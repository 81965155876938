import Vue from 'vue'
import Vuex from 'vuex'
import ConfigApiHelper from "./script/Utility/ConfigApiHelper";
Vue.use(Vuex);

export default new Vuex.Store({
    getters: {
        cart: state => state.cart,
        config: state => state.config
    },
    state() {
        let cart = getLocalStorage("cart") || {};
        let cartCount = 0;
        if (cart instanceof Array) {
            cart = {};
        } else if (!cart instanceof Object) {
            cart = {};
        }
        if (Object.keys(cart).length > 0) {
            cartCount = Object.values(cart).reduce((total, currentCount) => total + currentCount);
        } else {
            cartCount = 0;
        }
        
        return {
            categories: [],
            products: [],
            cart,
            cartCount,
            searchStr: "",
            loginInfo: {
                isLogin: false,
                loginType: "",
                detail: {
                    points: 0
                }
            },
            config: {
                banners: null,
                brand_stories: null,
                promoCodeInfo: null,
                free_ship_fee_lower: 660,// 免運門檻(預設)
                ship_fee_cvs: 60,        // 超商運費(預設)
                ship_fee_home: 110       // 宅配運費(預設)
            }
        }
    },
    mutations: {
        banners(state, item) {
            state.config.banners = item;
        },
        brand_stories(state, item) {
            state.config.brand_stories = item;
        },
        categories(state, items) {
            Vue.set(state, 'categories', items);
            const products = [];
            items.forEach(item => {
                products.push(...item.products);
            });
            this.commit("products", products);
        },
        products(state, items) {
            Vue.set(state, 'products', items);
        },
        promoCodeInfo(state, item) {
            state.config.promoCodeInfo = item;
        },
        login(state, item) {
            Object.assign(state.loginInfo, item);
        },
        logout(state) {
            Object.assign(state.loginInfo, {
                isLogin: false,
                loginType: "",
                detail: {
                    points: 0
                }
            });
        },
        cartModify(state, item) {
            const count = item.count;
            const selection = item.selection;
            const id = item.id + (selection ? "-" + selection : "");
            const diff = count - (state.cart[id] ? state.cart[id] : 0);
            Vue.set(state.cart, id, count);
            Vue.set(state, 'cartCount', state.cartCount + diff);
            if (count == 0) {
                Vue.delete(state.cart, id);
            }
            setLocalStorage("cart", state.cart); 
        },
        clear(state) {
            Object.keys(state.cart).forEach((id)=>{
                this.commit("cartModify",  { id, count: 0 });
            });
        },
        add(state, item) {
            const product = item.product;
            const selection = item.selection || "";
            const id = product.id + (selection ? "-" + selection : "");
            const currentCount = state.cart[id] ? state.cart[id] : 0;
            const count = currentCount + item.count;
            
            fbq("track", "AddToCart", {
                "content_ids": id,
                "contents": {
                    'id': id,
                    'quantity': count,
                },
                "content_type": "product",
                "content_name": product.name,
                "value": product.price,
                "currency": "TWD",
                "product_catalog_id": product.category
            });
            this.commit("cartModify",  { id: product.id, count, selection });
        },
        remove(state, item) {
            this.commit("cartModify",  { id: item.id, count: 0, selection: item.selection});
        },
        search(state, str) {
            state.searchStr = str;
        },
        fetchConfig(state) {
            ConfigApiHelper.getConfigInfo().then((result) => {
                state.config = Object.assign(state.config, result);
            });
        }
    }
})