import { ApiMethods } from "../enum/ApiMethods";
import ApiHelper from "./ApiHelper";
const configApi = require("../../../../storage/app/apiPath/config.json");



export default class ConfigApiHelper {
    public static getBanner(target: any): Promise<any> {
        return ApiHelper.callSelf(ApiMethods.GET, `${configApi.banner}/${target}`);
    }
    public static getBrandStory(target: any): Promise<any> {
        return ApiHelper.callSelf(ApiMethods.GET, `${configApi.brand_story}/${target}`);
    }
    public static getPromoCodeInfo(promoCode: any): Promise<any> {
        return ApiHelper.callSelf(ApiMethods.GET, `${configApi.promo_code_info}/${promoCode}`);
    }
    public static getConfigInfo(): Promise<any> {
        return ApiHelper.callSelf(ApiMethods.GET, `${configApi.config_info}`);
    }
}