
<template>
  <div
    :class="`px-5 shopheader ${isTop ? 'shopheader-top' : ''}`"
    style="
      display: flex;
      justify-content: center;
      position: relative;
      height: 58px;
      align-items: center;
    "
  >
    <div
      class="colorWhite mx-2 button"
      style="pointer-events: auto; z-index: 1"
      @click="goPage('Home')"
    >
      <img
        class="logo sharkimg"
        src="/img/logo.png"
        style="height: 40px; width: auto"
      />
    </div>
    <div
      v-if="false"
      class="mx-2"
      style="flex-grow: 1; display: flex; justify-content: center; width: 0"
    >
      <search-bar
        v-model="searchStr"
        :placeholder="$t('header_search_placeholder')"
        class="bgItem mx-0 h-100 w-100"
        style="max-width: 500px"
      ></search-bar>
    </div>
    <div
      v-else
      style="
        margin: auto;
        display: flex;
        font-family: TopMenu;
        justify-content: center;
        flex-grow: 1;
        width: 0;
        pointer-events: auto;
      "
    >
      <div class="my-auto">
        <div
          class="my-2 px-4 button clickScale singleLine"
          @click="clickItem('#brandStory')"
        >
          品牌故事
        </div>
      </div>
      <div
        v-for="category in this.$store.state.categories"
        :key="category.id"
        class="my-auto"
        style="display: inherit"
      >
        <Dropdown v-if="category.show_in_pc_menu">
          <div
            class="my-2 px-4 button clickScale singleLine"
            @click="clickItem(`#category${category.id}`)"
          >
            {{ category.name }}
          </div>
          <DropdownMenu slot="list">
            <DropdownItem
              v-for="product in category.products.filter(
                (product) => product.show_in_menu
              )"
              :key="product.id"
              @click.native="goProduct(product.id)"
              >{{ product.name }}</DropdownItem
            >
          </DropdownMenu>
        </Dropdown>
      </div>
      <div class="my-auto" style="display: inherit">
        <Dropdown>
          <div class="my-2 px-4 button clickScale singleLine">全系列商品</div>
          <DropdownMenu slot="list">
            <Dropdown
              v-for="category in this.$store.state.categories"
              :key="category.id"
              placement="right-start"
              class="my-2 px-3"
            >
              <DropdownItem
                @click.native="clickItem(`#category${category.id}`)"
              >
                {{ category.name }}
                <Icon type="ios-arrow-forward"></Icon>
              </DropdownItem>
              <DropdownMenu slot="list">
                <DropdownItem
                  v-for="product in category.products.filter(
                    (product) => product.show_in_menu
                  )"
                  :key="product.id"
                  @click.native="goProduct(product.id)"
                >
                  {{ product.name }}</DropdownItem
                >
              </DropdownMenu>
            </Dropdown>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
    <div
      class="colorWhite mx-2 button"
      style="pointer-events: auto; z-index: 1"
      @click="clickSkinTest()"
    >
      <img src="/img/icon_skin.png" style="height: 24px; width: auto" />
    </div>
    <div
      class="colorWhite mx-2 button"
      style="pointer-events: auto; z-index: 1"
      @click="goPage('Cart')"
    >
      <Badge :count="$store.state.cartCount">
        <Icon type="md-cart" size="24" />
      </Badge>
    </div>
    <div
      class="colorWhite mx-2 button"
      style="pointer-events: auto; z-index: 1"
      @click="goPage('Member')"
    >
      <Icon type="md-person" size="24" />
    </div>
  </div>
</template>
<script>
export default require("../../../script/component/ShopHeader").default;
</script>