import OrderApiHelper from "../Utility/OrderApiHelper";
export default {
  props: ["id", "from"],
  beforeCreate() {
    $loading(true);
    const data = OrderApiHelper.getOrderDetail(this.$route.params.id).then(
      (result) => {
        $loading(false);
        Object.assign(this.order, result);
        this.payStatusCode = result.order_records[0]?.payment_status || 0;
        this.shippingStatusCode =
          result.order_records[0]?.shipping_status ?? -1;
      }
    );
  },

  data() {
    return {
      isReady: false,
      payStatusCode: 0,
      shippingStatusCode: 0,
      order: {
        promo_code_discount: 0,
        used_points: 0,
        timestamp: "",
        shipfee: 0,
        order_items: [],
        logistics_type: "",
        logistics_sub_type: "",
        logistics_store_name: "",
        logistics_store_id: "",
        name: "",
        address: "",
        zip_code: "",
        phone: "",
        payment_type: "",
        memo: "",
      },
      fromCheckout: this.from == "Checkout",
    };
  },
  computed: {
    ShopName() {
      const code = this.order.logistics_sub_type;
      const map = {
        TCAT: this.$t("common_shop_tcat"), // 黑貓(宅配)
        ECAN: this.$t("common_shop_ecan"), // 大嘴鳥宅配通
        FAMI: this.$t("common_shop_family"), // 全家
        UNIMART: this.$t("common_shop_711"), // 統一超商
        HILIFE: this.$t("common_shop_hilife"), // 萊爾富
      };
      return map[code] ?? "";
    },
    PayMethodName() {
      const code = this.order.payment_type.split("_")[0];
      const map = {
        Credit: this.$t("common_paymethod_credit"),
        WebATM: this.$t("common_paymethod_webatm"),
        CVS: this.$t("common_paymethod_cvs"),
        BARCODE: this.$t("common_paymethod_barcode"),
        GetAndPay: this.$t("common_paymethod_getandpay"),
      };
      return map[code];
    },
    DeliveryName() {
      const code = this.order.logistics_type;
      const map = {
        Home: this.$t("common_logistics_home"), // 黑貓(宅配)
        CVS: this.$t("common_logistics_cvs"), // 宅配通
      };
      return map[code] ?? "";
    },
    showPayNow() {
      const code = this.order.payment_type.split("_")[0];
      return code == "Credit" && this.payStatusCode == 0;
    },
    isCVS() {
      return this.order.logistics_type == "CVS";
    },
    payStatus() {
      const payStatusMap = {
        0:this.$t("order_status_unpaid"),
        1: this.$t("order_status_paid"),
        2: this.$t("order_status_getandpay"),
        3: this.$t("order_status_canceled"),
      };
      const payStatus = payStatusMap[parseInt(this.payStatusCode)] || this.$t("order_status_error");
      return payStatus;
    },
    shippingStatus() {
      const msgID = `shipping_status.${this.shippingStatusCode}`;
      const message = this.$t(msgID);
      return message != msgID ? message : this.$t("shipping_status_not_found");
    },
    total() {
      return this.order.order_items
        .map((item) => item.price * item.count)
        .reduce((total, price) => total + price);
    },
    finalPrice() {
      return (
        this.total -
        this.order.promo_code_discount -
        this.order.used_points +
        this.order.shipfee
      );
    },
  },
  watch: {},
  methods: {
    onOrderPostSuccess(result) {
      this.$router.push({
        name: "OrderDetail",
        params: { id: result.order_id, from: "Checkout" },
      });
      this.isReady = true;
    },
    onOrderPostField(error) {
      this.$Message.error(error.message);
      this.isReady = true;
    },
    payNow() {
      const params = {
        items: this.order.order_items.map((item) => {
          return {
            id: item.product_id,
            count: item.count,
          };
        }),
        use_points: this.order.used_points,
        promo_code: this.order.promo_code,
        pay_method: this.order.payment_type,
        old_trade_no: this.order.id,
        logistics_type: this.order.logistics_type,
        logistics_sub_type: this.order.logistics_sub_type,
        logistics_store_id: this.order.logistics_store_id,
        logistics_store_name: this.order.logistics_store_name,
        receiver_name: this.order.name,
        receiver_email: this.order.email,
        zip_code: this.isCVS ? "" : this.order.zip_code,
        address: this.isCVS ? "" : this.order.address,
        receiver_mobile: this.order.phone,
        memo: this.order.memo,
        remark: this.order.remark,
      };
      OrderApiHelper.openRepayOrder(params)
        .then(this.onOrderPostSuccess)
        .catch(this.onOrderPostField);
      setTimeout(() => {
        this.$router.go(-1);
      }, 500);
    },
  },
};