const OrderApiHelper =
  require("../Utility/OrderApiHelper").default;
export default {
  beforeCreate() {
    $loading(true);
    OrderApiHelper.getOrderRecords().then((result) => {
      if (result instanceof Array) {
        this.orders = result.filter((order) => order.name);
      }
      $loading(false);
    });
  },
  data() {
    return {
      orders: [],
    };
  },
  computed: {
    source: () => {},
  },
  methods: {},
};