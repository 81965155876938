import { LoginType } from "../enum/LoginType";
import LoginInterface from "./LoginInterface";
import ApiHelper from "../Utility/ApiHelper";
import LoginApiHelper from "../Utility/LoginApiHelper";
const loginApi = require("../../../../storage/app/apiPath/login.json");
export default class LineLogin extends LoginInterface {
    url = {
        auth: "https://access.line.me/oauth2/v2.1/authorize"
    }
    config = {
        loginType: LoginType.Line,
    }
    isInited: boolean = false
    initedCallback: Function = () => { }
    constructor() {
        super();
        this.defaultData.loginType = this.config.loginType;
        this.data = Object.assign({}, this.defaultData)
    }
    public registCallback(callback: () => void): void {
        callback();
    }
    public logout(callback: (response: StatusResponse) => void): void {
        LoginApiHelper.lineLogout()
            .then((data) => {
                this.statusChangeCallback(data, callback);
            });
    }
    public login(callback: (response: StatusResponse) => void) {
        const url = ApiHelper.getUrl(this.url.auth, {
            response_type: "code",
            client_id: process.env.MIX_LINE_CLIENT_ID,
            redirect_uri: location.origin + loginApi.line.auth,
            state: Math.random(),
            scope: "profile&openid"
        });

        LoginApiHelper.openOutside(this.url.auth, {
            response_type: "code",
            client_id: process.env.MIX_LINE_CLIENT_ID,
            redirect_uri: location.origin + loginApi.line.auth,
            state: Math.random(),
            scope: "profile&openid"
        }).then(this.onPageCallback.bind(this, callback));
    }
    public onPageCallback(callback: ((response: StatusResponse) => void), data: any) {
        this.statusChangeCallback(data, callback);
    }
    public checkLoginStatus(callback: (response: StatusResponse) => void) {
        LoginApiHelper.lineGetUserInfo()
            .then((data) => {
                this.statusChangeCallback(data, callback);
            }).catch((data) => {
                this.statusChangeCallback(data, callback);
            });
    }

    // 處理各種登入身份
    private statusChangeCallback(rawData: any, callback: ((response: StatusResponse) => void) | null) {
        const userData = rawData;
        if (userData.userId) {
            this.loginSuccess();
            this.data.isLogin = true;
            this.data.detail.id = userData.userId;
            this.data.detail.display_name = userData.displayName;
            this.data.detail.photo = userData.pictureUrl;
        } else {
            this.logoutSuccess();
            Object.assign(this.data, this.defaultData);
            this.data.message = rawData.message;
        }
        if (callback) callback(this.data);
    }
}
