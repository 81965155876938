import { LoginType } from "../enum/LoginType";
import LoginInterface from "./LoginInterface";
import ApiHelper from "../Utility/ApiHelper";
import LoginApiHelper from "../Utility/LoginApiHelper";
const loginApi = require("../../../../storage/app/apiPath/login.json");
export default class RoyishopLogin extends LoginInterface {
    config = {
        loginType: LoginType.Royishop,
    }
    isInited: boolean = false
    initedCallback: Function = () => { }
    constructor() {
        super();
        this.defaultData.loginType = this.config.loginType;
        this.data = {
            message: '',
            loginType: this.config.loginType,
            isLogin: false,
            detail: Object.assign({}, this.defaultData.detail)
        };
    }
    public registCallback(callback: () => void): void {
        callback();
    }
    public logout(callback: (response: StatusResponse) => void): void {
        this.statusChangeCallback({}, callback);
    }
    public login(callback: (response: StatusResponse) => void) { }
    public loginByAccount(account: string, password: string, callback: (response: StatusResponse) => void) {
        LoginApiHelper.login(account, password).then((result) => {
            this.statusChangeCallback(result, callback);
        }).catch((result) => {
            this.statusChangeCallback(result, callback);
        });
    }
    public onPageCallback(callback: ((response: StatusResponse) => void), data: any) {
        this.statusChangeCallback(data, callback);
    }
    public checkLoginStatus(callback: (response: StatusResponse) => void) {
        const token = getCookie('royishopToken') as string;
        LoginApiHelper.getLoginInfo(token).then((result) => {
            this.statusChangeCallback(result, callback);
        }).catch((result) => {
            this.statusChangeCallback(result, callback);
        });
    }

    // 處理各種登入身份
    private statusChangeCallback(rawData: {
        birth: string
        display_name: string
        email: string
        email_valid: number
        gender: string
        id: string
        memo: string
        phone: string
        regist_time: string
        token: string
        token_expired: string
    } | any, callback: ((response: StatusResponse) => void) | null) {
        const userData = rawData;
        if (userData.id) {
            // setCookie('royishopToken', );
            this.loginSuccess();
            this.data.isLogin = true;
            this.data.detail = Object.assign({}, userData);
        } else {
            this.logoutSuccess();
            this.data.isLogin = false;
            this.data.detail = Object.assign({}, this.defaultData.detail);
            this.data.message = rawData.message;
        }
        if (callback) callback(this.data);
    }
}
