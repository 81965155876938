<template>
  <div class="row justify-content-center my-3 mx-0">
    <div class="page">
      <div v-if="fixedCartList.length">
        <div
          v-for="product in fixedCartList"
          :key="product.id + product.selection"
        >
          <ShoppingCartItemPC class="mb-3 mx-0" v-bind:product="product">
          </ShoppingCartItemPC>
        </div>
        <Divider class="bgGray" />
        <div class="title" style="margin: -2vh 0px; font-weight: normal">
          {{ $t("cart_total_amount")
          }}<span
            :class="'colorDarkRed' + (promoDiscount ? ' priceDelete' : '')"
          >
            NT${{ total }}</span
          ><span class="colorDarkRed" v-if="promoDiscount"
            >NT${{ total - promoDiscount }}</span
          >
        </div>
        <Divider class="bgGray" />
        <div style="display: flex; justify-content: center">
          <div
            v-if="totalCount"
            class="p-1 mx-3 roundButton2"
            @click="gotoBuy()"
          >
            {{ $t("cart_checkout") }}
          </div>
          <div
            class="p-1 mx-3 roundButton"
            @click="$router.push({ name: 'Home' })"
          >
            {{ $t("cart_select_more") }}
          </div>
          <div
            class="p-1 mx-3 roundButton"
            @click="clear()"
          >
            {{ $t("scitem_product_clear_shoping_car") }}
          </div>
        </div>
      </div>
      <div v-else class="row align-items-center mx-0" style="height: 50vh">
        <div>{{ $t("cart_notthing_in_cart") }}</div>
        <div style="width: auto; margin: auto">
          <div class="roundButton2 p-1" @click="$router.push({ name: 'Home' })">
            {{ $t("cart_go_shopping") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default require("../../../script/pages/ShoppingCart").default;
</script>