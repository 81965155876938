import Vue from 'vue'
import VueI18n from 'vue-i18n'

// 自訂語言檔
import en from '../i18n/lang/en'
import cn from '../i18n/lang/cn'
import tw from '../i18n/lang/tw'

// 使用插件
Vue.use(VueI18n)

// 取得預設語系
const locale = localStorage.getItem('locale') || 'tw'

// 建立 VueI18n 實體
const i18n = new VueI18n({
  locale,
  messages: { en, tw, cn }
})
/**
 * @description 切換網站語系
 */
 i18n.switchLang = (newLang) => {
    i18n.locale = newLang
    // localStorage.setItem('locale', newLang)
  }
  var language = window.navigator.userLanguage || window.navigator.language;
  language = language.toLowerCase()
  if(language.indexOf('cn') >=0) {
    i18n.switchLang('cn');
  } else if(language.indexOf('zh') >= 0) {
    i18n.switchLang('tw');
  } else {
    i18n.switchLang('en');
  }
  
export default i18n