<template>
  <div class="row">
    <div
      class="col-4"
      :style="{
        'text-align': 'left',
        width: labelWidth ? `${labelWidth}` : '',
        height: 'fit-content',
        margin: 'auto',
      }"
    >
      {{ field }}
    </div>
    <div
      class="col-8 px-0"
      :style="{
        width: labelWidth ? `calc( 100% - ${labelWidth} )` : '',
      }"
    >
      <div
        class="roundButton2 col-7 p-1"
        @click="$emit('buttonClick')"
        style="width: 100%"
      >
        {{ text }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["field", "text", "labelWidth"],
  mounted() {},
};
</script>
