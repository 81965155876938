export default {
  common_use_outside_browser: '請使用外部瀏覽器以達到最佳體驗',
  common_system_error: '系統忙碌中, 請稍後再試({0})',
  common_check_fields: '請檢查欄位是否輸入正確',
  common_yes: '確定',
  common_no: '取消',
  common_modify_success: '修改成功',
  common_steps_cart_content: '購物車內容',
  common_steps_order_finish: '訂單完成',
  common_shop_tcat: '黑貓宅急便',
  common_shop_ecan: '大嘴鳥宅配通',
  common_shop_family: '全家便利商店',
  common_shop_711: '7-11',
  common_shop_hilife: '萊爾富',
  common_paymethod_credit: '信用卡/金融卡',
  common_paymethod_webatm: '網路ATM',
  common_paymethod_cvs: '超商代碼',
  common_paymethod_barcode: '超商條碼',
  common_paymethod_getandpay: '貨到付款',
  common_logistics_home: '宅配',
  common_logistics_home_shop: '宅配業者',
  common_logistics_cvs: '超商取貨',
  common_logistics_cvs_shop: '取貨超商',
  common_logistics_cvs_store_code: '門市代碼',
  common_logistics_cvs_store_name: '門市名稱',
  header_search_placeholder: '搜尋商品',
  header_title_login: '登入',
  header_title_signup: '註冊',
  header_title_member: '會員專區',
  header_title_product: '商品詳情',
  header_title_points: '點數紀錄',
  header_title_cart: '購物車',
  header_title_checkout: '結帳',
  header_title_order: '訂單紀錄',
  header_title_order_detail: '訂單明細',
  header_title_skintest_record: '膚質檢測紀錄',
  header_title_reset_pwd: '重設密碼',
  header_title_forgot_pwd: '忘記密碼',
  footer_contact_phone: '連絡電話: {0}',
  footer_contact_email: 'E-Mail: {0}',
  signup_email: 'E-Mail',
  signup_email_please: '請輸入E-Mail',
  signup_email_wrong_format: 'E-Mail格式有誤',
  signup_email_valify_first: '請先驗證email',
  signup_valify_code: '驗證碼',
  signup_valify_code_please: '請輸入驗證碼',
  signup_send_valify_code: '發送驗證碼',
  signup_password: '密碼',
  signup_password_please: '請設定6-15碼密碼',
  signup_password_not_less_six: '密碼至少六個字',
  signup_password_confirn: '密碼確認',
  signup_password_confirn_please: '請再次輸入密碼',
  signup_password_not_match: '兩次密碼輸入不相符',
  signup_mobile: '手機號碼',
  signup_mobile_please: '請輸入手機號碼',
  signup_mobile_wrong_format: '手機格式錯誤',
  signup_birthday: '生日',
  signup_birthday_please: '請選擇生日',
  signup_gender: '性別',
  signup_gender_man: '男',
  signup_gender_woman: '女',
  signup_gender_please: '請選擇性別',
  signup_signup: '註冊',
  signup_button_finish: '點我完成',
  signup_resend_valify_code: '重新發送: {0}s',
  signup_privacy: '隱私權政策',
  signup_service: '服務條款',
  login_email: 'E-Mail',
  login_email_please: '請輸入E-Mail',
  login_email_wrong_format: 'E-Mail格式有誤',
  login_password: '密碼',
  login_password_please: '請設定6-15碼密碼',
  login_password_not_less_six: '密碼至少六個字',
  login_signup: '註冊',
  login_login: '登入',
  login_slogan: '讓美麗變得更容易',
  login_save_account: '記憶帳號',
  home_product_not_found: '找不到任何商品',
  product_add_to_cart: '加入購物車',
  product_buy_directly: '直接購買',
  product_add_to_cart_success: '{0} 成功加入購物車',
  product_product_id: '產品編號',
  product_product_price: '商品價格',
  product_product_count: '購買數量',
  product_shipfee_free: '全館滿{0}元免運費',
  product_product_specification: '商品規格',
  product_product_life_time: '保存期限',
  product_product_origin: '產地',
  product_price: '售價',
  product_detail: '商品特色',
  product_recommand: '向您推薦',
  product_out_of_stock: '缺貨中',
  product_product_out_of_stock: '商品缺貨中',
  product_product_out_of_stock_message: '商品庫存不足',
  member_user_id: '會員編號',
  member_points: '累積點數',
  member_gender: '性別',
  member_gender_woman: '女',
  member_gender_man: '男',
  member_birth: '生日',
  member_phone: '電話號碼',
  member_email: 'E-Mail',
  member_my_points: '我的點數',
  member_order_records: '訂單紀錄',
  member_button_search: '點我查詢',
  member_logout: '登出',
  member_skintest_records: '膚質檢測紀錄',
  member_skintest_records_notfount: '未偵測到您的檢測紀錄',
  member_skintest_records_test_now: '馬上去測驗',
  member_password: '密碼',
  member_password_reset: '點我重設',
  
  points_source: '來源',
  points_points: '點數',
  points_time: '時間',
  points_no_points: '目前沒有點數',
  points_used_points: '使用點數',
  points_order_reward_points: '訂單點數回饋',
  points_promo_code_points: '優惠碼點數',
  points_system_points: '系統分配',
  orecords_order_id: '訂單編號',
  orecords_order_amount: '訂單金額',
  orecords_order_time: '訂單時間',
  orecords_no_order: '目前沒有訂單',
  order_order_id: '訂單編號',
  order_order_amount: '訂單金額',
  order_order_time: '訂單時間',
  order_order_info: '訂單資訊',
  order_order_detail: '訂單明細',
  order_promo_discount: '優惠碼折扣',
  order_points_discount: '點數折抵',
  order_payment_detail: '付款詳情',
  order_payment_amount: '付款金額',
  order_payment_status: '付款狀態',
  order_payment_shipfee: '運費',
  order_shipping_status: '貨運狀態',
  order_status_paid: '已付款',
  order_status_unpaid: '未付款',
  order_status_canceled: '已取消',
  order_status_getandpay: '貨到付款',
  order_status_error: '訂單異常',
  order_subtitle_product: '商品',
  order_subtitle_count: '數量',
  order_paymethod: '付款方式',
  order_shipping_info: '貨運資訊',
  order_shipping_method: '運送方式',
  order_shipping_shop: '運送商家',
  order_shipping_store_name: '運送店名',
  order_shipping_store_id: '運送店號',
  order_shipping_zipcode: '郵遞區號',
  order_shipping_address: '地址',
  order_shipping_memo: '交易備註',
  order_receiver_name: '收件人',
  order_receiver_mobile: '手機號碼',
  order_buy_count: '{0}商品',
  order_buy_amount: '購買金額',
  order_pay_now: '立即付款',
  order_status: '狀態',
  order_go_shopping: '馬上去購物',
  cart_total_amount: '小計',
  cart_notthing_in_cart: '購物車空空如也',
  cart_login_please: '請先登入',
  cart_checkout: '立即結帳',
  cart_select_more: '選擇更多商品',
  cart_go_shopping: '馬上去購物',
  scitem_product_name: '品名',
  scitem_product_price: '價格',
  scitem_product_count: '數量',
  scitem_product_clear_shoping_car: '清空購物車',
  scitem_product_remove_confirm: '刪除該項商品',
  coitem_product_name: '品名',
  coitem_product_price: '價格',
  coitem_product_count: '數量',
  checkout_subtitle_product: '商品',
  checkout_subtitle_count: '數量',
  checkout_save_order_data: '記憶結帳資料',
  checkout_use_points_please: '輸入折抵點數',
  checkout_use_points: '點數折抵[{0}]',
  checkout_promo_code_please: '輸入優惠碼',
  checkout_promo_code: '優惠碼',
  checkout_logistics_type: '寄送方式',
  checkout_logistics_home_shop_select: '請選擇宅配業者',
  checkout_receiver_info: '收件人資料',
  checkout_receiver_name: '收件人',
  checkout_receiver_zipcode: '郵遞區號',
  checkout_receiver_address: '地址',
  checkout_receiver_mobile: '手機號碼',
  checkout_receiver_memo: '交易備註',
  checkout_payment_detail: '付款詳情',
  checkout_payment_order_amount: '訂單金額',
  checkout_payment_promo_code_discount: '優惠折扣',
  checkout_payment_use_points: '點數折抵',
  checkout_payment_shipfee: '運費',
  checkout_payment_shipfee_discount: '運費折抵',
  checkout_payment_total_amount: '總付款金額',
  checkout_payment_commit: '立即結帳',
  checkout_name_min_6: '收件人地址最少六個字',
  checkout_zipcode_please: '請輸入郵遞區號',
  checkout_zipcode_wrong_format: '郵遞區號格式錯誤',
  checkout_name_please: '請輸入姓名',
  checkout_address_please: '請輸入地址',
  checkout_mobile_please: '請輸入手機號碼',
  checkout_mobile_wrong_format: '手機號碼格式錯誤',
  checkout_use_points_wrong_format: '輸入錯誤',
  checkout_use_points_not_enugh: '可用點數不足',
  checkout_paymethod_select_plaese: '請選擇付款方式',
  checkout_paymethod_select: '付款方式',
  checkout_commit_confirm: '請確認您的資料是否正確',
  checkout_logistics_cvs_select: '請選擇取貨超商',
  checkout_logistics_cvs_store_select: '選擇取件門市',
  checkout_logistics_cvs_store_select_please: '請選擇取件門市',
  forgot_pwd_resend: '重新發送: {0}s',
  forgot_pwd_send: '發送',
  reset_pwd_origin_password: '原密碼',
  reset_pwd_origin_password_please: '請輸入原密碼',
  reset_pwd_new_password: '新密碼',
  reset_pwd_new_password_please: '請設定6-15碼密碼',
  reset_pwd_reset_password: '修改密碼',
  shipping_status_not_found: "訂單異常",
  shipping_status:
  {
    0: "訂單處理中",
    1: "已出貨",
    3: "已取消",
    300: "訂單處理中",
    310: "訂單上傳處理中",
    311: "上傳退貨電子訂單處理中",
    325: "退貨訂單處理中(已收到訂單資料)",
    2000: "出貨訂單修改",
    2001: "檔案傳送成功",
    2002: "出貨單號不合規則",
    2003: "XML檔內出貨單號重複",
    2004: "出貨單號重複上傳使用(驗收時發現)",
    2005: "日期格式不符",
    2006: "訂單金額或代收金額錯誤",
    2007: "商品類型為空",
    2008: "訂單為空",
    2009: "門市店號為空",
    2010: "出貨日期為空",
    2011: "出貨金額為空",
    2012: "出貨編號不存在",
    2013: "母廠商不存在",
    2014: "子廠商不存在",
    2015: "出貨編號已存在(單筆)",
    2016: "門市已關轉店，將進行退貨處理",
    2017: "出貨日期不符合規定",
    2018: "服務類型不符規定(如只開取貨付款服務，確使用純取貨服務)",
    2019: "商品類型不符規定",
    2020: "廠商尚未申請店配服務",
    2021: "同一批次出貨編號重覆(批次)",
    2022: "出貨金額不符規定",
    2023: "取貨人姓名為空",
    2024: "超商接受資料中",
    2025: "門市轉店號(舊門市店號已更新)",
    2026: "無此門市，將進行退貨處理",
    2027: "門市指定時間不配送(六、日)",
    2028: "門市關轉店，3日內未更新SUP(新店號)便至退貨流程",
    2029: "門市尚未開店",
    2030: "物流中心驗收成功",
    2031: "未到貨(物流端未收到該商品)",
    2032: "商品瑕疵(進物流中心)",
    2033: "超材",
    2034: "違禁品(退貨及罰款處理)",
    2035: "訂單資料重覆上傳",
    2036: "已過門市進貨日（未於指定時間內寄至物流中心）",
    2037: "門市關轉(可使用SUP檔案更新原單號更新門市與出貨日)",
    2038: "第一段標籤規格錯誤",
    2039: "第一段標籤無法判讀",
    2040: "第一段標籤資料錯誤",
    2041: "物流中心理貨中",
    2042: "商品遺失",
    2043: "門市指定不配送(六、日)",
    2044: "物流中心驗收退貨完畢",
    2045: "不正常到貨(商品提早到物流中心)",
    2046: "貨件未取退回大智通物流中心",
    2047: "正常二退(退貨時間延長，在判賠期限內退回)",
    2048: "商品瑕疵(商品在物流中心)",
    2049: "門市關店，將進行退貨處理",
    2050: "門市轉店，將進行退貨處理",
    2051: "廠商要求提早退貨（廠商出錯商品）",
    2052: "違禁品(退貨及罰款處理)",
    2053: "刷A給B",
    2054: "消費者要求提早拉退（消費者下訂單後又跟廠商取消）",
    2055: "更換門市",
    2057: "車輛故障，後續配送中",
    2058: "天候不佳，後續配送中",
    2059: "道路中斷，後續配送中",
    2060: "門市停業中，將進行退貨處理",
    2061: "缺件(商品未至門市)",
    2062: "門市報缺",
    2063: "門市配達",
    2065: "EC收退",
    2066: "異常收退(商品破損、外袋破損、消費者取錯件、誤刷代收等，提早從門市退貨)",
    2067: "消費者成功取件",
    2068: "交貨便收件(A門市收到件寄件商品)",
    2069: "退貨便收件(商品退回指定C門市)",
    2070: "退回原寄件門市且已取件",
    2071: "門市代碼格式錯誤",
    2072: "商品配達賣家取退貨門市",
    2073: "商品配達買家取貨門市",
    2074: "消費者七天未取，商品離開買家取貨門市",
    2075: "廠商未至門市取退貨，商品離開賣家取退貨門市",
    2076: "消費者七天未取，商品退回至大智通",
    2077: "廠商未至門市取退貨，商品退回至大智通",
    2078: "買家未取貨退回物流中心-驗收成功",
    2079: "買家未取貨退回物流中心-商品瑕疵(進物流中心)",
    2080: "買家未取貨退回物流中心-超材",
    2081: "買家未取貨退回物流中心-違禁品(退貨及罰款處理)",
    2082: "買家未取貨退回物流中心-訂單資料重覆上傳",
    2083: "買家未取貨退回物流中心-已過門市進貨日（未於指定時間內寄至物流中心）",
    2084: "買家未取貨退回物流中心-第一段標籤規格錯誤",
    2085: "買家未取貨退回物流中心-第一段標籤無法判讀",
    2086: "買家未取貨退回物流中心-第一段標籤資料錯誤",
    2087: "買家未取貨退回物流中心-物流中心理貨中",
    2088: "買家未取貨退回物流中心-商品遺失",
    2089: "買家未取退回物流中心-門市指定不配送(六、日)",
    2092: "買家未取退回物流中心-門市關轉",
    2093: "買家未取退回物流中心-爆量",
    2094: "包裹異常不配送",
    2095: "天候路況不佳",
    2101: "門市關轉店",
    2102: "門市舊店號更新",
    2103: "無取件門市資料",
    2104: "門市臨時關轉店",
    2105: "已申請門市變更",
    3001: "轉運中(即集貨)",
    3002: "不在家",
    3003: "配完",
    3004: "送錯BASE (送錯營業所)",
    3005: "送錯CENTER(送錯轉運中心)",
    3006: "配送中",
    3007: "公司行號休息",
    3008: "地址錯誤，聯繫收件人",
    3009: "搬家",
    3010: "轉寄(如原本寄到A，改寄B)",
    3011: "暫置營業所(收件人要求至營業所取貨)",
    3012: "到所(收件人要求到站所取件)",
    3013: "當配下車(當日配送A至B營業所，已抵達B營業所)",
    3014: "當配上車(當日配送從A至B營業所，已抵達A營業所)",
    3015: "空運配送中",
    3016: "配完狀態刪除",
    3017: "退回狀態刪除(代收退貨刪除)",
    3018: "到店尚未取貨，簡訊通知取件",
    3019: "退件到店尚未取貨，簡訊通知取件",
    3020: "貨件未取退回物流中心",
    3021: "退貨商品未取退回物流中心",
    3022: "買家已到店取貨",
    3023: "賣家已取買家未取貨",
    3024: "貨件已至物流中心",
    3025: "退貨已退回物流中心",
    3029: "商品已轉換店（商品送達指定更換之取件店舖）",
    3031: "退貨商品已轉換店（退貨商品送達指定更換之取件店舖）",
    3032: "賣家已到門市寄件",
    3110: "轉配郵局",
    3111: "配送外包",
    3112: "再配",
    3113: "異常",
    3114: "再取",
    3117: "拒收(調查處理中)",
    3118: "暫置營業所",
    3119: "暫置營業所(假日暫置)",
    3120: "預備配送中",
    3121: "轉交門市配達",
    3122: "另約時間",
    4001: "退貨商品已至門市交寄",
    4002: "退貨商品已至物流中心",
    5001: "損壞，站所將協助退貨",
    5002: "遺失",
    5003: "BASE列管(寄件人和收件人聯絡不到)",
    5004: "一般單退回",
    5005: "代收退貨",
    5006: "代收毀損",
    5007: "代收遺失",
    5008: "退貨配完",
    5009: "進貨門市發生緊急閉店，提早退貨至物流中心",
    7001: "超大(通常發生於司機取件，不取件)",
    7002: "超重(通常發生於司機取件，不取件)",
    7003: "地址錯誤，聯繫收件人",
    7004: "航班延誤",
    7005: "託運單刪除",
    7006: "小物流遺失",
    7007: "門市遺失",
    7008: "小物流破損，退回物流中心",
    7009: "商品包裝不良（物流中心反應）",
    7010: "商品包裝不良（門市反應）",
    7011: "取件門市閉店，轉退回原寄件店",
    7012: "條碼錯誤，物流中心客服處理",
    7013: "訂單超過驗收期限（商家未出貨）",
    7014: "商家未到貨（若訂單成立隔日未到貨即會發送，直到訂單失效刪除）",
    7015: "條碼重複，物流中心客服處理",
    7016: "超才",
    7017: "取件包裹異常協尋中",
    7018: "取件遺失進行賠償程序",
    7019: "寄件貨態異常協尋中",
    7020: "寄件遺失進行賠償程序",
    7021: "商品捆包",
    7022: "商品外袋透明",
    7023: "多標籤",
    7024: "另約時間",
    7025: "電聯不上",
    7026: "資料有誤",
    7027: "無件可退",
    7028: "超大超重",
    7029: "已回收",
    7030: "別家收走",
    7031: "商品未到",
    7032: "寄件門市閉店",
    7101: "包裹配送驗收異常-門市關轉",
    7102: "包裹配送驗收異常-無進貨資料",
    7103: "包裹配送驗收異常-條碼錯誤",
    7104: "包裹配送驗收異常-超材",
    7105: "包裹配送驗收異常-大物流包裝不良(滲漏)",
    7106: "包裹配送驗收異常-小物流破損",
    7107: "包裹配送驗收異常-門市反應商品包裝不良(滲漏)",
    7201: "退貨包裹配送驗收異常-門市關轉",
    7202: "退貨包裹配送驗收異常-無進貨資料",
    7203: "退貨包裹配送驗收異常-條碼錯誤",
    7204: "退貨包裹配送驗收異常-超材",
    7205: "退貨包裹配送驗收異常-大物流包裝不良(滲漏)",
    7206: "退貨包裹配送驗收異常-小物流破損",
    7207: "退貨包裹配送驗收異常-門市反應商品包裝不良(滲漏)",
    7255: "退貨包裹重新送往取件門市",
    9001: "退貨已取",
    9002: "退貨已取",
    9999: "訂單取消"
  },
  error_code: {
    "1001007": "帳號或密碼錯誤",
    "1001010": "您已經登出",
    "1001013": "E-Mail已被註冊",
    "1001018": "E-Mail不得為空",
    "1001020": "驗證碼錯誤",
    "1001022": "E-Mail不得為空",
    "1001025": "驗證碼錯誤",
    "1001029": "E-Mail已被註冊",
    "1001037": "輸入資料有誤",
    "1001038": "連結已過期",
    "1001039": "連結已過期",
    "1001041": "原密碼輸入錯誤",
    "1001042": "帳號不存在",
    "1002001": "您已經登出",
    "1002002": "您已經登出",
    "1003001": "您已經登出",
    "1013004": "點數輸入錯誤",
    "1013005": "點數餘額不足",
    "1013006": "收件者資料錯誤",
    "1013007": "收件者資料錯誤",
    "1013017": "優惠碼使用時間尚未開始",
    "1013018": "優惠碼已過期",
    "1013019": "優惠碼僅供第一次購物使用",
    "1013021": "優惠碼超過使用次數",
    "1013023": "優惠碼不存在",
    "1013026": "部分商品庫存量不足, 請重新下單",
    "1021001": "請勿重複填寫表單"
  }
}