
<template>
  <div class="roundButton">
    {{ text }}
  </div>
</template>
<script>
export default {
  props: ["text"],
  methods: {},
};
</script>
