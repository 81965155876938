<template>
  <div>
    <div class="page">
      <swiper
        class="col-12"
        :options="swiperOption1"
        style="padding: 5vmin 0"
        v-viewer="{ movable: false, toolbar: false }"
      >
        <swiper-slide v-if="!product.id || !product.imgs.length">
          <img src="/img/lady.png" />
        </swiper-slide>
        <swiper-slide class="" v-for="(img, i) in product.imgs" :key="i + img">
          <img :src="img" :alt="product.name" />
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div class="col-12 colorTitle">
        <Divider class="bgGray my-2" />
        <div class="title">
          {{ product.name }}
        </div>
        <div
          style="white-space: pre-wrap; margin: 2vmin; text-align: center"
          v-html="product.content"
        ></div>
        <Divider class="bgGray my-2" />

        <div class="content px-3">
          <field-select
            class="colorTitle"
            v-if="productSelections.length"
            :selections="productSelections"
            v-model="selection"
            :field="'購買項目'"
            labelWidth="20vw"
            :red="selectNotice"
          />
          <field-value
            :field="$t('product_product_id')"
            :value="product.product_code"
            labelWidth="20vw"
            style="margin: 4vmin"
          />
          <field-value
            :field="$t('product_product_price')"
            :value="priceText"
            :deleteValue="
              originPrice
                ? `<span class='priceDelete'>${originPrice}</span>`
                : ''
            "
            labelWidth="20vw"
            style="margin: 2vmin"
          />
          <field-item :field="$t('product_product_count')" labelWidth="20vw">
            <InputNumber
              slot="content"
              v-model="count"
              style=""
              controls-outside
              :min="1"
            ></InputNumber>
          </field-item>

          <Divider class="bgGray my-2" />
          <pre
            v-if="product.extra_info"
            class="ql-editor p-0 m-0"
            style="min-height: auto"
            width="100%"
            v-html="product.extra_info"
          ></pre>
          <Divider v-if="product.extra_info" class="bgGray my-2" />
          <field-value
            :field="$t('product_product_specification')"
            :value="product.specification"
            labelWidth="20vw"
            style="margin: 4vmin"
          />
          <field-value
            :field="$t('product_product_life_time')"
            :value="product.life_time"
            labelWidth="20vw"
            style="margin: 2vmin"
          />
          <field-value
            :field="$t('product_product_origin')"
            :value="product.made_origin"
            labelWidth="20vw"
            style="margin: 2vmin"
          />
          <Divider class="bgGray my-4" />
          <div style="width: 100%; text-align: left">
            {{ $t("product_detail") }}
          </div>
          <div
            class="ql-editor m-0 px-0 py-0 pb-3"
            style="white-space: pre-wrap; margin: 2vmin; text-align: left"
            v-html="product.detail"
          ></div>
        </div>
      </div>
    </div>
    <div class="px-0 page">
      <img v-for="(img, i) in product.edms" :src="img" :key="i" />
    </div>
    <div
      class="px-3 pb-3"
      style="margin-bottom: 12vmin"
      v-if="productListFilter.length"
    >
      <div class="subTitle colorTitle" style="margin: 3vmin; text-align: left">
        {{ $t("product_recommand") }}
      </div>
      <div
        class="row x-md-center"
        style="position: relative; padding-bottom: 6vmin"
      >
        <swiper class="swiper" :options="swiperOption2">
          <swiper-slide v-for="product in productListFilter" :key="product.id"
            ><ProductItemPC :product="product"></ProductItemPC
          ></swiper-slide>
        </swiper>
        <div
          v-show="product.id"
          class="swiper-pagination px-0"
          slot="pagination"
          style="left: 0; bottom: 0vmin"
        ></div>
      </div>
    </div>
    <div
      class="py-3 mx-0 bgWhite"
      style="position: fixed; bottom: 0px; width: 100%; left: 0; z-index: 11"
    >
      <div v-if="canBuy" class="page row justify-content-center">
        <div class="roundButton col-2" @click="addToCart()">
          {{ $t("product_add_to_cart") }}
        </div>
        <div class="roundButton2 col-2 offset-1" @click="directBuy()">
          {{ $t("product_buy_directly") }}
        </div>
      </div>
      <div v-else class="page row justify-content-center">
        {{ $t("product_out_of_stock") }}
      </div>
    </div>
  </div>
</template>
<script>
export default require("../../../script/pages/ProductInfo").default;
</script>