
<template>
  <div
    :class="`px-3 shopheader ${isTop ? 'shopheader-top' : ''}`"
    style="
      display: flex;
      justify-content: center;
      position: relative;
      height: 58px;
      align-items: center;
    "
  >
    <Icon
      type="md-menu"
      @click="$emit('menuClick')"
      size="24"
      class="button clickScale"
    />
    <div
      class="colorWhite mx-2 button"
      style="z-index: 1"
      @click="goPage('Home')"
    >
      <img
        class="logo sharkimg"
        src="/img/logo.png"
        style="height: 30px; width: auto"
      />
    </div>
    <div
      v-if="false"
      class="mx-2"
      style="flex-grow: 1; display: flex; justify-content: center; width: 0"
    >
      <search-bar
        v-model="searchStr"
        :placeholder="$t('header_search_placeholder')"
        class="bgItem mx-0 h-100 w-100"
        style="max-width: 500px"
      ></search-bar>
    </div>
    <div
      v-else
      style="
        margin: auto;
        display: flex;
        font-family: TopMenu;
        justify-content: center;
        flex-grow: 1;
        width: 0;
      "
    ></div>
    <div
      class="colorWhite mx-2 button"
      style="z-index: 1"
      @click="clickSkinTest()"
    >
      <img src="/img/icon_skin.png" style="height: 24px; width: auto" />
    </div>
    <div
      class="colorWhite mx-2 button"
      style="z-index: 1"
      @click="goPage('Cart')"
    >
      <Badge :count="$store.state.cartCount">
        <Icon type="md-cart" size="24" />
      </Badge>
    </div>
    <div
      class="colorWhite mx-2 button"
      style="z-index: 1"
      @click="goPage('Member')"
    >
      <Icon type="md-person" size="24" />
    </div>
  </div>
</template>
<script>
export default require("../../../script/component/ShopHeader").default;
</script>