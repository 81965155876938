export default {
  props: ["order"],
  data() {
    const payStatusMap = {
      0:this.$t("order_status_unpaid"),
      1: this.$t("order_status_paid"),
      2: this.$t("order_status_getandpay"),
      3: this.$t("order_status_canceled"),
    };
    const payStatus =
      payStatusMap[this.order.order_status.payment_status] ||
      this.$t("order_status_error");
    return {
      payStatus,
    };
  },
};