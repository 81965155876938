export enum ShopType {
    TCAT = 'TCAT',// 黑貓(宅配)
    ECAN = 'ECAN',// 宅配通
    FAMILY = 'FAMI',// 全家
    UNIMART = 'UNIMART',// 統一超商
    HILIFE = 'HILIFE',// 萊爾富
    FAMILY_C2C = 'FAMIC2C',// 全家店到店
    UNIMART_C2C = 'UNIMARTC2C',// 統一超商寄貨便
    HILIFE_C2C = 'HILIFEC2C'// 萊爾富店到店
}
