<template>
  <div class="colorTitle content page py-3">
    <div v-if="orders.length">
      <field-value
        class="pb-2"
        :field="$t('order_order_info')"
        value=""
        contentAlign="left"
        labelWidth="50%"
      />
      <Divider class="my-0 divider" />
      <div v-for="(orderItem, index) in orders" :key="orderItem.id">
        <OrderItemPC :order="orderItem" />
        <Divider v-if="index != orders.length - 1" class="my-0 bgGray" />
      </div>
    </div>
    <div v-else class="row align-items-center" style="height: 50vh">
      <div>{{ $t("orecords_no_order") }}</div>
      <div>
        <div
          class="roundButton2 p-2"
          @click="$router.push({ name: 'Home' })"
          style="width: 100px; margin: auto"
        >
          {{ $t("order_go_shopping") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default require("../../../script/pages/OrderRecords").default;
</script>