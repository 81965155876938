var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:!_vm.labelWidth ? 'row ' : 'row mx-0'},[_c('div',{class:!_vm.labelWidth ? 'col-4 ' : 'px-0',style:({
      'text-align': 'left',
      width: _vm.labelWidth ? ("" + _vm.labelWidth) : '',
    })},[_vm._v("\n    "+_vm._s(_vm.field)+"\n  ")]),_vm._v(" "),_c('div',{class:(!_vm.labelWidth ? 'col-8 ' : 'px-0 ') +
      (_vm.roundStyle ? 'roundStyle ' : '') +
      (_vm.red ? 'colorDarkRed ' : ''),style:({
      margin: 'auto',
      'text-align': _vm.contentAlign ? _vm.contentAlign : 'right',
      width: _vm.labelWidth ? ("calc( 100% - " + _vm.labelWidth + " )") : '',
      'white-space': 'pre-wrap',
    }),domProps:{"innerHTML":_vm._s(("" + (_vm.deleteValue || '') + _vm.value))}})])}
var staticRenderFns = []

export { render, staticRenderFns }