const UserApiHelper = require("../Utility/UserApiHelper").default;
export default {
  beforeCreate() {
    $loading(true);
    UserApiHelper.getPoints().then((result) => {
      if (result instanceof Array) {
        this.points = result;
      }
      $loading(false);
    });
  },
  data() {
    return {
      points: [],
    };
  },
  computed: {
    source: () => {},
  },
  methods: {
    getSource(type) {
      switch (type) {
        case "1":
          return this.$t("points_used_points");
        case "2":
          return this.$t("points_order_reward_points");
        case "3":
          return this.$t("points_promo_code_points");
        default:
          return this.$t("points_system_points");
      }
    },
  },
};