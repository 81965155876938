<template>
  <div class="mx-2 mt-3 mb-4">
    <div class="row" style="text-align: left">
      <div class="col-6 mx-0">
        <div class="py-1">
          <label>{{ order.id }}</label>
        </div>
        <div class="py-1">
          {{ $t("order_buy_amount") }}:
          <label class="colorDarkRed">NT${{ order.total_price }}</label>
        </div>
        <div class="py-1">
          <label>{{ $t("order_buy_count", [order.total_count]) }}</label>
        </div>
      </div>
      <div class="col-6 mx-0">
        <div class="py-1">
          <label>{{ order.timestamp }}</label>
        </div>
        <div class="py-1">
          {{ $t("order_status") }}: <label>{{ payStatus }}</label>
        </div>
      </div>
    </div>
    <div class="row text-dark justify-content-center py-1 my-3">
      <div
        class="roundButton p-2 ms-auto"
        style="width: 100px"
        @click="$router.push({ name: 'OrderDetail', params: { id: order.id } })"
      >
        {{ $t("order_order_detail") }}
      </div>
    </div>
  </div>
</template>
<script>
export default require("../../../script/component/OrderItem").default;
</script>
