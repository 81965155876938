
<template>
  <FormItem
    class="imgText"
    :prop="prop"
    style="justify-content: center; display: flex"
  >
    <img :src="src" />
    <Input
      :value="value ? value : ''"
      @input="update($event)"
      :placeholder="placeholder"
      autocomplete="on"
      :name="name"
      :type="type"
      :password="password"
      :maxlength="maxlength"
    ></Input>
  </FormItem>
</template>
<script>
export default {
  props: {
    src: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    prop: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    password: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: 20,
    },
  },
  methods: {
    update(value) {
      this.$emit("input", value);
    },
  },
};
</script>
