var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:!_vm.labelWidth ? 'row ' : 'row mx-0'},[_c('div',{class:!_vm.labelWidth ? 'col-4 ' : 'px-0',style:({
      'text-align': 'left',
      margin: 'auto',
      width: _vm.labelWidth ? _vm.labelWidth : '',
    })},[_vm._v("\n    "+_vm._s(_vm.field)+"\n  ")]),_vm._v(" "),_c('div',{class:!_vm.labelWidth ? 'col-8 ' : 'px-0',style:({
      color: _vm.red ? 'red' : '',
      width: _vm.labelWidth ? ("calc( 100% - " + _vm.labelWidth + " )") : '',
    })},[_c('div',{style:({
        position: 'relative',
        width: parseInt(_vm.height) * 5 + 'vmin',
        margin: '0 0 0 auto',
      })},[_c('img',{attrs:{"src":"/img/button_count.png"}}),_vm._v(" "),_c('div',{staticClass:"colorLight",staticStyle:{"display":"flex","justify-content":"center","position":"absolute","align-items":"center","top":"0px","left":"0px","width":"100%","height":"100%","font-size":"5.5vmin","font-weight":"bold"}},[_c('div',{staticClass:"bgLight",style:({
            height: _vm.height,
            width: _vm.height,
            'line-height': _vm.height,
            cursor: 'default',
            'border-radius': '1vmin',
          }),on:{"click":function($event){return _vm.update(_vm.value - 1)}}},[_vm._v("\n          －\n        ")]),_vm._v(" "),_c('input',{staticClass:"colorLight",style:({
            width: '10vmin',
            background: '#0000',
            border: '0px',
            'text-align': 'center',
            height: _vm.height,
            'font-size': '4vmin',
          }),attrs:{"type":"number"},domProps:{"value":_vm.showValue},on:{"input":function($event){return _vm.update($event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"bgLight",style:({
            height: _vm.height,
            width: _vm.height,
            'line-height': _vm.height,
            cursor: 'default',
            'border-radius': '1vmin',
          }),on:{"click":function($event){return _vm.update(_vm.value + 1)}}},[_vm._v("\n          ＋\n        ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }