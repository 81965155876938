import Login from "../../script/Login/Login";
import ProductApiHelper from "../../script/Utility/ProductApiHelper";
import ConfigApiHelper from "../../script/Utility/ConfigApiHelper";
export default {
  props: {
    isTop: {
      type: Boolean,
      default: false
    }
  },
  beforeCreate() {
    const logoutPage = [
      "Member",
      "Checkout",
      "OrderRecords",
      "OrderDetail",
      "Points",
    ];
    Login.init();
    Login.setOnLoginStatusChangeListener((data) => {
      const currentRoute = this.$router.currentRoute.name.replace("mobile", "");
      this.$store.commit("login", data);
      if (data.isLogin) {
        if (currentRoute == "Login") {
          const detail = data.detail;
          if (!detail.email || !detail.gender || !detail.birth) {
            this.$router.push({ name: "Signup" });
          } else {
            this.$router.push({ name: "Home" });
          }
        }
        if (data.detail.firstTimeLogin) {
          if (!getCookie("clickedPromoCode")) {
            $alert("新戶贈送折價券，馬上前往註冊信箱領取!", () => {
              setCookie("clickedPromoCode", true);
            });
          }
        }
      } else {
        if (logoutPage.find((page) => currentRoute.indexOf(page) >= 0)) {
          this.$router.push({ name: "Home" });
        }
      }
    });
    ProductApiHelper.getProductByCategory()
      .then((result) => {
        this.$store.commit("categories", result);
      })
      .catch((error) => {
        $alert(error.message);
      });
    if (!this.$store.state.config.banners) {
        ConfigApiHelper.getBanner(
            this.$route.params.isMobile ? "mobile" : "pc"
        ).then((result) => {
            this.$store.commit("banners", result);
        });
    }
    if (!this.$store.state.config.brand_stories) {
        ConfigApiHelper.getBrandStory(
            this.$route.params.isMobile ? "mobile" : "pc"
        ).then((result) => {
            this.$store.commit("brand_stories", result);
        });
    }
  },
  data() {
    const headerTitle = {
      Login: this.$t("header_title_login"),
      Signup: this.$t("header_title_signup"),
      Member: this.$t("header_title_member"),
      Points: this.$t("header_title_points"),
      Product: this.$t("header_title_product"),
      Cart: this.$t("header_title_cart"),
      Checkout: this.$t("header_title_checkout"),
      Order: this.$t("header_title_order"),
      OrderDetail: this.$t("header_title_order_detail"),
      SkinTestResult: this.$t("header_title_skintest_record"),
      ResetPassword: this.$t("header_title_reset_pwd"),
      ForgotPassword: this.$t("header_title_forgot_pwd"),
    };
    const routeName = this.$route.name.replace("mobile", "");
    return {
      loginInfo: Login.data,
      searchStr: "",
      title: headerTitle[routeName] ?? "",
      showSearch: routeName == "Home",
      headerTitle,
    };
  },
  mounted() {
    const routeName = this.$route.name.replace("mobile", "");
    if (routeName == "Errorpath") {
      this.$router.replace({ name: "Home" });
    }
  },
  watch: {
    searchStr(str) {
      this.$store.commit("search", str);
    },
    $route(to, from) {
      if (to.name == "Errorpath") {
        this.$router.replace({ name: "Home" });
      }
      const currentRoute = this.$route.name.replace("mobile", "");
      this.showSearch = currentRoute == "Home";
      this.title = this.headerTitle[currentRoute] ?? "";
      Login.check();
     
      if (currentRoute == "Member") {
        const detail = Login.data.detail;
        if (!detail.email || !detail.gender || !detail.birth) {
          this.$router.push({ name: "Signup" });
        }
      }
    },
  },
  methods: {
    clickItem(id) {
      const scrollToID = () => {
        if ($('#content')[0] && $(id)[0]) {
            $('#content').animate({ scrollTop: $('#content')[0].scrollTop + $(id).offset().top - 65 }, 'slow');
          }
      }
      const routeName = this.$route.name.replace("mobile", "");
      if (routeName != "Home") {
        this.$router.push({ name: "Home" });
        setTimeout(() => {
            scrollToID();
          }, 100);
      } else {
        scrollToID();
      }
      
    },
    goPage(page) {
      if (page == "Home") {
        this.searchStr = "";
      }
      if (page == "Member") {
        this.goMember();
      } else if (this.$router.currentRoute.name.replace("mobile", "") != page) {
        this.$router.push({ name: page });
      }
    },
    goMember() {
      const currentRoute = this.$router.currentRoute.name.replace("mobile", "");
      if (this.$store.state.loginInfo.isLogin) {
        if (currentRoute != "Member") {
          this.$router.push({ name: "Member" });
        }
      } else if (currentRoute != "Login") {
        this.$router.push({ name: "Login" });
      }
    },
    goProduct(productID) {
        this.$router.push({ name: "Product", params: { id: productID } });
    },
    clickSkinTest() {
      window.open(location.origin + "/SkinTest", "_blank");
    },
  },
};