
<template>
  <div class="imgText">
    <img class="img" :src="src" />
    <div class="text">{{ text }}</div>
  </div>
</template>
<script>
export default {
  props: {
    src: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
  methods: {},
};
</script>
