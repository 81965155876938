<template>
  <div>
    <Form
      ref="signupData"
      :model="signupData"
      :rules="ruleValidate"
      :label-width="80"
      class="signupText px-3 py-5 content"
    >
      <FormItem :label="$t('signup_email')" prop="email">
        <Input
          v-model="signupData.email"
          :placeholder="$t('signup_email_please')"
          autocomplete="on"
          name="email"
          :maxlength="50"
        ></Input>
      </FormItem>
      <div
        class="roundButton2 py-1"
        @click="sendToken()"
        :disabled="
          checkedEmail == '' || checkedEmail != signupData.email || timeLeft > 0
        "
        style="width: 32vmin; margin: 0 0 0 auto; margin-bottom: 5vmin"
      >
        {{
          timeLeft
            ? $t("signup_resend_valify_code", [timeLeft])
            : $t("signup_send_valify_code")
        }}
      </div>
      <FormItem :label="$t('signup_valify_code')" prop="token">
        <Input
          v-model="signupData.token"
          :placeholder="$t('signup_valify_code_please')"
          :maxlength="6"
        ></Input>
      </FormItem>
      <FormItem
        v-if="!fromThird"
        :label="$t('signup_password')"
        prop="password"
        aria-required="true"
      >
        <Input
          v-model="signupData.password"
          :placeholder="$t('signup_password_please')"
          type="password"
          autocomplete="on"
          name="password"
          password
          :maxlength="15"
        ></Input>
      </FormItem>
      <FormItem
        v-if="!fromThird"
        :label="$t('signup_password_confirn')"
        prop="repassword"
        aria-required="true"
      >
        <Input
          v-model="signupData.repassword"
          :placeholder="$t('signup_password_confirn')"
          type="password"
          autocomplete="on"
          name="new-password"
          password
          :maxlength="20"
        ></Input>
      </FormItem>
      <FormItem
        :label="$t('signup_mobile')"
        prop="mobile"
        aria-required="false"
      >
        <Input
          v-model="signupData.mobile"
          :placeholder="$t('signup_mobile_please')"
          autocomplete="on"
          name="tel-local"
          :maxlength="10"
        ></Input>
      </FormItem>
      <FormItem :label="$t('signup_birthday')" prop="birth">
        <DatePicker
          type="date"
          :placeholder="$t('signup_birthday_please')"
          v-model="signupData.birth"
          autocomplete="on"
          name="bday"
        ></DatePicker>
      </FormItem>
      <FormItem :label="$t('signup_gender')" prop="gender">
        <div class="row">
          <radio-button
            class="col-5 py-0"
            group="gender"
            :tag="1"
            :text="$t('signup_gender_man')"
            v-model="signupData.gender"
            :noImg="true"
          />
          <radio-button
            class="col-5 py-0"
            group="gender"
            :tag="0"
            :text="$t('signup_gender_woman')"
            v-model="signupData.gender"
            :noImg="true"
          />
        </div>
      </FormItem>

      <div style="display: flex; justify-content: center">
        <div class="roundButton2 p-1 m-3" @click="submit()" style="width: 80px">
          {{ $t("signup_signup") }}
        </div>
        <div class="roundButton p-1 m-3" style="width: 80px" @click="cancel()">
          {{ $t("common_no") }}
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <div
          class="roundButton2 py-0"
          style="width: 25vmin; margin: 1vmin"
          @click="$router.push({ name: 'PrivacyPolicy' })"
        >
          {{ $t("signup_privacy") }}
        </div>

        <div
          class="roundButton2 py-0"
          style="width: 25vmin; margin: 1vmin"
          @click="$router.push({ name: 'Services' })"
        >
          {{ $t("signup_service") }}
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
export default require("../../../script/pages/Signup").default;
</script>