<template>
  <div
    class="bgItem"
    :style="{
      width: width,
      'border-radius': '999vmin',
      position: 'relative',
    }"
  >
    <input
      :value="value ? value : ''"
      @input="update($event)"
      type="text"
      :placeholder="placeholder"
      style="
        height: 100%;
        width: 100%;
        border: 0px;
        background: #0000;
        padding: 0 4vmin;
      "
    />
    <div
      style="
        top: 0px;
        right: 0px;
        position: absolute;
        width: 10vmin;
        height: 100%;
        background: #90aab5;
        border-radius: 999vmin;
        flex-flow: column;
        display: flex;
      "
      onclick="onclick"
    >
      <img
        src="/img/search.png"
        style="margin: auto; height: 100%; width: auto; padding: 0.5vmin"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: ["width", "value", "placeholder", "onclick"],
  methods: {
    update(inputEvent) {
      this.$emit("input", inputEvent.target.value);
    },
  },
};
</script>