<template>
  <div class="page">
    <Form
      ref="loginData"
      :model="loginData"
      :rules="ruleValidate"
      :label-width="80"
      class="mb-3 content"
      style="
        width: 300px;
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
      "
    >
      <form-field-edit
        :field="$t('login_email')"
        prop="email"
        v-model="loginData.email"
        :placeholder="$t('login_email_please')"
        name="email"
        :maxlength="50"
      />
      <form-field-edit
        :field="$t('login_password')"
        prop="password"
        v-model="loginData.password"
        :placeholder="$t('login_password_please')"
        name="password"
        type="password"
        :password="true"
        :maxlength="15"
      />
      <div style="width: 85%; display: flex; justify-content: space-between">
        <checkbox-button
          :text="$t('login_save_account')"
          v-model="checkboxes"
          tag="saveAccount"
          :noImg="true"
        />
        <div
          class="button"
          @click="
            $router.push({
              name: 'ForgotPassword',
              params: { email: loginData.email },
            })
          "
        >
          忘記密碼
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <div class="m-3">
          <div class="roundButton2 p-1" @click="submit()">
            {{ $t("login_login") }}
          </div>
        </div>
        <div class="m-3">
          <div
            class="roundButton p-1"
            @click="
              $router.push({
                name: 'Signup',
                params: { email: loginData.email },
              })
            "
          >
            {{ $t("login_signup") }}
          </div>
        </div>
      </div>
    </Form>
    <!-- <div>目前狀態：{{ loginStatus }}</div> -->
    <div
      style="
        display: flex;
        justify-content: center;
        margin-top: 2vmin;
        width: 60vw;
        margin: auto;
      "
    >
      <!-- <third-login-button
        class="mx-1"
        src="/img/fb.png"
        @click.native="login('facebook')"
        buttonClass="fbButton"
      />
      <third-login-button
        class="mx-1"
        src="/img/line.png"
        @click.native="login('line')"
        buttonClass="lineButton"
      /> -->

      <!-- <img
        src="/img/icon_google.png"
        style="width: auto; height: 10vmin; margin: 1vmin; cursor: pointer"
        @click="login('google')"
      /> -->
    </div>
  </div>
</template>

<script>
export default require("../../../script/pages/Login").default;
</script>