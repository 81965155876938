<template>
  <div class="p-4">
    <Form
      class="py-3"
      ref="params"
      :label-width="100"
      :model="params"
      :rules="ruleValidate"
    >
      <form-field-edit
        v-if="fromMember"
        :field="$t('reset_pwd_origin_password')"
        v-model="params.oldPassword"
        :placeholder="$t('reset_pwd_origin_password_please')"
        name="password"
        type="password"
        :password="true"
        :maxlength="15"
      />
      <form-field-edit
        :field="$t('reset_pwd_new_password')"
        v-model="params.newPassword"
        :placeholder="$t('reset_pwd_new_password_please')"
        name="new-password"
        type="password"
        :password="true"
        :maxlength="20"
      />
    </Form>
    <div class="roundButton2" @click="submit()">
      {{ $t("reset_pwd_reset_password") }}
    </div>
  </div>
</template>

<script>
export default require("../../../script/pages/ResetPassword").default;
</script>