var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:!_vm.labelWidth ? 'row ' : 'row mx-0'},[_c('div',{class:!_vm.labelWidth ? 'col-4 ' : '',style:({
      'text-align': 'left',
      width: _vm.labelWidth ? ("" + _vm.labelWidth) : '',
    })},[_vm._v("\n    "+_vm._s(_vm.field)+"\n  ")]),_vm._v(" "),_c('div',{class:!_vm.labelWidth ? 'col-8 ' : '',style:({
      width: _vm.labelWidth ? ("calc( 100% - " + _vm.labelWidth + " )") : '',
      display: 'flex',
      'justify-content': _vm.align ? _vm.align : 'left',
    })},_vm._l((_vm.selections),function(selection){return _c('radio-button',{key:selection.tag,staticClass:"py-0 px-2",attrs:{"group":_vm.group,"tag":selection.tag,"text":selection.text,"noImg":true,"disabled":_vm.disabled}})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }