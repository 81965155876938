import { LoginType } from "../enum/LoginType";
import LoginInterface from "./LoginInterface";

export default class FBLogin extends LoginInterface {
    config = {
        loginType: LoginType.Facebook,
        appid: process.env.MIX_FB_APP_ID,// 測試用
        // appid: "175222097792489"
    }
    isInited: boolean = false
    initedCallback: Function = () => { }
    constructor() {
        super();
        this.defaultData.loginType = this.config.loginType;
        this.data = Object.assign({}, this.defaultData)
        // 載入 FB SDK
        window.fbAsyncInit = this.fbAsyncInit.bind(this);
        (function (d, s, id) {
            var js: any, fjs: any = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/zh_TW/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }
    public registCallback(callback: () => void): void {
        this.initedCallback = callback;
        if (this.isInited)
            callback();
    }
    public logout(callback: (response: StatusResponse) => void): void {
        this.checkLoginStatus(() => {
            FB.logout((response: fb.StatusResponse) => {
                this.statusChangeCallback(response, callback);
            })
        });
    }
    public login(callback: (response: StatusResponse) => void) {
        // location.href = `https://www.facebook.com/v10.0/dialog/oauth?client_id=${this.config.appid}&redirect_uri=https://localhost/login`
        FB.login((response: fb.StatusResponse) => {
            this.statusChangeCallback(response, callback);
        }, { scope: 'public_profile,email' });
    }
    public checkLoginStatus(callback: (response: StatusResponse) => void) {
        if (this.isInited) {
            FB.getLoginStatus((response: fb.StatusResponse) => {
                this.statusChangeCallback(response, callback);
            });
        }
    }
    private fbAsyncInit() {
        FB.init({
            appId: this.config.appid,
            cookie: true,
            xfbml: true,
            version: "v10.0"
        });
        this.isInited = true;
        this.initedCallback();
        log("FB inited");
    }
    // 處理各種登入身份
    private statusChangeCallback(fbresponse: fb.StatusResponse, callback: (response: StatusResponse) => void) {

        // 登入 FB 且已加入會員
        if (fbresponse.status === "connected") {
            this.loginSuccess();
            this.data.isLogin = true;
            FB.api("/me?fields=id,name,email", (response: facebook.User) => {
                this.data.detail.id = response.id;
                this.data.detail.display_name = response.name;
                this.data.detail.email = response.email;
                this.data.detail.photo = response.profile_pic;
                this.data.detail.birth = response.birthday;
                this.data.detail.gender = response.gender;
                callback(this.data);
            });
        } else {

            // 登入 FB, 未偵測到加入會員
            if (fbresponse.status === "not_authorized") {
                this.data.isLogin = false;
            }
            // 未登入 FB
            else {
                this.data.isLogin = false;
            }
            if (!this.data.isLogin && getCookie("LoginType") == LoginType.Facebook) {
                Object.assign(this.data, this.defaultData);
                this.logoutSuccess();
            }
            callback(this.data);
        }
    }
}
