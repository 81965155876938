<template>
  <div class="content colorTitle page py-3">
    <steps-pointer
      v-if="fromCheckout"
      class="mb-4"
      :steps="[
        $t('common_steps_cart_content'),
        $t('common_steps_order_finish'),
      ]"
      :current="1"
    />
    <div v-if="order.order_items.length">
      <div v-if="!fromCheckout">
        <field-value class="py-3" :field="$t('order_order_id')" :value="id" />
        <field-value
          class="pt-3"
          :field="$t('order_order_time')"
          :value="order.timestamp"
        />
      </div>
      <field-value
        class="pt-4 pb-2"
        style="padding-right: 5vmin"
        :field="$t('order_subtitle_product')"
        :value="$t('order_subtitle_count')"
      />
      <Divider class="my-0 divider mb-3" />
      <div
        v-for="(item, index) in order.order_items"
        :key="item.product_id + item.selection_id"
      >
        <CheckoutItemPC v-bind:product="item" />
        <Divider
          v-if="index != order.order_items.length - 1"
          class="my-0 bgGray my-3"
        />
      </div>
      <div v-if="fromCheckout" class="pt-5">
        <field-value class="py-3" :field="$t('order_order_id')" :value="id" />
        <field-value
          class="py-2"
          :field="$t('order_order_time')"
          :value="order.timestamp"
        />
      </div>
      <divider-text :text="$t('order_payment_detail')" class="pt-5" />
      <field-value
        class="py-2"
        :field="$t('order_order_amount')"
        :value="'$' + total"
      />
      <field-value
        class="py-2"
        :field="$t('order_promo_discount')"
        :value="'-$' + order.promo_code_discount"
      />
      <field-value
        class="py-2"
        :field="$t('order_points_discount')"
        :value="'-$' + order.used_points"
      />
      <field-value
        class="py-2"
        :field="$t('order_payment_shipfee')"
        :value="'$' + order.shipfee"
      />
      <field-value
        class="py-2"
        :field="$t('order_payment_amount')"
        :value="'$' + finalPrice"
        :red="true"
      />

      <field-value
        class="py-2"
        :field="$t('order_payment_status')"
        :value="payStatus"
        :red="false"
      />
      <field-value
        class="py-2"
        :field="$t('order_paymethod')"
        :value="PayMethodName"
        :red="false"
      />
      <divider-text :text="$t('order_shipping_info')" class="pt-5" />
      <field-value
        class="py-2"
        :field="$t('order_receiver_name')"
        :value="order.name"
        :red="false"
      />
      <field-value
        class="py-2"
        :field="$t('order_receiver_mobile')"
        :value="order.phone"
        :red="false"
      />
      <field-value
        class="py-2"
        field="Email"
        :value="order.email"
        :red="false"
      />
      <field-value
        class="py-2"
        :field="$t('order_shipping_method')"
        :value="DeliveryName"
        :red="false"
      />
      <field-value
        class="py-2"
        :field="$t('order_shipping_shop')"
        :value="ShopName"
        :red="false"
      />
      <field-value
        v-if="isCVS"
        class="py-2"
        :field="$t('order_shipping_store_name')"
        :value="order.logistics_store_name"
        :red="false"
      />
      <field-value
        v-if="isCVS"
        class="py-2"
        :field="$t('order_shipping_store_id')"
        :value="order.logistics_store_id"
        :red="false"
      />
      <field-value
        v-if="!isCVS"
        class="py-2"
        :field="$t('order_shipping_zipcode')"
        :value="order.zip_code"
        :red="false"
      />
      <field-value
        v-if="!isCVS"
        class="py-2"
        :field="$t('order_shipping_address')"
        :value="order.address"
        :red="false"
      />

      <field-value
        class="py-2"
        :field="$t('order_shipping_status')"
        :value="shippingStatus"
        :red="false"
      />
      <field-value
        class="py-2"
        :field="$t('order_shipping_memo')"
        :value="order.memo"
        :red="false"
      />
    </div>
    <div v-if="showPayNow" class="roundButton2 mt-3" @click="payNow()">
      {{ $t("order_pay_now") }}
    </div>
  </div>
</template>
<script>
export default require("../../../script/pages/OrderDetail").default;
</script>