<template>
  <div class="row justify-content-center colorTitle content">
    <div class="page">
      <steps-pointer
        class="mb-4"
        :steps="[
          $t('common_steps_cart_content'),
          $t('common_steps_order_finish'),
        ]"
        :current="0"
      />
      <field-value
        class="pt-4 pb-2"
        style="padding-right: 5vmin"
        :field="$t('checkout_subtitle_product')"
        :value="$t('checkout_subtitle_count')"
        prop="total"
      />
      <Divider class="my-0 divider" />
      <CheckoutItemPC
        v-for="product in cartList"
        class="mb-3"
        :key="product.id + product.selection_id"
        v-bind:product="product"
      />
      <Divider class="my-4 divider" />
      <Form
        ref="receiver"
        :model="receiver"
        :rules="ruleValidate"
        :label-width="100"
      >
        <!-- <form-field-edit
          :field="
            $t('checkout_use_points', [$store.state.loginInfo.detail.points])
          "
          prop="usePoints"
          :value="usePoints ? usePoints : ''"
          @input="usePoints = $event"
          placeholder="輸入欲折抵點數"
          :maxlength="5"
        /> -->
        <form-field-edit
          v-model="promoCode"
          :field="$t('checkout_promo_code')"
          prop="promoCode"
          placeholder="輸入優惠碼"
          :maxlength="16"
        />

        <div class="py-4">
          <divider-text :text="$t('checkout_logistics_type')" />
          <div class="my-4 row justify-content-center">
            <radio-button
              class="p-1 col-3"
              group="logisticsType"
              :tag="DeliveryMethod.HOME"
              :text="$t('common_logistics_home')"
              v-model="logisticsType"
            />
            <radio-button
              class="p-1 col-3"
              group="logisticsType"
              :tag="DeliveryMethod.CVS"
              :text="$t('common_logistics_cvs')"
              v-model="logisticsType"
            />
          </div>

          <Divider v-show="isCVS" class="my-0 bgGray" />
          <div v-show="isCVS" class="row justify-content-center">
            <!-- <div class="col-5">
              <radio-button
                class="my-4"
                group="send_shop"
                :tag="ShopType.TCAT"
                :text="ShopName(ShopType.TCAT)"
                v-model="logisticsInfo.send_shop"
              />
              <radio-button
                class="my-4"
                group="send_shop"
                :tag="ShopType.ECAN"
                :text="ShopName(ShopType.ECAN)"
                v-model="logisticsInfo.send_shop"
              />
            </div> -->
            <div class="my-4 row justify-content-center">
              <!--
              <radio-button
                class="p-1 col-3"
                group="send_shop"
                :tag="ShopType.UNIMART"
                :text="ShopName(ShopType.UNIMART)"
                v-model="logisticsInfo.send_shop"
              />
              -->
              <radio-button
                class="p-1 col-3"
                group="send_shop"
                :tag="ShopType.FAMILY"
                :text="ShopName(ShopType.FAMILY)"
                v-model="logisticsInfo.send_shop"
              />
              <!-- <radio-button
                class="my-4"
                group="send_shop"
                :tag="ShopType.HILIFE"
                :text="ShopName(ShopType.HILIFE)"
                v-model="logisticsInfo.send_shop"
              /> -->
            </div>
          </div>

          <div v-if="isCVS">
            <Divider class="mt-0 bgGray" />
            <div v-if="logisticsInfo.send_shop">
              <div
                class="roundButton"
                @click="selectMap(logisticsInfo.send_shop)"
              >
                {{ $t("checkout_logistics_cvs_store_select") }}
              </div>
              <div v-if="isCVS && logisticsInfo.store_code">
                <Divider class="bgGray" />
                <field-value
                  :field="$t('common_logistics_cvs_store_code')"
                  :value="logisticsInfo.store_code"
                />
                <field-value
                  :field="$t('common_logistics_cvs_store_name')"
                  :value="logisticsInfo.store_name"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="py-0">
          <divider-text class="mb-4" :text="$t('checkout_receiver_info')" />
          <form-field-edit
            v-model="receiver.name"
            :field="$t('checkout_receiver_name')"
            prop="name"
            placeholder="ex: 容小易"
            :maxlength="10"
          />
          <form-field-address
            v-show="!isCVS"
            v-model="addressData"
            :field="$t('checkout_receiver_address')"
            prop="address"
            placeholder="ex: 市政北二路238號14樓B2"
            :maxlength="60"
          />

          <form-field-edit
            v-model="receiver.mobile"
            :field="$t('checkout_receiver_mobile')"
            prop="mobile"
            placeholder="ex: 0912345678"
            :maxlength="10"
          />
          <form-field-edit
            v-model="receiver.email"
            field="E-Mail"
            prop="email"
            placeholder="ex: royi@louyiai.com"
            :maxlength="200"
          />
          <form-field-edit
            v-model="receiver.memo"
            :field="$t('checkout_receiver_memo')"
            placeholder="輸入備註"
            prop="memo"
            :maxlength="200"
          />
          <checkbox-button
            class="my-1 mybg"
            tag="cacheData"
            :text="$t('checkout_save_order_data')"
            v-model="selectedCheckboxes"
            :noImg="true"
            style="width: 100% display: flex; justify-content: flex-end;"
          />
        </div>
        <div class="my-auto">
          <divider-text :text="$t('checkout_paymethod_select')" />
          <div class="my-4 row justify-content-center">
            <radio-button
              class="my-4 col-3"
              group="pay_method"
              :tag="PayMethod.Credit"
              :text="PayMethodName(PayMethod.Credit)"
              v-model="pay_method"
            />
            <!-- <radio-button
              class="my-2"
              group="pay_method"
              :tag="PayMethod.WebATM"
              :text="PayMethodName(PayMethod.WebATM)"
              v-model="pay_method"
            />
            <radio-button
              class="my-2"
              group="pay_method"
              :tag="PayMethod.CVS"
              :text="PayMethodName(PayMethod.CVS)"
              v-model="pay_method"
            />
            <radio-button
              class="my-2"
              group="pay_method"
              :tag="PayMethod.BARCODE"
              :text="PayMethodName(PayMethod.BARCODE)"
              v-model="pay_method"
            /> -->
            <radio-button
              class="my-4 col-3"
              group="pay_method"
              :tag="PayMethod.GetAndPay"
              :text="PayMethodName(PayMethod.GetAndPay)"
              v-model="pay_method"
            />
          </div>
        </div>
      </Form>
      <divider-text :text="$t('checkout_payment_detail')" />
      <field-value
        class="my-3 md-2"
        :field="$t('checkout_payment_order_amount')"
        :value="total"
      />
      <field-value
        class="my-3"
        :field="$t('checkout_payment_promo_code_discount')"
        :value="-promoDiscount"
      />
      <!-- <field-value
        class="my-3"
        :field="$t('checkout_payment_use_points')"
        :value="-showUsePoint"
      /> -->
      <field-value
        class="my-3"
        :field="$t('checkout_payment_shipfee')"
        :value="shipFee"
      />
      <field-value
        class="my-3"
        :field="$t('checkout_payment_shipfee_discount')"
        :value="-shipFeeDiscount"
      />
      <field-value
        class="my-3"
        :field="$t('checkout_payment_total_amount')"
        :value="finalPrice"
        :red="true"
      />

      <div class="mt-5">
        <image-text
          class="subTitle colorWhite button"
          src="/img/button_finish.png"
          :text="$t('checkout_payment_commit')"
          @click.native="submit()"
          style="width: 35vmin; margin: auto"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default require("../../../script/pages/Checkout").default;
</script>