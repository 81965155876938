import { ApiMethods } from "../enum/ApiMethods";
import ApiHelper from "./ApiHelper";
const orderApi = require("../../../../storage/app/apiPath/order.json");

export default class OrderApiHelper {
    public static openRepayOrder(orderItems: any) {
        return ApiHelper.openOutsideByPath(ApiMethods.POST, orderApi.repay, orderItems);
    }
    public static openCreateOrder(orderItems: any) {
        return ApiHelper.openOutsideByPath(ApiMethods.POST, orderApi.create, orderItems);
    }
    public static createOrder(orderItems: any) {
        return ApiHelper.callSelf(ApiMethods.POST, orderApi.create, orderItems);
    }
    public static selectStore(shopInfo: any) {
        return ApiHelper.openOutsideByPath(ApiMethods.POST, orderApi.selectStore, shopInfo);
    }
    public static checkPromoCode(promoCode: string) {
        return ApiHelper.callSelf(ApiMethods.POST, orderApi.checkPromoCode, { promo_code: promoCode });
    }
    public static getOrderRecords() {
        return ApiHelper.callSelf(ApiMethods.GET, orderApi.getOrder);
    }
    public static getOrderDetail(id: string) {
        return ApiHelper.callSelf(ApiMethods.GET, `${orderApi.getOrder}/${id}`);
    }
    public static getTradeNo() {
        return ApiHelper.callSelf(ApiMethods.GET, orderApi.getTradeNo);
    }


}