<template>
  <div
    class="row text-dark x-md-center subTitle"
    :class="!canBuy ? 'bgLight' : ''"
    style="position: relative"
  >
    <div style="display: flex; height: 200px; width: auto">
      <img
        v-lazy="product.imgs[0] ? product.imgs[0] : '/img/lady.png'"
        style="margin: auto; height: 100%; width: auto"
      />
    </div>
    <div class="col-8 align-self-center">
      <div style="text-align: left">
        {{ product.name }}
      </div>
      <div
        class="content colorGray"
        style="text-align: left; max-width: 90%"
        v-html="content"
      >
        {{ content }}
      </div>

      <div
        class="colorDarkRed"
        style="
          text-align: left;
          padding-top: 3vmin;
          padding-left: 1vmin;
          font-weight: normal;
        "
      >
        <span v-if="originPrice" class="priceDelete">NT${{ originPrice }}</span>
        NT${{ priceText }}
      </div>
    </div>
    <div
      class="p-0"
      style="
        padding-right: 0;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 20px;
        display: inherit;
      "
    >
      <img
        class="button"
        src="/img/no.png"
        style="margin: auto"
        @click="remove()"
      />
    </div>
    <div
      style="
        padding-right: 0;
        position: absolute;
        right: 0;
        bottom: 20px;
        width: auto;
      "
    >
      <div v-if="canBuy">
        <InputNumber v-model="product.count" controls-outside></InputNumber>
        <div v-if="product.inventory != null && product.inventory < product.count" class="colorRed">
          {{ $t("product_product_out_of_stock_message") }}
        </div>
      </div>
      <div v-else class="content colorGray">
        {{ $t("product_out_of_stock") }}
      </div>
    </div>
  </div>
</template>
<script>
export default require("../../../script/pages/ShoppingCartItem").default;
</script>