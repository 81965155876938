import { LoginType } from "../enum/LoginType";
export default abstract class LoginInterface {
    defaultData = {
        loginType: LoginType.None,
        isLogin: false,
        message: '',
        detail: {
            id: "",
            birth: "",
            display_name: "",
            photo: "",
            email: "",
            email_valid: 0,
            gender: -1,
            memo: "",
            phone: "",
            points: 0,
            regist_time: ""
        }
    }
    data: StatusResponse = this.defaultData
    protected loginSuccess = () => {
        setCookie("LoginType", this.data.loginType);
    }
    protected logoutSuccess = () => {
        setCookie("LoginType", "");
        setCookie('royishopToken', "");
    }
    abstract login(callback: (response: StatusResponse) => void): void;
    abstract logout(callback: (response: StatusResponse) => void): void;
    abstract checkLoginStatus(callback: (response: StatusResponse) => void): void;
    abstract registCallback(callback: () => void): void;
}

