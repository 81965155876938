<template>
  <div>
    <div style="position: absolute; z-index: -1; width: 100%">
      <img :src="`/SkinTest/resources/results/${results.join('')}.png`" />
    </div>
    <div class="row justify-content-center mx-0" style="padding-top: 46%">
      <div
        :id="resultBID"
        class="col-4 buttonMove"
        @click="onClickSelection(resultBID)"
      >
        <img :src="`/SkinTest/resources/product/${results[1]}.png`" />
      </div>
      <div
        :id="resultAID"
        class="col-4 buttonMove"
        @click="onClickSelection(resultAID)"
      >
        <img :src="`/SkinTest/resources/product/${results[0]}.png`" />
      </div>
    </div>
    <div
      class="row justify-content-center mx-0 px-4"
      style="margin-top: 71%; position: relative"
    >
      <!-- Facebook -->
      <div class="col-3 col-lg-2 buttonMove">
        <img src="/SkinTest/resources/icon-facebook.png" @click="share('fb')" />
      </div>

      <!-- line -->
      <div class="col-3 col-lg-2 buttonMove">
        <img src="/SkinTest/resources/icon-line.png" @click="share('line')" />
      </div>
    </div>
  </div>
</template>

<script>
export default require("../../../script/pages/SkinTestResult").default;
</script>