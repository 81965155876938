import { ApiMethods } from "../enum/ApiMethods";
import ApiHelper from "./ApiHelper";
const productApi = require("../../../../storage/app/apiPath/product.json");


export default class ProductApiHelper {
    public static getProducts(productIds?: Array<string>): Promise<Array<Product>> {
        return ApiHelper.callSelf(ApiMethods.GET, productApi.product, productIds ? { productIds: productIds.join(',') } : null);
    }
    public static getProductByCategory(): Promise<Array<Product>> {
        return ApiHelper.callSelf(ApiMethods.GET, productApi.productByCategory);
    }
    public static getProduct(id: string): Promise<Product> {
        return ApiHelper.callSelf(ApiMethods.GET, `${productApi.product}/${id}`);
    }

}