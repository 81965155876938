<template>
  <div :class="!labelWidth ? 'row ' : 'row mx-0'">
    <div
      :class="!labelWidth ? 'col-4 ' : 'px-0'"
      :style="{
        'text-align': 'left',
        width: labelWidth ? `${labelWidth}` : '',
      }"
    >
      {{ field }}
    </div>
    <div
      :class="
        (!labelWidth ? 'col-8 ' : 'px-0 ') +
        (roundStyle ? 'roundStyle ' : '') +
        (red ? 'colorDarkRed ' : '')
      "
      :style="{
        margin: 'auto',
        'text-align': contentAlign ? contentAlign : 'right',
        width: labelWidth ? `calc( 100% - ${labelWidth} )` : '',
        'white-space': 'pre-wrap',
      }"
      v-html="`${deleteValue || ''}${value}`"
    ></div>
  </div>
</template>
<script>
export default {
  props: [
    "field",
    "value",
    "red",
    "labelWidth",
    "contentAlign",
    "roundStyle",
    "deleteValue",
  ],
  mounted() {},
};
</script>
