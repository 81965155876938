import './bootstrap';
import './script/Common.js';
import 'view-design/dist/styles/iview.css';
import 'swiper/css/swiper.css'
import 'viewerjs/dist/viewer.css';
import 'wowjs/css/libs/animate.css'
import 'jquery-twzipcode';
import '../css/animation.scss';
import '../css/app-d768.scss';
import '../css/app-u768.scss';
import '../css/app.scss';
import router from './router';
import Vue from 'vue';
import VueLazyload from 'vue-lazyload';
import ViewUI from 'view-design';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import i18n from './i18n/i18n.js'
import VueVisible from 'vue-visible';
import Viewer from 'v-viewer';

const store = require('./store.js').default;
const files = require.context('./', true, /\.vue$/i)
files.keys()
    .filter(key => {
        const notPCMobile = (key.indexOf("/PC/") == -1 && key.indexOf("/Mobile/") == -1);
        if (key.indexOf("/component/") >= 0) {
            return true
        }
        return notPCMobile
    })
    .forEach(key => {

        if (key.indexOf("/component/") >= 0) {
            if (key.indexOf("/PC/") >=0) {
                Vue.component(key.split('/').pop().split('.')[0] + "PC", copy(files(key).default));
            } else if (key.indexOf("/Mobile/") >= 0) {
                Vue.component(key.split('/').pop().split('.')[0] + "Mobile", copy(files(key).default));
            } else {
                Vue.component(key.split('/').pop().split('.')[0], files(key).default)
            }
        } else {
            Vue.component(key.split('/').pop().split('.')[0], files(key).default)
        }
        
    });
Vue.use(Viewer);
Vue.use(VueVisible);
Vue.use(ViewUI);
Vue.use(VueAwesomeSwiper);
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: '/img/lady.png',
    loading: '/img/lady.png',
    attempt: 1
});

const app = new Vue({
    i18n,
    router,
    el: '#app',
    store
});


