export default {
  common_use_outside_browser: '请使用外部浏览器以达到最佳体验',
  common_system_error: '系统忙碌中, 请稍后再试({0})',
  common_check_fields: '请检查栏位是否输入正确',
  common_yes: '确定',
  common_no: '取消',
  common_modify_success: '修改成功',
  common_steps_cart_content: '购物车内容',
  common_steps_order_finish: '订单完成',
  common_shop_tcat: '黑猫宅急便',
  common_shop_ecan: '大嘴鸟宅配通',
  common_shop_family: '全家便利商店',
  common_shop_711: '7-11',
  common_shop_hilife: '莱尔富',
  common_paymethod_credit: '信用卡/金融卡',
  common_paymethod_webatm: '网路ATM',
  common_paymethod_cvs: '超商代码',
  common_paymethod_barcode: '超商条码',
  common_paymethod_getandpay: '货到付款',
  common_logistics_home: '宅配',
  common_logistics_home_shop: '宅配业者',
  common_logistics_cvs: '超商取货',
  common_logistics_cvs_shop: '取货超商',
  common_logistics_cvs_store_code: '门市代码',
  common_logistics_cvs_store_name: '门市名称',
  header_search_placeholder: '搜寻商品',
  header_title_login: '登入',
  header_title_signup: '注册',
  header_title_member: '会员专区',
  header_title_product: '商品详情',
  header_title_points: '点数纪录',
  header_title_cart: '购物车',
  header_title_checkout: '结帐',
  header_title_order: '订单纪录',
  header_title_order_detail: '订单明细',
  header_title_skintest_record: '肤质检测纪录',
  header_title_reset_pwd: '重设密码',
  header_title_forgot_pwd: '忘记密码',
  footer_contact_phone: '连络电话: {0}',
  footer_contact_email: 'E-Mail: {0}',
  signup_email: 'E-Mail',
  signup_email_please: '请输入E-Mail',
  signup_email_wrong_format: 'E-Mail格式有误',
  signup_email_valify_first: '请先验证email',
  signup_valify_code: '验证码',
  signup_valify_code_please: '请输入验证码',
  signup_send_valify_code: '发送验证码',
  signup_password: '密码',
  signup_password_please: '请设定6-15码密码',
  signup_password_not_less_six: '密码至少六个字',
  signup_password_confirn: '密码确认',
  signup_password_confirn_please: '请再次输入密码',
  signup_password_not_match: '两次密码输入不相符',
  signup_mobile: '手机号码',
  signup_mobile_please: '请输入手机号码',
  signup_mobile_wrong_format: '手机格式错误',
  signup_birthday: '生日',
  signup_birthday_please: '请选择生日',
  signup_gender: '性别',
  signup_gender_man: '男',
  signup_gender_woman: '女',
  signup_gender_please: '请选择性别',
  signup_signup: '注册',
  signup_button_finish: '点我完成',
  signup_resend_valify_code: '重新发送: {0}s',
  signup_privacy: '隐私权政策',
  signup_service: '服务条款',
  login_email: 'E-Mail',
  login_email_please: '请输入E-Mail',
  login_email_wrong_format: 'E-Mail格式有误',
  login_password: '密码',
  login_password_please: '请设定6-15码密码',
  login_password_not_less_six: '密码至少六个字',
  login_signup: '注册',
  login_login: '登入',
  login_slogan: '让美丽变得更容易',
  login_save_account: '记忆帐号',
  home_product_not_found: '找不到任何商品',
  product_add_to_cart: '加入购物车',
  product_buy_directly: '直接购买',
  product_add_to_cart_success: '{0} 成功加入购物车',
  product_product_id: '产品编号',
  product_product_price: '商品价格',
  product_product_count: '购买数量',
  product_shipfee_free: '全馆满{0}元免运费',
  product_product_specification: '商品规格',
  product_product_life_time: '保存期限',
  product_product_origin: '产地',
  product_price: '售价',
  product_detail: '商品特色',
  product_recommand: '向您推荐',
  product_out_of_stock: '缺货中',
  product_product_out_of_stock: '商品缺货中',
  product_product_out_of_stock_message: '商品库存不足',
  member_user_id: '会员编号',
  member_points: '累积点数',
  member_gender: '性别',
  member_gender_woman: '女',
  member_gender_man: '男',
  member_birth: '生日',
  member_phone: '电话号码',
  member_email: 'E-Mail',
  member_my_points: '我的点数',
  member_order_records: '订单纪录',
  member_button_search: '点我查询',
  member_logout: '登出',
  member_skintest_records: '肤质检测纪录',
  member_skintest_records_notfount: '未侦测到您的检测纪录',
  member_skintest_records_test_now: '马上去测验',
  member_password: '密码',
  member_password_reset: '点我重设',
  
  points_source: '来源',
  points_points: '点数',
  points_time: '时间',
  points_no_points: '目前没有点数',
  points_used_points: '使用点数',
  points_order_reward_points: '订单点数回馈',
  points_promo_code_points: '优惠码点数',
  points_system_points: '系统分配',
  orecords_order_id: '订单编号',
  orecords_order_amount: '订单金额',
  orecords_order_time: '订单时间',
  orecords_no_order: '目前没有订单',
  order_order_id: '订单编号',
  order_order_amount: '订单金额',
  order_order_time: '订单时间',
  order_order_info: '订单资讯',
  order_order_detail: '订单明细',
  order_promo_discount: '优惠码折扣',
  order_points_discount: '点数折抵',
  order_payment_detail: '付款详情',
  order_payment_amount: '付款金额',
  order_payment_status: '付款状态',
  order_payment_shipfee: '运费',
  order_shipping_status: '货运状态',
  order_status_paid: '已付款',
  order_status_unpaid: '未付款',
  order_status_canceled: '已取消',
  order_status_getandpay: '货到付款',
  order_status_error: '订单异常',
  order_subtitle_product: '商品',
  order_subtitle_count: '数量',
  order_paymethod: '付款方式',
  order_shipping_info: '货运资讯',
  order_shipping_method: '运送方式',
  order_shipping_shop: '运送商家',
  order_shipping_store_name: '运送店名',
  order_shipping_store_id: '运送店号',
  order_shipping_zipcode: '邮递区号',
  order_shipping_address: '地址',
  order_shipping_memo: '交易备注',
  order_receiver_name: '收件人',
  order_receiver_mobile: '手机号码',
  order_buy_count: '{0}商品',
  order_buy_amount: '购买金额',
  order_pay_now: '立即付款',
  order_status: '状态',
  order_go_shopping: '马上去购物',
  cart_total_amount: '小计',
  cart_notthing_in_cart: '购物车空空如也',
  cart_login_please: '请先登入',
  cart_checkout: '立即结帐',
  cart_select_more: '选择更多商品',
  cart_go_shopping: '马上去购物',
  scitem_product_name: '品名',
  scitem_product_price: '价格',
  scitem_product_count: '数量',
  scitem_product_clear_shoping_car: '清空購物車',
  scitem_product_remove_confirm: '删除该项商品',
  coitem_product_name: '品名',
  coitem_product_price: '价格',
  coitem_product_count: '数量',
  checkout_subtitle_product: '商品',
  checkout_subtitle_count: '数量',
  checkout_save_order_data: '记忆结帐资料',
  checkout_use_points_please: '输入折抵点数',
  checkout_use_points: '点数折抵[{0}]',
  checkout_promo_code_please: '输入优惠码',
  checkout_promo_code: '优惠码',
  checkout_logistics_type: '寄送方式',
  checkout_logistics_home_shop_select: '请选择宅配业者',
  checkout_logistics_cvs_select: '请选择取货超商',
  checkout_logistics_cvs_store_select: '选择取件门市',
  checkout_logistics_cvs_store_select_please: '请选择取件门市',
  checkout_receiver_info: '收件人资料',
  checkout_receiver_name: '收件人',
  checkout_receiver_zipcode: '邮递区号',
  checkout_receiver_address: '地址',
  checkout_receiver_mobile: '手机号码',
  checkout_receiver_memo: '交易备注',
  checkout_payment_detail: '付款详情',
  checkout_payment_order_amount: '订单金额',
  checkout_payment_promo_code_discount: '优惠折扣',
  checkout_payment_use_points: '点数折抵',
  checkout_payment_shipfee: '运费',
  checkout_payment_shipfee_discount: '運費折抵',
  checkout_payment_total_amount: '总付款金额',
  checkout_payment_commit: '立即结帐',
  checkout_name_min_6: '收件人地址最少六个字',
  checkout_zipcode_please: '请输入邮递区号',
  checkout_zipcode_wrong_format: '邮递区号格式错误',
  checkout_name_please: '请输入姓名',
  checkout_address_please: '请输入地址',
  checkout_mobile_please: '请输入手机号码',
  checkout_mobile_wrong_format: '手机号码格式错误',
  checkout_use_points_wrong_format: '输入错误',
  checkout_use_points_not_enugh: '可用点数不足',
  checkout_paymethod_select_plaese: '请选择付款方式',
  checkout_paymethod_select: '付款方式',
  checkout_commit_confirm: '请确认您的资料是否正确',
  forgot_pwd_resend: '重新发送: {0}s',
  forgot_pwd_send: '发送',
  reset_pwd_origin_password: '原密码',
  reset_pwd_origin_password_please: '请输入原密码',
  reset_pwd_new_password: '新密码',
  reset_pwd_new_password_please: '请设定6-15码密码',
  reset_pwd_reset_password: '修改密码',
  shipping_status_not_found: "订单异常",
  shipping_status:
  {
    0: "订单处理中",
    1: "已出货",
    3: "已取消",
    300: "订单处理中",
    310: "订单上传处理中",
    311: "上传退货电子订单处理中",
    325: "退货订单处理中(已收到订单资料)",
    2000: "出货订单修改",
    2001: "档案传送成功",
    2002: "出货单号不合规则",
    2003: "XML档内出货单号重复",
    2004: "出货单号重复上传使用(验收时发现)",
    2005: "日期格式不符",
    2006: "订单金额或代收金额错误",
    2007: "商品类型为空",
    2008: "订单为空",
    2009: "门市店号为空",
    2010: "出货日期为空",
    2011: "出货金额为空",
    2012: "出货编号不存在",
    2013: "母厂商不存在",
    2014: "子厂商不存在",
    2015: "出货编号已存在(单笔)",
    2016: "门市已关转店，将进行退货处理",
    2017: "出货日期不符合规定",
    2018: "服务类型不符规定(如只开取货付款服务，确使用纯取货服务)",
    2019: "商品类型不符规定",
    2020: "厂商尚未申请店配服务",
    2021: "同一批次出货编号重覆(批次)",
    2022: "出货金额不符规定",
    2023: "取货人姓名为空",
    2024: "超商接受资料中",
    2025: "门市转店号(旧门市店号已更新)",
    2026: "无此门市，将进行退货处理",
    2027: "门市指定时间不配送(六、日)",
    2028: "门市关转店，3日内未更新SUP(新店号)便至退货流程",
    2029: "门市尚未开店",
    2030: "物流中心验收成功",
    2031: "未到货(物流端未收到该商品)",
    2032: "商品瑕疵(进物流中心)",
    2033: "超材",
    2034: "违禁品(退货及罚款处理)",
    2035: "订单资料重覆上传",
    2036: "已过门市进货日（未于指定时间内寄至物流中心）",
    2037: "门市关转(可使用SUP档案更新原单号更新门市与出货日)",
    2038: "第一段标签规格错误",
    2039: "第一段标签无法判读",
    2040: "第一段标签资料错误",
    2041: "物流中心理货中",
    2042: "商品遗失",
    2043: "门市指定不配送(六、日)",
    2044: "物流中心验收退货完毕",
    2045: "不正常到货(商品提早到物流中心)",
    2046: "货件未取退回大智通物流中心",
    2047: "正常二退(退货时间延长，在判赔期限内退回)",
    2048: "商品瑕疵(商品在物流中心)",
    2049: "门市关店，将进行退货处理",
    2050: "门市转店，将进行退货处理",
    2051: "厂商要求提早退货（厂商出错商品）",
    2052: "违禁品(退货及罚款处理)",
    2053: "刷A给B",
    2054: "消费者要求提早拉退（消费者下订单后又跟厂商取消）",
    2055: "更换门市",
    2057: "车辆故障，后续配送中",
    2058: "天候不佳，后续配送中",
    2059: "道路中断，后续配送中",
    2060: "门市停业中，将进行退货处理",
    2061: "缺件(商品未至门市)",
    2062: "门市报缺",
    2063: "门市配达",
    2065: "EC收退",
    2066: "异常收退(商品破损、外袋破损、消费者取错件、误刷代收等，提早从门市退货)",
    2067: "消费者成功取件",
    2068: "交货便收件(A门市收到件寄件商品)",
    2069: "退货便收件(商品退回指定C门市)",
    2070: "退回原寄件门市且已取件",
    2071: "门市代码格式错误",
    2072: "商品配达卖家取退货门市",
    2073: "商品配达买家取货门市",
    2074: "消费者七天未取，商品离开买家取货门市",
    2075: "厂商未至门市取退货，商品离开卖家取退货门市",
    2076: "消费者七天未取，商品退回至大智通",
    2077: "厂商未至门市取退货，商品退回至大智通",
    2078: "买家未取货退回物流中心-验收成功",
    2079: "买家未取货退回物流中心-商品瑕疵(进物流中心)",
    2080: "买家未取货退回物流中心-超材",
    2081: "买家未取货退回物流中心-违禁品(退货及罚款处理)",
    2082: "买家未取货退回物流中心-订单资料重覆上传",
    2083: "买家未取货退回物流中心-已过门市进货日（未于指定时间内寄至物流中心）",
    2084: "买家未取货退回物流中心-第一段标签规格错误",
    2085: "买家未取货退回物流中心-第一段标签无法判读",
    2086: "买家未取货退回物流中心-第一段标签资料错误",
    2087: "买家未取货退回物流中心-物流中心理货中",
    2088: "买家未取货退回物流中心-商品遗失",
    2089: "买家未取退回物流中心-门市指定不配送(六、日)",
    2092: "买家未取退回物流中心-门市关转",
    2093: "买家未取退回物流中心-爆量",
    2094: "包裹异常不配送",
    2095: "天候路况不佳",
    2101: "门市关转店",
    2102: "门市旧店号更新",
    2103: "无取件门市资料",
    2104: "门市临时关转店",
    2105: "已申请门市变更",
    3001: "转运中(即集货)",
    3002: "不在家",
    3003: "配完",
    3004: "送错BASE (送错营业所)",
    3005: "送错CENTER(送错转运中心)",
    3006: "配送中",
    3007: "公司行号休息",
    3008: "地址错误，联系收件人",
    3009: "搬家",
    3010: "转寄(如原本寄到A，改寄B)",
    3011: "暂置营业所(收件人要求至营业所取货)",
    3012: "到所(收件人要求到站所取件)",
    3013: "当配下车(当日配送A至B营业所，已抵达B营业所)",
    3014: "当配上车(当日配送从A至B营业所，已抵达A营业所)",
    3015: "空运配送中",
    3016: "配完状态删除",
    3017: "退回状态删除(代收退货删除)",
    3018: "到店尚未取货，简讯通知取件",
    3019: "退件到店尚未取货，简讯通知取件",
    3020: "货件未取退回物流中心",
    3021: "退货商品未取退回物流中心",
    3022: "买家已到店取货",
    3023: "卖家已取买家未取货",
    3024: "货件已至物流中心",
    3025: "退货已退回物流中心",
    3029: "商品已转换店（商品送达指定更换之取件店铺）",
    3031: "退货商品已转换店（退货商品送达指定更换之取件店铺）",
    3032: "卖家已到门市寄件",
    3110: "转配邮局",
    3111: "配送外包",
    3112: "再配",
    3113: "异常",
    3114: "再取",
    3117: "拒收(调查处理中)",
    3118: "暂置营业所",
    3119: "暂置营业所(假日暂置)",
    3120: "预备配送中",
    3121: "转交门市配达",
    3122: "另约时间",
    4001: "退货商品已至门市交寄",
    4002: "退货商品已至物流中心",
    5001: "损坏，站所将协助退货",
    5002: "遗失",
    5003: "BASE列管(寄件人和收件人联络不到)",
    5004: "一般单退回",
    5005: "代收退货",
    5006: "代收毁损",
    5007: "代收遗失",
    5008: "退货配完",
    5009: "进货门市发生紧急闭店，提早退货至物流中心",
    7001: "超大(通常发生于司机取件，不取件)",
    7002: "超重(通常发生于司机取件，不取件)",
    7003: "地址错误，联系收件人",
    7004: "航班延误",
    7005: "托运单删除",
    7006: "小物流遗失",
    7007: "门市遗失",
    7008: "小物流破损，退回物流中心",
    7009: "商品包装不良（物流中心反应）",
    7010: "商品包装不良（门市反应）",
    7011: "取件门市闭店，转退回原寄件店",
    7012: "条码错误，物流中心客服处理",
    7013: "订单超过验收期限（商家未出货）",
    7014: "商家未到货（若订单成立隔日未到货即会发送，直到订单失效删除）",
    7015: "条码重复，物流中心客服处理",
    7016: "超才",
    7017: "取件包裹异常协寻中",
    7018: "取件遗失进行赔偿程序",
    7019: "寄件货态异常协寻中",
    7020: "寄件遗失进行赔偿程序",
    7021: "商品捆包",
    7022: "商品外袋透明",
    7023: "多标签",
    7024: "另约时间",
    7025: "电联不上",
    7026: "资料有误",
    7027: "无件可退",
    7028: "超大超重",
    7029: "已回收",
    7030: "别家收走",
    7031: "商品未到",
    7032: "寄件门市闭店",
    7101: "包裹配送验收异常-门市关转",
    7102: "包裹配送验收异常-无进货资料",
    7103: "包裹配送验收异常-条码错误",
    7104: "包裹配送验收异常-超材",
    7105: "包裹配送验收异常-大物流包装不良(渗漏)",
    7106: "包裹配送验收异常-小物流破损",
    7107: "包裹配送验收异常-门市反应商品包装不良(渗漏)",
    7201: "退货包裹配送验收异常-门市关转",
    7202: "退货包裹配送验收异常-无进货资料",
    7203: "退货包裹配送验收异常-条码错误",
    7204: "退货包裹配送验收异常-超材",
    7205: "退货包裹配送验收异常-大物流包装不良(渗漏)",
    7206: "退货包裹配送验收异常-小物流破损",
    7207: "退货包裹配送验收异常-门市反应商品包装不良(渗漏)",
    7255: "退货包裹重新送往取件门市",
    9001: "退货已取",
    9002: "退货已取",
    9999: "订单取消"
  },
  error_code: {
    "1001007": "帐号或密码错误",
    "1001010": "您已经登出",
    "1001013": "E-Mail已被注册",
    "1001018": "E-Mail不得为空",
    "1001020": "验证码错误",
    "1001022": "E-Mail不得为空",
    "1001025": "验证码错误",
    "1001029": "E-Mail已被注册",
    "1001037": "输入资料有误",
    "1001038": "连结已过期",
    "1001039": "连结已过期",
    "1001041": "原密码输入错误",
    "1001042": "帳號不存在",
    "1002001": "您已经登出",
    "1002002": "您已经登出",
    "1003001": "您已经登出",
    "1013004": "点数输入错误",
    "1013005": "点数余额不足",
    "1013006": "收件者资料错误",
    "1013007": "收件者资料错误",
    "1013017": "优惠码使用时间尚未开始",
    "1013018": "优惠码已过期",
    "1013019": "优惠码仅供第一次购物使用",
    "1013021": "优惠码超过使用次数",
    "1013023": "优惠码不存在",
    "1013026": "部分商品库存量不足, 请重新下单",
    "1021001": "请勿重复填写表单"
  }
}