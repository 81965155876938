const LoginApiHelper =
  require("../Utility/LoginApiHelper").default;
export default {
  mounted() {
    if (!this.fromMember && this.token) {
      $loading(true);
      LoginApiHelper.checkToken(this.token)
        .then(() => {
          $loading(false);
        })
        .catch((e) => {
          $loading(false);
          $alert(e.message, () => {
            this.$router.push({ name: "Login" });
          });
        });
    }
  },
  data() {
    const token = this.$route.params.token || "";
    const fromMember = this.$store.state.loginInfo.isLogin;
    if (token) {
      $loading(true);
    }
    if (!fromMember && !token) {
      this.$router.go(-1);
    }
    return {
      token,
      fromMember,
      params: {
        oldPassword: "",
        newPassword: "",
      },
      ruleValidate: {
        oldPassword: [
          {
            required: !fromMember,
            message: this.$t("reset_pwd_origin_password_please"),
            trigger: "blur",
          },
          {
            type: "string",
            min: 6,
            message: this.$t("signup_password_not_less_six"),
            trigger: "blur",
          },
        ],
        newPassword: [
          {
            required: true,
            message: this.$t("reset_pwd_new_password_please"),
            trigger: "blur",
          },
          {
            type: "string",
            min: 6,
            message: this.$t("signup_password_not_less_six"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    submit() {
      $loading(true);
      const params = {
        new_pwd: this.params.newPassword,
      };
      if (this.fromMember) {
        params["old_pwd"] = this.params.oldPassword;
        params["mode"] = "reset";
      } else {
        params["token"] = this.token;
        params["mode"] = "forgot";
      }
      LoginApiHelper.resetPassword(params)
        .then(() => {
          $loading(false);
          $alert(this.$t("common_modify_success"), () => {
            if (this.fromMember) {
              this.$router.go(-1);
            } else {
              this.$router.push({ name: "Login" });
            }
          });
        })
        .catch((error) => {
          $loading(false);
          $alert(error.message);
        });
    },
  },
};