<template>
  <div>
    <swiper
      :options="swiperOptionBanner"
      class="mb-3"
      style="overflow: clip visible"
    >
      <swiper-slide v-for="(banner, index) in banners" :key="index">
        <img :src="banner.src" @click="banner.click()" />
      </swiper-slide>
      <div
        v-show="banners.length > 1"
        class="swiper-pagination px-0"
        slot="pagination"
        style="left: 0; bottom: -5vmin"
      ></div>
    </swiper>
    <div class="container" style="margin-top: 5vmin">
      <div
        v-if="$store.state.searchStr && productFilterList.length"
        class="row"
      >
        <div
          class="col col-6 col-sm-6 col-md-4 col-lg-2 mb-3"
          v-for="product in productFilterList"
          :key="product.id"
        >
          <ProductItemMobile :product="product"></ProductItemMobile>
        </div>
      </div>
      <div v-else-if="$store.state.searchStr && !productFilterList.length">
        {{ $t("home_product_not_found") }}
      </div>
      <div v-else v-for="category in allCategory" :key="category.id">
        <div
          v-if="category.products.length > 0"
          :id="`category${category.id}`"
          class="title colorTitle my-3 mx-1"
        >
          <img v-if="category.header_img" :src="category.header_img" />
          <div v-else>{{ category.name }}</div>
        </div>
        <div
          class="row x-md-center px-3 pb-5"
          style="overflow-x: auto; flex-wrap: nowrap; position: relative"
        >
          <swiper
            class="swiper"
            :options="swiperOption"
            style="overflow: visible; overflow-x: clip"
          >
            <swiper-slide v-for="product in category.products" :key="product.id"
              ><ProductItemMobile :product="product"></ProductItemMobile>
            </swiper-slide>
            <div
              v-show="category.products.length > 1"
              class="swiper-pagination px-0"
              slot="pagination"
              style="left: 0; bottom: -5vmin"
            ></div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default require("../../../script/pages/Home").default;
</script>