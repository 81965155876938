<template>
  <div
    style="
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 20vmin 10vmin;
    "
  >
    <div class="content">請輸入註冊之電子信箱, 將寄送密碼重設連結</div>
    <Input
      field="email"
      v-model="params.email"
      placeholder="請輸入email"
      name="email"
      :maxlength="50"
      style="padding: 20px"
    />
    <div
      class="roundButton2 py-1"
      @click="sendToken()"
      :disabled="!params.email || timeLeft > 0"
      style="width: 32vmin; margin: 0 0 0 auto; margin-bottom: 5vmin"
    >
      {{
        timeLeft ? $t("forgot_pwd_resend", [timeLeft]) : $t("forgot_pwd_send")
      }}
    </div>
  </div>
</template>

<script>
export default require("../../../script/pages/ForgotPassword").default;
</script>