export default {
  mounted() {},
  data() {
    const results = this.$route.params.result.split(",");
    const idMap = {
      A1: "471091826148",
      A2: "471091826149",
      A3: "471091826150",
      B1: "471091826154",
      B2: "471091826155",
      B3: "471091856156",
    };
    const request = "s=" + results.join(",");
    const shareUrl = location.href + "/SkinTest/share.php?" + request;
    return {
      results,
      resultAID: idMap[results[0]],
      resultBID: idMap[results[1]],
      shareUrl,
      shareText: "你的朋友做了膚質檢測,一起來測驗你的膚質吧",
    };
  },
  methods: {
    onClickSelection(id) {
      this.$router.push({ name: "Product", params: { id } });
    },
    share(source) {
      switch (source) {
        case "fb":
          window.open(
            `https://www.facebook.com/sharer.php?u=${this.shareUrl}&quote=${this.shareText}`,
            "FB-share",
            "height=500, width=500"
          );
          break;
        case "line":
          window.open(
            `https://lineit.line.me/share/ui?url=${this.shareUrl}&text=${this.shareText}`,
            "Line-share",
            "height=500, width=500"
          );
          break;
      }
    },
  },
};