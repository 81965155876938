<template>
  <div style="touch-action: manipulation; height: 100%">
    <div
      v-if="showMenu"
      style="
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
      "
      @click="showMenu = !showMenu"
    ></div>
    <transition name="slide" mode="out-in" class="mx-0">
      <div
        v-show="showMenu"
        class="bgTitle shopheader"
        style="
          top: 58px;
          display: flex;
          flex-flow: column;
          position: absolute;
          background: white;
          height: calc(100% - 58px);
          z-index: 2;
          font-family: TopMenu;
        "
      >
        <Menu
          class="bgTitle"
          theme="light"
          style="overflow: auto; height: 0; flex-grow: 1"
        >
          <MenuItem
            name="brandStory"
            @click.native="
              showMenu = false;
              $router.push({ name: 'BrandStory' });
            "
            ><div style="font-size: medium">品牌故事</div></MenuItem
          >
          <Submenu
            v-for="(category, index) in this.$store.state.categories"
            :key="index"
            :name="`category${category.id}`"
          >
            <template slot="title">
              <div class="pl-3" style="color: black; font-size: medium">
                {{ category.name }}
              </div>
            </template>
            <MenuItem
              v-for="product in category.products.filter(
                (product) => product.show_in_menu
              )"
              :key="product.id"
              :name="`product${product.id}`"
              @click.native="goProduct(product.id)"
              ><div style="font-size: medium">
                {{ product.name }}
              </div></MenuItem
            >
          </Submenu>
        </Menu>

        <!-- <div class="m-auto pb-2 colorWhite content">
          <u class="button px-2" :selected="lang == 'tw'" @click="lang = 'tw'"
            >繁</u
          >
          <u class="button px-2" :selected="lang == 'cn'" @click="lang = 'cn'"
            >簡</u
          >
          <u class="button px-2" :selected="lang == 'en'" @click="lang = 'en'"
            >EN</u
          >
        </div> -->
        <!-- <div
          class="roundButton mt-2 mx-2"
          style="height: auto"
          @click="logout()"
        >
          登出
        </div> -->
      </div>
    </transition>
    <div
      v-if="!isBlank"
      class="mx-0"
      style="display: flex; flex-flow: column; height: 100%"
    >
      <div v-if="showHeader" style="width: 100%; z-index: 1; position: fixed">
        <ShopHeaderPC
          v-if="!isMobile"
          id="header"
          :isTop="isTop && isHome"
          style="pointer-events: none"
        ></ShopHeaderPC>
        <ShopHeaderMobile
          v-else
          id="header"
          @menuClick="showMenu = !showMenu"
        ></ShopHeaderMobile>
      </div>

      <!-- <div
        id="content"
        class="text-center mx-0"
        :style="`position: fixed;
          bottom: 0px;
          overflow-y: scroll;
          width: 100%;
          z-index:0;
          webkit-overflow-scrolling: touch;
          top:${contentTop};`"
      > -->
      <div
        id="content"
        class="text-center mx-0"
        :style="`
          overflow-y: scroll;
          width: 100%;
          height:calc(100% + 1px);
          z-index:0;
          webkit-overflow-scrolling: touch;
          position: static;
          padding-top:${contentTop};`"
      >
        <BackTop target="#content" />
        <router-view
          class="pb-4 mx-0"
          :style="`min-height: calc(100% - ${footerHeight}px);`"
        />
        <div v-if="showFooter">
          <ShopFooterPC v-if="!this.isMobile" id="footer" />
          <ShopFooterMobile v-else id="footer" />
        </div>
      </div>
    </div>
    <div v-else>
      <router-view />
    </div>
    <Modal v-model="dialog.show" :closable="false" :mask-closable="false">
      <pre
        style="text-align: left; white-space: pre-wrap"
        v-html="dialog.content"
      ></pre>
      <div slot="footer">
        <Button v-show="dialog.type == 'confirm'" @click="dialogNo">{{
          dialog.noText
        }}</Button>
        <Button @click="dialogYes">{{ dialog.yesText }}</Button>
      </div>
    </Modal>
    <Spin size="large" fix v-if="showLoading" style="position: fixed"></Spin>
  </div>
</template>
<script>
const Login = require("../script/Login/Login.ts").default;
import BackTop from "./component/BackTop.vue";
import { WOW } from "wowjs";
export default {
  components: {
    BackTop,
  },
  beforeCreate() {
    const promoCode = this.$router.currentRoute.query.promoCode;
    if (promoCode) {
      setCookie("promoCode", promoCode, 24);
    }
    const event = promoCode || getQueryUrl("event");
    if (event) {
      firebase.analytics().logEvent(event);
      setCookie("event", event, 24 * 30);
    }

    if (
      typeof document != "undefined" &&
      document.referrer &&
      !document.referrer.includes("royishop") &&
      !getCookie("today_first_time")
    ) {
      firebase.analytics().logEvent(document.referrer);
      setCookie("today_first_time", true, 24);
      setCookie("referrer", document.referrer, 24 * 30);
    }
  },
  mounted() {
    $("body").keydown(
      function (event) {
        if (event.which == 13) {
          if (this.dialog.show) {
            this.dialogYes();
          } else {
            this.$root.$emit("enter");
          }
        }
      }.bind(this)
    );
    content.addEventListener(
      "scroll",
      function () {
        this.isTop = content.scrollTop == 0;
      }.bind(this)
    );
    this.isTop = content.scrollTop == 0;
    const currentRoute = this.$router.currentRoute;
    // const description = document.getElementsByName("description")[0];
    // if (description) description.content = currentRoute.name;
    window.onresize();
    bindProcess(process);
    fbq("track", "PageView");
    firebase.analytics().logEvent("page_view", {
      page_location: currentRoute.path,
    });
    // Initialize Performance Monitoring and get a reference to the service
    if (isWebview() && this.$router.currentRoute.meta.group == "shop") {
      $alert(this.$t("common_use_outside_browser"));
    }

    if (getQueryUrl("from") == "SkinTest") {
      let skinTest = getCookie("skinTest");
      if (skinTest) {
        skinTest = JSON.parse(decodeURIComponent(skinTest));
        skinTest.recommands.forEach((id) => {
          if (!this.$store.state.cart[id]) {
            this.$store.commit("cartModify", { id: id, count: 1 });
          }
        });
      }
    }

    const push = this.$router.push.bind(this.$router);
    this.$router.push = (data) => {
      if (!compare(data, this.$router.currentRoute)) {
        if (!data.params) {
          data.params = {};
        }
        data.params.isMobile = this.isMobile;
        if (this.isMobile) {
          data.name = "mobile" + data.name;
        }
        push(data);
      }
    };
    this.$store.commit("fetchConfig", "config");
  },
  computed: {
    contentTop() {
      return this.isHome && !this.isMobile ? "0px" : "58px";
    },
    isHome() {
      return this.$route.name == "Home";
    },
  },
  data: function () {
    window.$loading = (show = true) => {
      this.showLoading = show;
    };
    window.$alert = (content, yesCallback) => {
      this.setDailog(content, "alert", yesCallback, null);
    };
    window.$confirm = (content, yesCallback, noCallback) => {
      this.setDailog(content, "confirm", yesCallback, noCallback);
    };
    window.$scrollToTop = this.scrollToTop;
    const currentRoute = this.$router.currentRoute;
    const isBlank = currentRoute.meta.group == "blank";
    const nofooter = currentRoute.meta.nofooter || isBlank;
    const noheader = currentRoute.meta.noheader || isBlank;
    if (!this.$router.currentRoute.params) {
      this.$router.currentRoute.params = {};
    }
    window.onresize = function () {
      this.isMobile = window.innerWidth < 768;
      this.$router.currentRoute.params.isMobile = this.isMobile;
      this.footerHeight =
        typeof footer != "undefined" ? footer.offsetHeight : 0;
      this.headerResize();
      this.footerResize();
    }.bind(this);
    window.onresize();
    const redirectRoute = copy(currentRoute);
    if (this.isMobile && redirectRoute.name.indexOf("mobile") < 0) {
      redirectRoute.name = "mobile" + redirectRoute.name;
      this.$router.push(redirectRoute);
    } else if (!this.isMobile && redirectRoute.name.indexOf("mobile") == 0) {
      redirectRoute.name = redirectRoute.name.replace("mobile", "");
      this.$router.push(redirectRoute);
    }
    return {
      showMenu: false,
      showLoading: false,
      isTop: true,
      isMobile: false,
      needUpdate: false,
      showHeader: !noheader,
      showFooter: !nofooter,
      headerHeight: 0,
      footerHeight: 0,
      isBlank,
      defaultDialog: {
        show: false,
        content: "",
        yesText: this.$t("common_yes"),
        noText: this.$t("common_no"),
        yesCallback: null,
        noCallback: null,
      },
      dialog: {
        type: "",
        show: false,
        content: "",
        yesText: "",
        noText: "",
        yesCallback: null,
        noCallback: null,
      },
    };
  },
  methods: {
    scrollToTop() {
      content.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    headerResize() {
      // if (this.isBlank) {
      //   return;
      // }
      // if (this.showHeader) {
      //   this.headerHeight = header.offsetHeight;
      //   this.minHeight = window.innerHeight - this.headerHeight;
      // } else {
      //   this.headerHeight = 0;
      //   this.minHeight = window.innerHeight;
      // }
    },
    footerResize() {
      // if (this.showFooter) {
      //   this.minHeight = this.minHeight - footer.offsetHeight;
      // }
    },
    setDailog(content, type, yesCallback, noCallback) {
      Object.assign(this.dialog, this.defaultDialog);
      this.dialog.type = type;
      this.dialog.show = true;
      if (typeof content == "string") {
        this.dialog.content = content;
        this.dialog.yesText = this.defaultDialog.yesText;
        this.dialog.noText = this.defaultDialog.noText;
        this.dialog.yesCallback = yesCallback;
        this.dialog.noCallback = noCallback;
      } else if (typeof content == "object") {
        Object.assign(this.dialog, content);
      }
    },
    dialogYes() {
      this.dialog.show = false;
      if (typeof this.dialog.yesCallback == "function") {
        setTimeout(this.dialog.yesCallback, 100);
      }
    },
    dialogNo() {
      this.dialog.show = false;
      if (typeof this.dialog.noCallback == "function") {
        setTimeout(this.dialog.noCallback, 100);
      }
    },
    goProduct(productID) {
      this.showMenu = false;
      this.$router.push({ name: "Product", params: { id: productID } });
    },
    logout() {
      $loading(true);
      Login.logout().then((result) => {
        $loading(false);
      });
    },
  },
  updated() {
    if (this.needUpdate) {
      this.needUpdate = false;
      window.onresize();
    }
    var wow = new WOW({
      boxClass: "wow", // 要套用WOW.js縮需要的動畫class(預設是wow)
      animateClass: "animated", // 要"動起來"的動畫(預設是animated, 因此如果你有其他動畫library要使用也可以在這裡調整)
      offset: 0, // 距離顯示多遠開始顯示動畫 (預設是0, 因此捲動到顯示時才出現動畫)
      mobile: true, // 手機上是否要套用動畫 (預設是true)
      live: false, // 非同步產生的內容是否也要套用 (預設是true, 非常適合搭配SPA)
      callback: function (box) {
        // 當每個要開始時, 呼叫這裡面的內容, 參數是要開始進行動畫特效的element DOM
      },
      scrollContainer: "#content", // 可以設定成只套用在某個container中捲動才呈現, 不設定就是整個視窗
    });
    wow.init();
  },
  watch: {
    $route(to, from) {
      this.isBlank = to.meta.group == "blank";
      const nofooter = to.meta.nofooter || this.isBlank;
      const noheader = to.meta.noheader || this.isBlank;
      if (this.showHeader != !noheader || this.showFooter != !nofooter) {
        this.needUpdate = true;
      }
      this.showHeader = !noheader;
      this.showFooter = !nofooter;

      fbq("track", "PageView");
      firebase.analytics().logEvent("page_view", {
        page_location: to.path,
      });
      this.scrollToTop();
    },
  },
};
</script>
<style>
img {
  width: 100%;
}
</style>