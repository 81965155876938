<template>
  <div>
    <img
      v-for="(brandStory, index) in brandStories"
      class="wow fadeIn"
      data-wow-delay="0s"
      data-wow-iteration="1"
      data-wow-offset="200"
      :src="brandStory.src"
      @click="brandStory.click"
      :key="index"
    />
  </div>
</template>
<script>
export default {
  computed: {
    brandStories() {
      const brand_stories = this.$store.state.config.brand_stories;
      if (brand_stories instanceof Array) {
        return brand_stories.map((brand_storie) => {
          return {
            src: brand_storie.image,
            click: () => {
              if (brand_storie.click_url) {
                if (brand_storie.new_window) {
                  window.open(brand_storie.click_url, "_blank");
                } else {
                  location.href = brand_storie.click_url;
                }
              }
            },
          };
        });
      }
      return [];
    },
  },
};
</script>