import { ApiMethods } from "../enum/ApiMethods";
import ApiHelper from "./ApiHelper";
const otherApi = require("../../../../storage/app/apiPath/other.json");


export default class OtherApiHelper {
    public static commit(data: any): Promise<Product> {
        return ApiHelper.callSelf(ApiMethods.POST, `${otherApi.skinTestEvent}`, data);
    }
    public static selectStore(shopInfo: any) {
        return ApiHelper.openOutsideByPath(ApiMethods.POST, otherApi.selectStore, shopInfo, "_self");
    }

}