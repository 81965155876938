import FormFieldEdit from "../../components/component/FormFieldEdit.vue";
const Login = require("../Login/Login.ts").default;
export default {
  components: { FormFieldEdit },
  mounted() {
    this.$root.$on("enter", this.submit);
  },
  beforeDestroy() {
    this.$root.$off("enter", this.submit);
  },
  beforeCreate() {},
  data() {
    const saveAccount = getLocalStorage("saveAccount") || false;
    const checkboxes = [saveAccount ? "saveAccount" : false];
    return {
      isReady: true,
      checkboxes: checkboxes.filter((item) => item),
      loginData: {
        email: saveAccount || "",
        password: "",
      },
      ruleValidate: {
        email: [
          {
            required: true,
            message: this.$t("login_email_please"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("login_password_please"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submit() {
      
      const name = 'loginData';
      this.$refs[name].validate((valid) => {
        if (valid) {
          $loading(true);
          Login.loginByAccount(
            this.loginData.email,
            this.loginData.password
          ).then((result) => {
            $loading(false);
            if (!result.isLogin) {
              $alert(result.message);
            }
          });
        } else {
          this.$Message.error(this.$t("common_check_fields"));
        }
      });
    },
    login: function (source) {
      $loading(true);
      Login.login(source).then((result) => {
        $loading(false);
        if (!result.isLogin && result.message) {
          $alert(result.message);
        }
      });
    },
  },
  watch: {
    "loginData.email": function (value) {
      setLocalStorage(
        "saveAccount",
        this.checkboxes.indexOf("saveAccount") >= 0 ? value : false
      );
    },
    checkboxes(value) {
      setLocalStorage(
        "saveAccount",
        value.indexOf("saveAccount") >= 0 ? this.loginData.email : false
      );
    },
  },
};