<template>
  <div class="text-dark colorBlack content" style="overflow: hidden">
    <div style="position: relative; height: 0; padding-bottom: 100%">
      <img
        style="
          object-fit: contain;
          position: absolute;
          height: 100%;
          left: 0;
          top: 0;
        "
        v-lazy="product.imgs[0] ? product.imgs[0] : '/img/lady.png'"
        :alt="product.name"
        @click="goProduct()"
      />
    </div>
    <div style="padding: 5px; height: 100%">
      <div class="singleLine" style="text-align: center; height: auto">
        {{ product.name }}
      </div>
      <div
        class="content centerOrigin colorGray singleLine"
        style="
          text-align: center;
          line-height: 20px;
          height: 40px;
          -webkit-line-clamp: 2;
        "
        v-html="content"
      ></div>
      <div class="mx-0" style="width: 100%">
        <div class="px-0 my-auto" style="text-align: center; font-size: large">
          <span v-if="originPrice" class="priceDelete"
            >NT${{ originPrice }}</span
          ><span class="colorBlack">NT${{ priceText }}</span>
        </div>
      </div>
      <div style="margin: auto; width: 80%">
        <img
          v-if="canBuy"
          class="button"
          @click="addToCart()"
          src="/img/cart.png"
        />
        <div v-else style="line-height: 45px">
          {{ $t("product_out_of_stock") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default require("../../../script/component/ProductItem").default;
</script>