<template>
  <div style="display: flex; justify-content: center">
    <div
      v-for="(label, index) in steps"
      :key="index"
      style="display: flex; align-items: center"
    >
      <div style="position: relative; display: flex; justify-content: center">
        <div
          class="step content"
          :checked="current == index"
          style="transform-origin: center"
        >
          {{ index + 1 }}
        </div>
        <div
          class="content"
          style="
            position: absolute;
            width: max-content;
            top: 6vmin;
            transform-origin: center;
          "
        >
          {{ label }}
        </div>
      </div>
      <hr v-if="index != steps.length - 1" style="width: 20vmin" />
    </div>
  </div>
</template>
<script>
export default {
  props: ["steps", "current"],
  methods: {},
};
</script>