<template>
  <div class="text-dark colorBlack content" style="overflow: hidden">
    <div
      style="position: relative; height: 0; padding-bottom: 100%"
      v-on:mouseover="showAddToCart = true"
      v-on:mouseleave="showAddToCart = false"
    >
      <img
        style="
          object-fit: contain;
          position: absolute;
          height: 100%;
          left: 0;
          top: 0;
        "
        v-lazy="product.imgs[0] ? product.imgs[0] : '/img/lady.png'"
        :alt="product.name"
        @click="goProduct()"
      />
      <div
        v-show="showAddToCart"
        class="w-100 h-100"
        style="
          background: #00000033;
          position: absolute;
          bottom: 0;
          pointer-events: none;
        "
      >
        <div
          class="button"
          style="
            background: #00000088;
            position: absolute;
            bottom: 0;
            height: 50px;
            width: 100%;
            display: flex;
            color: white;
            pointer-events: auto;
          "
          @click="addToCart()"
          :disabled="canBuy"
        >
          <div class="m-auto">{{ addToCartText }}</div>
        </div>
      </div>
    </div>
    <div style="padding: 5px; height: 100%">
      <div class="singleLine" style="text-align: center; height: auto">
        {{ product.name }}
      </div>
      <div
        class="description centerOrigin colorGray singleLine"
        style="
          text-align: center;
          line-height: 20px;
          height: 40px;
          -webkit-line-clamp: 2;
        "
        v-html="content"
      ></div>
      <div class="mx-0" style="width: 100%">
        <div class="px-0 my-auto" style="text-align: center; font-size: large">
          <div class="colorBlack" :style="originPrice ? 'color: #f39c9c' : ''">
            NT${{ priceText }}
          </div>
          <div
            v-if="originPrice"
            class="priceDelete"
            style="text-decoration-color: gray; margin-top: -9px"
          >
            NT${{ originPrice }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default require("../../../script/component/ProductItem").default;
</script>