<template>
  <div>
    <div v-if="points.length">
      <div class="row">
        <div class="col-4">{{ $t("points_source") }}</div>
        <div class="col-2">{{ $t("points_points") }}</div>
        <div class="col-6">{{ $t("points_time") }}</div>
      </div>
      <div class="row" v-for="pointItem in points" :key="pointItem.id">
        <div class="col-4">
          {{ getSource(pointItem.type) }}
        </div>
        <div class="col-2">{{ pointItem.points }}</div>
        <div class="col-6">{{ pointItem.timestamp }}</div>
      </div>
    </div>
    <div v-else>{{ $t("points_no_points") }}</div>
  </div>
</template>

<script>
export default require("../../../script/pages/Points").default;
</script>