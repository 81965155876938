<template>
  <div style="display: flex; align-items: center">
    <input
      :class="styleClass"
      type="radio"
      :name="group"
      :id="group + tag"
      :value="tag"
      @input="update($event.target.value)"
      :disabled="disabled"
      style="transform: scale(1)"
    />
    <label :class="styleClass" :for="group + tag">{{ text }}</label>
  </div>
</template>
<script>
export default {
  props: [
    "text",
    "tag",
    "model",
    "group",
    "value",
    "noImg",
    "disabled",
    "buttonClass",
  ],
  methods: {
    update(value) {
      this.$emit("input", value);
    },
  },
  computed: {
    styleClass() {
      return this.buttonClass
        ? this.buttonClass
        : !this.noImg
        ? "roundRadio"
        : "";
    },
  },
  mounted() {
    $(`#${this.group}${this.tag}`)[0].checked = this.value == this.tag;
  },
  watch: {
    value: function (v) {
      $(`#${this.group}${this.tag}`)[0].checked = v == this.tag;
      //   this.$emit("input", v);
    },
  },
};
</script>
