<template>
  <div class="row mx-0">
    <div
      class="px-0"
      :style="{
        'text-align': 'left',
        width: labelWidth,
      }"
    >
      {{ field }}
    </div>
    <div
      class="px-0"
      :style="{
        margin: 'auto',
        'text-align': contentAlign,
        width: `calc( 100% - ${labelWidth} )`,
        'white-space': 'pre-wrap',
      }"
    >
      <Input
        :value="value ? value : ''"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :name="name"
        :type="type"
        :password="password"
        @input="update($event)"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    contentAlign: {
      type: String,
      default: "right",
    },
    labelWidth: {
      type: String,
      default: "100px",
    },
    field: {
      type: String,
      default: "",
    },
    value: {
      type: String | Number,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    prop: {
      type: String,
      default: "",
    },
    maxlength: {
      type: Number,
      default: 100,
    },
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    password: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    update(value) {
      this.$emit("input", value);
    },
  },
};
</script>
