
<template>
  <div
    class="colorGreen"
    style="
      height: 160px;
      position: relative;
      background: #ededed;
      letter-spacing: 2px;
    "
  >
    <div
      class="content"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      "
    >
      <img
        class="mx-3 button"
        style="height: 70px; width: auto"
        src="/img/下方欄位-logo button.png"
        @click="goHome()"
      />
      <div class="mx-3">
        <div>04-22583089</div>
        <div style="display: flex; justify-content: center">
          <img
            src="/img/IG-icon button.png"
            style="width: auto; height: 40px; cursor: pointer"
            onclick="window.open('https://www.instagram.com/royi_tw/', '_blank')"
          />
          <img
            src="/img/FB-icon button.png"
            style="width: auto; height: 40px; cursor: pointer"
            onclick="window.open('https://www.facebook.com/Royi.tw', '_blank')"
          />
        </div>
      </div>
      <div class="bgGreen mx-3" style="height: 50%; width: 1px" />
      <div class="mx-3">
        <div>台中市西屯區市政北二路236號8樓A2</div>
        <div>Email:royi.service@louyiai.com</div>
        <div>服務時間:週一至週五 9:00-18:00</div>
      </div>
      <div class="bgGreen mx-3" style="height: 50%; width: 1px" />
      <div class="mx-3">
        <div>公司名稱：樓易健康科技股份有限公司</div>
        <div
          style="text-align:left"
        >
          統一編號：85049258
        </div>
        <div style="display: flex; justify-content: center">
          <div
            class="roundButton3"
            style="margin:0.2rem 0.5rem !important"
            @click="$router.push({ name: 'PrivacyPolicy' })"
          >
            隱私權政策
          </div>
          <div
            class="roundButton3"
            style="margin:0.2rem 0.5rem !important"
            @click="$router.push({ name: 'Services' })"
          >
            服務條款
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  beforeCreate() {},
  data() {
    return {};
  },
  watch: {},
  methods: {
    goHome() {
      const name = this.$route.name.replace("mobile");
      if (name != "Home") {
        this.$router.push({ name: "Home" });
      } else {
        $scrollToTop();
      }
    },
  },
};
</script>