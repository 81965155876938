export default {
    props: ["product"],
    computed: {
        canBuy() {
          return this.product.inventory == null || this.product.inventory > 0
        },
        content() {
        if ($(`<div>${this.product.content}</div>`)[0]) {
            return $(`<div>${this.product.content}</div>`)[0].innerText;
        }
        return this.product.content;
        },
        originPrice() {
        let price = this.product.origin_price;
        if (this.product.special_price) {
            price = this.product.origin_price || this.product.price;
        }
        return price;
        },

        priceText() {
        let price = this.product.price;
        if (this.product.special_price) {
            price = this.product.special_price;
        }
        return price;
    },
  },
  methods: {
    remove() {
      $confirm(this.$t("scitem_product_remove_confirm"), () => {
        this.$store.commit("remove", {
          id: this.product.id,
          selection: this.product.selection,
        });
      });
    },
  },
  watch: {
    "product.count": function (value) {
      if (value > 0) {
        if (this.product.inventory == null || this.product.inventory >= value) {
          this.$store.commit("cartModify", {
            id: this.product.id,
            count: value,
            selection: this.product.selection,
          });
        } else {
          $alert(
          this.$t("product_product_out_of_stock_message"),
            () => {
              this.product.count = this.product.inventory;
          }
        );
        }
      } else {
        $confirm(
          this.$t("scitem_product_remove_confirm"),
          () => {
            this.$store.commit("remove", {
              id: this.product.id,
              selection: this.product.selection,
            });
          },
          () => {
            this.product.count = 1;
          }
        );
      }
    },
  },
};