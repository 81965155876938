export default {
  props: ["product"],
  computed: {
    canBuy() {
      const productId = this.product.id + (this.product.selection_id ? `-${this.product.selection_id}` : "");
      return this.product.inventory == null || this.product.inventory >= this.$store.state.cart[productId]
    },
    content() {
      if ($(`<div>${this.product.content}</div>`)[0]) {
        return $(`<div>${this.product.content}</div>`)[0].innerText;
      }
      return this.product.content || "";
    },
    originPrice() {
      let price = this.product.origin_price;
      if (this.product.special_price) {
        price = this.product.origin_price || this.product.price;
      }
      return price;
    },

    priceText() {
      let price = this.product.price;
      if (this.product.special_price) {
        price = this.product.special_price;
      }
      return price;
    },
  },
};