var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"px-0",style:({
      'text-align': 'left',
      width: _vm.labelWidth,
    })},[_vm._v("\n    "+_vm._s(_vm.field)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"px-0",style:({
      margin: 'auto',
      width: ("calc( 100% - " + _vm.labelWidth + " )"),
      'white-space': 'pre-wrap',
      display: 'flex',
      'justify-content': 'flex-end',
    })},[_c('select',{style:(_vm.red ? 'border: 1px solid red;' : ''),on:{"change":function($event){return _vm.update($event.target.value)}}},_vm._l((_vm.selections),function(option){return _c('option',{key:option.value,domProps:{"value":option.value,"selected":option.value == _vm.value}},[_vm._v("\n        "+_vm._s(option.text)+"\n      ")])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }