<template>
  <div
    class="row mx-0 text-dark x-md-center content"
    :class="!canBuy ? 'bgLight' : ''"
  >
    <div style="display: flex; height: 200px; width: auto">
      <img
        v-lazy="product.imgs[0] ? product.imgs[0] : '/img/lady.png'"
        style="margin: auto; height: 100%; width: auto"
      />
    </div>
    <div class="col-8 align-self-center">
      <div style="text-align: left">
        {{ product.name }}
      </div>
      <div class="mt-1 description colorGray" style="text-align: left">
        {{ content }}
      </div>
      <div class="row pt-3 mx-0">
        <div
          class="subTitle colorDarkRed col-4 px-0"
          style="
            text-align: left;
            margin: auto;
            padding-left: 1vmin;
            font-weight: normal;
          "
        >
          <span v-if="originPrice" class="priceDelete"
            >NT${{ originPrice }}</span
          >
          NT${{ priceText }}
        </div>

        <field-value
          v-if="canBuy"
          class="my-2 content colorTitle col-8"
          field=""
          :value="`x${product.count}`"
          labelWidth="15vmin"
        />
        <field-value
          v-else
          class="my-2 content colorTitle col-8"
          field=""
          :value="$t('product_product_out_of_stock_message')"
          labelWidth="15vmin"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default require("../../../script/component/CheckoutItem").default;
</script>