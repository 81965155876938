
import { LoginType } from "../enum/LoginType";
import FBLogin from "./FBLogin";
import LoginInterface from "./LoginInterface";
import GoogleLogin from './GoogleLogin';
import LineLogin from './LineLogin';
import RoyishopLogin from './RoyishopLogin';
import "../Common";
import ApiHelper from "../Utility/ApiHelper";
import { ApiMethods } from "../enum/ApiMethods";
const loginApi = require("../../../../storage/app/apiPath/login.json");
export default class Login {
  static data: StatusResponse = {
    loginType: getCookie("LoginType") as LoginType,
    isLogin: false,
    message: '',
    detail: {
      id: "",
      birth: "",
      display_name: "",
      photo: "",
      email: "",
      email_valid: 0,
      gender: -1,
      memo: "",
      phone: "",
      points: 0,
      regist_time: ""
    }
  }
  static inited = false;
  static fbLogin = new FBLogin();
  static googleLogin = new GoogleLogin();
  static lineLogin = new LineLogin();
  static royishopLogin = new RoyishopLogin();
  static statusChangeListener = (response: StatusResponse) => { }
  public static init() {
    let loginMethod: LoginInterface | null = this.getCurrentMethod(this.data.loginType as LoginType);
    loginMethod?.registCallback(this.onReady.bind(this));
  }
  private static onReady() {
    this.check('login');
  }
  private static getCurrentMethod(loginType: LoginType | string) {
    let loginMethod: LoginInterface | null = null;
    switch (loginType) {
      case LoginType.Facebook:
        loginMethod = this.fbLogin;
        break;
      case LoginType.Google:
        loginMethod = this.googleLogin;
        break;
      case LoginType.Line:
        loginMethod = this.lineLogin;
        break;
      case LoginType.Royishop:
        loginMethod = this.royishopLogin;
        break;
      default:
        break;
    }
    return loginMethod;
  }
  public static login(loginType: LoginType): Promise<StatusResponse> {
    return new Promise((resolve, reject) => {
      let loginMethod: LoginInterface | null = this.getCurrentMethod(loginType);
      loginMethod?.login((response) => {
        this.onStatusChange('login', response, (data: any) => {
          resolve(data);
        })
      });
    });
  }
  public static loginByAccount(account: string, password: string): Promise<StatusResponse> {
    return new Promise((resolve, reject) => {
      this.royishopLogin.loginByAccount(account, password, (response) => {
        this.onStatusChange('login', response, (data: any) => {
          resolve(data);
        })
      });
    });
  }
  public static logout(): Promise<StatusResponse> {
    return new Promise((resolve, reject) => {
      let loginMethod: LoginInterface | null = this.getCurrentMethod(this.data.loginType);
      loginMethod?.logout((response) => {
        this.onStatusChange('logout', response, (data: any) => {
          resolve(data);
        });
      });
    });
  }
  public static check(method: string = 'check'): Promise<StatusResponse> {
    return new Promise((resolve, reject) => {
      let loginMethod: LoginInterface | null = this.getCurrentMethod(this.data.loginType);
      loginMethod?.checkLoginStatus((response) => {
        this.onStatusChange(method, response, (data: any) => {
          resolve(data);
        });
      });
    });
  }
  public static setOnLoginStatusChangeListener(listener: (response: StatusResponse) => void) {
    this.statusChangeListener = listener;
  }
  static onStatusChange(method: string, response: StatusResponse, callback: Function | null = null) {
    if (response.isLogin) {
      if (response.loginType == LoginType.Royishop) {
        if (method == 'login') {
          this.data.isLogin = true;
          this.data.loginType = LoginType.Royishop;
          Object.assign(this.data.detail, response.detail);
        }
        if (callback) callback(this.data);
        this.statusChangeListener(this.data);
      } else {
        ApiHelper.callSelf(ApiMethods.POST, `${loginApi.normal.thirdLogin}/${response.detail.id}`, {
          source: response.loginType,
          email: response.detail.email,
          birth: response.detail.birth,
          display_name: response.detail.display_name
        }).then((loginInfo) => {
          if (method == 'login') {
            this.data.isLogin = true;
            this.data.loginType = response.loginType;
            Object.assign(this.data.detail, loginInfo);
            this.data.detail.email = this.data.detail.email || response.detail.email;
            this.data.detail.birth = this.data.detail.birth || response.detail.birth;
            this.data.detail.display_name = this.data.detail.display_name || response.detail.display_name;
          }
          if (callback) callback(this.data);
          this.statusChangeListener(this.data);
        });
      }
    } else {
      this.data.isLogin = false;
      this.data.loginType = LoginType.None;
      this.data.message = response.message;
      Object.assign(this.data.detail, response.detail);
      if (callback) callback(this.data);
      this.statusChangeListener(this.data);
    }
  }
}