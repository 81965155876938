import Login from "../Login/Login";
import { LoginType } from "../enum/LoginType";
import LoginApiHelper from "../Utility/LoginApiHelper";
import ImageText from "../../components/component/ImageText.vue";
import RadioButton from "../../components/component/RadioButton.vue";
export default {
  components: { RadioButton, ImageText },
  mounted() {
    this.$root.$on("enter", this.submit);
  },
  beforeDestroy() {
    this.$root.$off("enter", this.submit);
  },
  data() {
    return {
      valid: false,
      checkedEmail: "",
      timeLeft: 0,
      waitTime: 60,
      signupData: {
        user_id: "",
        email:
          this.$store.state.loginInfo.detail.email ||
          this.$route.params.email ||
          "",
        password: "",
        repassword: "",
        mobile: this.$store.state.loginInfo.detail.mobile,
        birth: this.$store.state.loginInfo.detail.birth,
        gender: this.$store.state.loginInfo.detail.gender,
        token: "",
      },
      ruleValidate: {
        email: [
          {
            required: true,
            message: this.$t("signup_email_please"),
            trigger: "blur",
          },
          {
            type: "email",
            message: this.$t("signup_email_wrong_format"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
                  if (this.checkedEmail != value) {
                  $loading(true);
                LoginApiHelper.checkEmail(value)
                    .then((result) => {
                      $loading(false);
                    this.checkedEmail = value;
                    callback();
                  })
                    .catch((e) => {
                      $loading(false);
                    callback(e);
                  });
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        password: [
          {
            required: !this.fromThird,
            message: this.$t("signup_password_please"),
            trigger: "blur",
          },
          {
            type: "string",
            min: 6,
            message: this.$t("signup_password_not_less_six"),
            trigger: "blur",
          },
        ],
        repassword: [
          {
            required: !this.fromThird,
            message: this.$t("signup_password_confirn_please"),
            trigger: "blur",
          },
          {
            type: "string",
            min: 6,
            message: this.$t("signup_password_not_less_six"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value == this.signupData.password) {
                callback();
              } else {
                callback(this.$t("signup_password_not_match"));
              }
            },
            trigger: "blur",
          },
        ],
        token: [
          {
            required: true,
            message: this.$t("signup_email_valify_first"),
            trigger: "blur",
          },
        ],
        mobile: [
          {
            validator: (rule, value, callback) => {
              if (value) {
                const regex = new RegExp(/^09[0-9]{8}$/);
                if (regex.test(value)) {
                  callback();
                } else {
                  callback(this.$t("signup_mobile_wrong_format"));
                }
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        gender: [
          {
            required: true,
            message: this.$t("signup_gender_please"),
            trigger: "change",
          },
        ],
        birth: [
          {
            required: true,
            type: "date",
            message: this.$t("signup_birthday_please"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    fromThird() {
      return (
        this.$store.state.loginInfo.isLogin &&
        this.$store.state.loginInfo.loginType != LoginType.Royishop
      );
    },
  },
  watch: {
    "$store.state.loginInfo": {
      handler: function (value) {
        this.signupData.email = value.detail.email;
        this.signupData.mobile = value.detail.mobile;
        this.signupData.birth = value.detail.birth;
        this.signupData.gender = value.detail.gender;
      },
      deep: true,
    },
  },
  methods: {
    submit() {
      const name = "signupData";
      this.$refs[name].validate((valid) => {
        if (valid) {
          const sendData = Object.assign({}, this.signupData);
          sendData.user_id = Login.data.detail.id;
          $loading(true);
          LoginApiHelper.signupAccount(
            Login.data.loginType || LoginType.Royishop,
            sendData
          )
            .then((result) => {
              if (result.id) {
                setCookie("clickedPromoCode", false);
                Login.check("login").then((result) => {
                  if (result.isLogin) {
                    this.$router.push({ name: "Home" });
                  } else {
                    $loading(false);
                    $alert(result.message);
                  }
                });
              } else {
                $loading(false);
                $alert(result.message);
              }
            })
            .catch((e) => {
              $loading(false);
              $alert(e.message);
            });
        } else {
          this.$Message.error(this.$t("common_check_fields"));
        }
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    sendToken() {
      if (!this.signupData.email) {
        $alert(this.$t("signup_email_please"));
        return;
      }
      if (this.timeLeft == 0) {
        LoginApiHelper.sendVerifyEmail(this.signupData.email);
        const startTime = new Date();
        const intervel = setInterval(() => {
          const timeLeft = Math.floor(
            this.waitTime - (new Date() - startTime) / 1000
          );
          if (timeLeft > 0) {
            this.timeLeft = timeLeft;
          } else {
            this.timeLeft = 0;
            clearInterval(intervel);
          }
        }, 300);
      }
    },
    cancel() {
      if (Login.data.isLogin) {
        $loading(true);
        Login.logout().then(() => {
          $loading(false);
          this.$router.push({ name: "Login" });
        });
      } else {
        this.$router.push({ name: "Login" });
      }
    },
  },
};