import { ApiMethods } from "../enum/ApiMethods";
import ApiHelper from "./ApiHelper";
import { LoginType } from "../enum/LoginType";
import Login from "../Login/Login";
const loginApi = require("../../../../storage/app/apiPath/login.json");



export default class LoginApiHelper {
    public static lineLogout(): Promise<any> {
        return ApiHelper.callSelf(ApiMethods.GET, loginApi.line.logout);
    }
    public static lineGetUserInfo(): Promise<any> {
        return ApiHelper.callSelf(ApiMethods.GET, loginApi.line.getUserProfile);
    }
    public static googleLogout(): Promise<any> {
        return ApiHelper.callSelf(ApiMethods.GET, loginApi.google.logout);
    }
    public static googleGetUserInfo(): Promise<any> {
        return ApiHelper.callSelf(ApiMethods.GET, loginApi.google.getUserProfile);
    }
    public static openOutside(url: string | string, data: any | null) {
        return ApiHelper.openOutside(ApiMethods.GET, url, data, "_blank");
    }

    public static login(account: string, password: string) {
        return ApiHelper.callSelf(ApiMethods.POST, loginApi.normal.login, { account, password: md5(password) }).then((result) => {
            if (result.token) {
                setCookie('LoginType', LoginType.Royishop);
                setCookie('royishopToken', result.token);
            }
            return result;
        });
    }

    public static getLoginInfo(token: string) {
        return ApiHelper.callSelf(ApiMethods.POST, loginApi.normal.getLoginInfo, { token });
    }

    public static checkEmail(email: string) {
        return ApiHelper.callSelf(ApiMethods.POST, loginApi.normal.checkEmail, { email });
    }
    public static sendVerifyEmail(email: string) {
        return ApiHelper.callSelf(ApiMethods.POST, loginApi.normal.sendVerifyEmail, { email });
    }
    public static checkToken(token: string) {
        return ApiHelper.callSelf(ApiMethods.POST, loginApi.normal.checkToken, { token });
    }
    public static resetPassword(params: { old_pwd?: string, new_pwd: string }) {
        params['new_pwd'] = md5(params['new_pwd']);
        if (params['old_pwd']) {
            params['old_pwd'] = md5(params['old_pwd']);
        }
        return ApiHelper.callSelf(ApiMethods.POST, loginApi.normal.resetPassword, params);
    }
    public static forgetPassword(email: string) {
        return ApiHelper.callSelf(ApiMethods.POST, loginApi.normal.resetPasswordRequest, { email });
    }
    public static signupAccount(loginType: LoginType, data: any) {
        data.birth = data.birth.format();
        data.source = loginType;
        delete data.repassword;
        if (loginType == LoginType.Royishop) {
            data.password = md5(data.password);
            return ApiHelper.callSelf(ApiMethods.POST, loginApi.normal.regist, data).then((result) => {
                if (result.token) {
                    Login.data.loginType = LoginType.Royishop;
                    setCookie('LoginType', LoginType.Royishop);
                    setCookie('royishopToken', result.token);
                }
                return result;
            });
        } else {
            delete data.password;
            return ApiHelper.callSelf(ApiMethods.POST, loginApi.normal.thirdRegist, data).then((result) => {
                return result;
            });
        }

    }
}