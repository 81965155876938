<template>
  <div>
    <div class="title colorTitle">{{ text }}</div>
    <Divider class="divider" size="small" />
  </div>
</template>
<script>
export default {
  props: ["text"],
  methods: {},
};
</script>
