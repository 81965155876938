var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4",style:({
      'text-align': 'left',
      width: _vm.labelWidth ? ("" + _vm.labelWidth) : '',
      height: 'fit-content',
      margin: 'auto',
    })},[_vm._v("\n    "+_vm._s(_vm.field)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"col-8 px-0",style:({
      width: _vm.labelWidth ? ("calc( 100% - " + _vm.labelWidth + " )") : '',
    })},[_c('div',{staticClass:"roundButton2 col-7 p-1",staticStyle:{"width":"100%"},on:{"click":function($event){return _vm.$emit('buttonClick')}}},[_vm._v("\n      "+_vm._s(_vm.text)+"\n    ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }