const md5 = require('md5');
const common = {
    setCookie(cname, cvalue, aliveHour = 3*24) {
        switch (typeof cvalue) {
            case "object":
                cvalue = JSON.stringify(cvalue);
                break;
        }
        
        var d = new Date();
        d.setTime(d.getTime() + aliveHour * 60 * 60 * 1000);
        var expires = "expires=" + d.toUTCString();
        document.cookie = `${cname}=${cvalue};${expires};path=/;SameSite=None; Secure`;
    },

    getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                let target = c.substring(name.length, c.length);
                try {
                    target = JSON.parse(target);
                } catch (error) {
                }
                return target;
            }
        }
        
        return "";
    },
    setLocalStorage(key, item) {
         switch (typeof item) {
            case "object":
                item = JSON.stringify(item);
                break;
        }
        localStorage.setItem(key, item);
    },
    getLocalStorage(key) {
        const value = localStorage.getItem(key);
        try {
            return JSON.parse(value);
         } catch (e) {
            return value;
        }
    },
    bindProcess(process) {
        this.process = process;
    },
    log(text) {
        if (process.env.MIX_APP_DEBUG == "true") {
            console.debug(text);
        }
    },
    getQueryUrl(key) {
        const params = {};
        location.search.split(/[?&]/).filter((item) => item).forEach((item) => {
            const splitItem = item.split('=');
            params[splitItem[0]] = splitItem[1];
        });
        return params[key] ? decodeURIComponent(params[key]) : null;
    },
    isWebview() {
        var useragent = navigator.userAgent;
        var rules = ['WebView','(iPhone|iPod|iPad)(?!.*Safari\/)','Android.*(wv|\.0\.0\.0)'];
        var regex = new RegExp(`(${rules.join('|')})`, 'ig');
        return Boolean(useragent.match(regex));
    },
    compare(obj1, obj2) {
        let isSame = true;
        if (typeof obj1 == 'object' && obj1 !== null) {
            const keys = Object.keys(obj1);
            const objectKeys = Object.keys(obj2);
            keys.find((key) => {
                if (objectKeys.indexOf(key) >= 0) {
                    const type = typeof obj1[key];
                    if (type == 'object') {
                        isSame = compare(obj1[key], obj2[key]);
                    } else {
                        isSame = obj1[key] == obj2[key];
                    }
                } else {
                    isSame = false;
                }
                return !isSame;
            });
        } else {
            isSame = obj1 == obj2
        }
        
        return isSame;
    },
    copy(object) {
        if (object !== null && object !== undefined) {
            if (object instanceof Array) {
                const newArray = [];
                object.forEach((item) => {
                    newArray.push(copy(item));
                })
                return newArray;
            } else if (object instanceof Date) {
                return new Date(object.getTime());
            } else if (typeof object == "object") {
                const newObj = {};
                Object.keys(object).forEach((key) => {
                    newObj[key] = copy(object[key]);
                });
                return newObj;
            }
        }
        return object;
        
    },
    md5(s) {
        return md5(`royi${s}shop`);
    }
    
}
Object.assign(window, common);

Date.prototype.format = function () { //author: meizz
    var d = this,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}