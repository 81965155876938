<template>
  <div>
    <Button onclick="location.href='/table/user'">會員</Button>
    <Button onclick="location.href='/table/order'">訂單紀錄</Button>
    <Button onclick="location.href='/table/skin_test_event'"
      >膚質檢測表單</Button
    >
    <Button @click="downloadExcel()" type="primary">下載Excel</Button>
    總筆數:{{ tableList.length }}
    <Table :height="height" border :columns="columns" :data="tableList"></Table>
  </div>
</template>
<script>
var xlsx = require("node-xlsx");
import download from "downloadjs";
export default {
  props: ["table"],
  data() {
    let data = document
      .querySelector("meta[name=data]")
      .getAttribute("content");
    data = JSON.parse(data);
    const columns = data.columns
      // .filter((columnName) => columnName != "id")
      .map((columnName) => {
        const conlumn = {
          title: columnName,
          key: columnName,
          width: 150,
          sortable: true,
        };
        return conlumn;
      });
    return {
      tableList: data.data,
      columns,
      height: window.innerHeight - 32,
    };
  },
  methods: {
    downloadExcel() {
      const data = [{ name: "sheet1", data: [] }];
      data[0].data.push(this.columns.map((item) => item["key"]));
      data[0].data.push(...this.tableList.map((item) => Object.values(item)));
      var buffer = xlsx.build(data).toString("base64");
      download(atob(buffer), `${this.$route.params.table}.xlsx`);
    },
  },
};
</script>
