import { mapState } from "vuex";
export default {
  beforeCreate() {
    const ids = Object.keys(this.$store.state.cart);
    if (ids.length == 0 ) {
      $loading(false);
    } else {
      $loading(true);
    }
  },
  data() {
    return {
      total: 0,
      totalCount: 0,
      fixedCartList:[]
    };
  },
  mounted() {
    this.setTotal();
  },
  computed: {
    ...mapState(["cart"]),
    promoDiscount() {
        let discount = 0;
      const getDiscount = (product) => {
        if (product.inventory == null || product.inventory > 0) {
          let originPrice = product.origin_price || product.price;
          let newPrice = product.special_price || product.price;
          return (originPrice - newPrice) * product.count;
        }
        return 0;
      }
      if (this.cartList.length == 1) {
          discount = getDiscount(this.cartList[0]);
      } else if (this.cartList.length > 0) {
          discount = this.cartList.reduce((a, b) => {
              if (typeof a == "object") {
                  a = getDiscount(a);
              }
              return a + getDiscount(b);
          });
      }
      return discount;
    },
    cartList() {
      let list = Object.keys(this.$store.state.cart).map((id) => {
        const oldID = id.split("-")[0];
        const oldSelection = id.split("-")[1];
        return {
          id: oldID,
          selection: oldSelection,
          imgs: "",
          name: "...",
          tag: "",
          timestamp: "",
          category_id: "",
          detail: "...",
          price: 0,
          count: this.$store.state.cart[id],
        };
      });
      if (this.$store.state.products.length) {
        list = list.map((old) => {
          const oldID = old.id;
          const selectionID = old.selection;
          let newItem = this.$store.state.products.find((item) => item.id == oldID);
          if (newItem) {
            newItem = Object.assign({}, newItem);
            newItem.count = old.count;
            if (selectionID && newItem.selections.length > 0) {
              const selection =
                newItem.selections.find((s) => s.id == selectionID) || {};
              newItem.name = `${newItem.name}-${selection.name}`;
              newItem.selection = selectionID;
              newItem.price = selection.price;
              newItem.origin_price = selection.origin_price;
              newItem.special_price = selection.special_price;
              return newItem;
            } else {
              return newItem;
            }
          }
          this.$store.commit("remove", {
            id: oldID,
            selection: selectionID,
          });
          return old;
        });
        this.fixedCartList = copy(list);
        $loading(false);
      }
      return list;
    }
  },
  watch: {
    cartList: {
      handler: function (value) {
        this.setTotal();
      },
      deep: true,
    },
  },
  methods: {
    setTotal() {
      const prices = Object.values(this.cartList).map(
        (value) => {
          if (value.inventory == null || value.inventory > 0) {
            if (value.origin_price) {
              return value.origin_price * value.count
            } else {
              return value.price * value.count;
            }
          }
          return 0;
        });
      const counts = Object.values(this.cartList).map((value) => {
        if (value.inventory == null || value.inventory > 0) {
          return value.count
        }
        else {
          return 0
        }
      });
      if (prices.length > 0) {
        this.total = prices.reduce((total, item) => total + item);
        this.totalCount = counts.reduce((total, item) => total + item);
      } else {
        this.total = 0;
        this.totalCount = 0;
      }
    },
    gotoBuy() {
      const loginInfo = this.$store.state.loginInfo;
      const detail = loginInfo.detail;
      let outOfStock = false;
      this.cartList.forEach((productItem)=> {
        if (productItem.inventory != null && productItem.count > productItem.inventory) {
          this.$store.commit("cartModify", {
            id: productItem.id,
            count: productItem.inventory,
            selection: productItem.selection,
          });
          outOfStock = true;
        }
      });
      if (outOfStock) {
        $alert(this.$t("product_product_out_of_stock_message"));
        return;
      }
      if (!loginInfo.isLogin) {
        $alert(this.$t("cart_login_please"));
        this.$router.push({ name: "Login" });
      } else if (!detail.email || !detail.gender || !detail.birth) {
        $alert(this.$t("cart_login_please"));
        this.$router.push({ name: "Login" });
      } else {
        this.$router.push({ name: "Checkout" });
      }
    },
    clear(){
      $confirm(this.$t("scitem_product_clear_shoping_car"), () => {
        this.$store.commit("clear", {
          id: this.fixedCartList.id,
          selection: this.fixedCartList.selection,
        });
      });
    },
  },
};