<template>
  <div class="content colorDarkBlue" style="padding: 10vmin">
    <!-- <div>目前狀態：{{ loginStatus }}</div> -->
    <field-value
      class="py-3 underline"
      :field="$t('member_user_id')"
      :value="$store.state.loginInfo.detail.id"
    />
    <field-value
      class="py-3 underline"
      :field="$t('member_points')"
      :value="$store.state.loginInfo.detail.points"
    />
    <field-radio
      class="py-3 underline"
      :field="$t('member_gender')"
      :value="$store.state.loginInfo.detail.gender"
      group="gender"
      align="right"
      :selections="[
        { tag: 0, text: $t('member_gender_woman') },
        { tag: 1, text: $t('member_gender_man') },
      ]"
      :disabled="true"
    />

    <field-value
      class="py-3 underline"
      :field="$t('member_birth')"
      :value="$store.state.loginInfo.detail.birth"
    />
    <!-- <field-value
      class="py-3"
      :field="$t('member_phone')"
      :value="$store.state.loginInfo.detail.mobile"
      :roundStyle="true"
    /> -->
    <field-value
      class="py-3 underline"
      :field="$t('member_email')"
      :value="$store.state.loginInfo.detail.email"
    />
    <field-button
      class="py-3 underline"
      :field="$t('member_skintest_records')"
      @buttonClick="clickSkinTest()"
      :text="$t('member_button_search')"
    />
    <field-button
      class="py-3 underline"
      :field="$t('member_order_records')"
      @buttonClick="$router.push({ name: 'Order' })"
      :text="$t('member_button_search')"
    />
    <field-button
      class="py-3 underline"
      :field="$t('member_password')"
      @buttonClick="$router.push({ name: 'ResetPassword' })"
      :text="$t('member_password_reset')"
    />
    <div
      class="my-3 py-1 roundButton col-11 col-md-7 mx-auto"
      @click="logout()"
    >
      {{ $t("member_logout") }}
    </div>
  </div>
</template>

<script>
export default require("../../../script/pages/Member").default;
</script>