<template>
  <div>
    <div v-if="!commited" class="m-3">
      <div
        class="title colorTitle"
        style="
          height: 10vmin;
          align-items: center;
          display: flex;
          justify-content: center;
        "
      >
        {{ sourceData.title }}
      </div>
      <Divider class="divider" size="small" />
      <div class="bgItem colorTitle p-3 my-3 mx-auto block">
        <b>活動規則</b>
        <p v-for="(rule, index) in sourceData.rules" :key="index">
          {{ index + 1 }}.{{ rule }}
        </p>
        <p class="ivu-form-item-required pt-2">
          <label class="ivu-form-item-label">為必填欄位。</label>
        </p>
      </div>
      <Form
        label-position="top"
        ref="postData"
        :model="postData"
        :rules="ruleValidate"
      >
        <div class="bgItem colorTitle px-3 pt-3 pb-1 my-3 mx-auto block">
          <field-edit
            class="mybg"
            field="收件人姓名"
            placeholder="ex: 容小易"
            v-model="postData.name"
            prop="name"
            :maxlength="10"
            justify-title="center"
          />
        </div>
        <div class="bgItem colorTitle px-3 pt-3 pb-1 my-3 mx-auto block">
          <field-edit
            class="mybg"
            field="收件人電話"
            placeholder="ex: 0912345678"
            v-model="postData.phone"
            prop="phone"
            :maxlength="10"
          />
        </div>
        <div class="bgItem colorTitle px-3 pt-3 pb-1 my-3 mx-auto block">
          <field-edit
            class="mybg"
            field="E-Mail"
            placeholder="ex: royi.service@louyiai.com"
            v-model="postData.email"
            prop="email"
            :maxlength="100"
          />
        </div>
        <div class="bgItem colorTitle px-3 pt-3 pb-1 my-3 mx-auto block">
          <FormItem label="領取方式" prop="getByCompany">
            <div>
              <radio-button
                class="my-1 mybg"
                group="getByCompany"
                tag="true"
                text="送貨上門"
                v-model="postData.getByCompany"
              />
              <radio-button
                class="my-1 mybg"
                group="getByCompany"
                tag="false"
                text="全家取貨"
                v-model="postData.getByCompany"
              />
            </div>
          </FormItem>
        </div>
        <div
          v-if="postData.getByCompany == 'true'"
          class="bgItem colorTitle p-3 my-3 mx-auto block"
        >
          <field-edit
            class="mybg"
            field="公司/團體名稱及地址"
            placeholder="ex: 容易商城"
            v-model="postData.company_name"
            prop="company_name"
            :maxlength="20"
          />
          <!-- <field-edit
            class="mybg"
            field="樓層及門牌號碼"
            placeholder="您的回答"
            v-model="postData.company_detail"
            prop="company_detail"
            :maxlength="100"
          /> -->
        </div>
        <div v-else class="bgItem colorTitle p-3 my-3 mx-auto block">
          <FormItem label="全家取貨門市" prop="store_code">
            <div class="roundButton" @click="selectMap(postData.send_shop)">
              {{ $t("checkout_logistics_cvs_store_select") }}
            </div>
          </FormItem>
          <div v-if="postData.store_code">
            <Divider class="bgGray" />
            <field-value
              :field="$t('common_logistics_cvs_store_code')"
              :value="postData.store_code"
            />
            <field-value
              :field="$t('common_logistics_cvs_store_name')"
              :value="postData.store_name"
            />
          </div>
        </div>

        <div class="bgItem colorTitle px-3 pt-3 pb-1 my-3 mx-auto block">
          <FormItem label="性別" prop="gender">
            <div>
              <radio-button
                class="my-1 mybg"
                group="gender"
                tag="男"
                :text="$t('signup_gender_man')"
                v-model="postData.gender"
              />
              <radio-button
                class="my-1 mybg"
                group="gender"
                tag="女"
                :text="$t('signup_gender_woman')"
                v-model="postData.gender"
              />
            </div>
          </FormItem>
        </div>
        <div class="bgItem colorTitle px-3 pt-3 pb-1 my-3 mx-auto block">
          <FormItem label="年齡" prop="age">
            <div>
              <radio-button
                class="my-1 mybg"
                group="age"
                tag="18↓"
                text="18↓"
                v-model="postData.age"
              />
              <radio-button
                class="my-1 mybg"
                group="age"
                tag="18~24"
                text="18~24"
                v-model="postData.age"
              />
              <radio-button
                class="my-1 mybg"
                group="age"
                tag="25~34"
                text="25~34"
                v-model="postData.age"
              />
              <radio-button
                class="my-1 mybg"
                group="age"
                tag="35~44"
                text="35~44"
                v-model="postData.age"
              />
              <radio-button
                class="my-1 mybg"
                group="age"
                tag="45~64"
                text="45~64"
                v-model="postData.age"
              />
              <radio-button
                class="my-1 mybg"
                group="age"
                tag="65↑"
                text="65↑"
                v-model="postData.age"
              />
            </div>
          </FormItem>
        </div>
        <div class="bgItem colorTitle px-3 pt-3 pb-1 my-3 mx-auto block">
          <FormItem label="您的肌膚檢測結果" prop="result">
            <div>
              <radio-button
                class="my-1 mybg"
                group="result"
                tag="乾燥熟齡肌"
                text="乾燥熟齡肌"
                v-model="postData.result"
              />
              <radio-button
                class="my-1 mybg"
                group="result"
                tag="乾燥蠟黃肌"
                text="乾燥蠟黃肌"
                v-model="postData.result"
              />
              <radio-button
                class="my-1 mybg"
                group="result"
                tag="乾燥敏感肌"
                text="乾燥敏感肌"
                v-model="postData.result"
              />
              <radio-button
                class="my-1 mybg"
                group="result"
                tag="暗沉熟齡肌"
                text="暗沉熟齡肌"
                v-model="postData.result"
              />
              <radio-button
                class="my-1 mybg"
                group="result"
                tag="暗沉蠟黃肌"
                text="暗沉蠟黃肌"
                v-model="postData.result"
              />
              <radio-button
                class="my-1 mybg"
                group="result"
                tag="暗沉敏感肌"
                text="暗沉敏感肌"
                v-model="postData.result"
              />
              <radio-button
                class="my-1 mybg"
                group="result"
                tag="出油熟齡肌"
                text="出油熟齡肌"
                v-model="postData.result"
              />
              <radio-button
                class="my-1 mybg"
                group="result"
                tag="出油蠟黃肌"
                text="出油蠟黃肌"
                v-model="postData.result"
              />
              <radio-button
                class="my-1 mybg"
                group="result"
                tag="出油敏感肌"
                text="出油敏感肌"
                v-model="postData.result"
              />
            </div>
          </FormItem>
        </div>
        <div class="bgItem colorTitle px-3 pt-3 pb-1 my-3 mx-auto block">
          <FormItem label="檢測結果推薦的面膜(選兩項)" prop="recommand">
            <div>
              <checkbox-button
                class="my-1 mybg"
                tag="保濕瞬效補水面膜"
                text="保濕瞬效補水面膜"
                v-model="postData.recommand"
              />
              <checkbox-button
                class="my-1 mybg"
                tag="美白煥彩潤澤面膜"
                text="美白煥彩潤澤面膜"
                v-model="postData.recommand"
              />
              <checkbox-button
                class="my-1 mybg"
                tag="控油細緻調理面膜"
                text="控油細緻調理面膜"
                v-model="postData.recommand"
              />
              <checkbox-button
                class="my-1 mybg"
                tag="玻尿酸原液賦活緊緻面膜"
                text="玻尿酸原液賦活緊緻面膜"
                v-model="postData.recommand"
              />
              <checkbox-button
                class="my-1 mybg"
                tag="九胜肽原液密集亮白面膜"
                text="九胜肽原液密集亮白面膜"
                v-model="postData.recommand"
              />
              <checkbox-button
                class="my-1 mybg"
                tag="EGF原液集中修復面膜"
                text="EGF原液集中修復面膜"
                v-model="postData.recommand"
              />
            </div>
          </FormItem>
        </div>
      </Form>
      <div class="roundButton2" @click="submit()">送出</div>
    </div>
    <div v-else>
      <div
        class="px-0"
        style="position: absolute; z-index: -1; width: 100%; top: 0"
      >
        <img src="/img/event_go_shopping.png" />
      </div>
      <div class="row justify-content-center mx-0" style="padding-top: 69%">
        <div
          :id="resultBID"
          class="col-4 buttonMove"
          @click="onClickSelection(resultBID)"
        >
          <img :src="`/SkinTest/resources/product/${results[1]}.png`" />
        </div>
        <div
          :id="resultAID"
          class="col-4 buttonMove"
          @click="onClickSelection(resultAID)"
        >
          <img :src="`/SkinTest/resources/product/${results[0]}.png`" />
        </div>
        <div
          class="roundButton2"
          style="margin-top: 21%; width: 70%"
          @click="$router.push({ name: 'Home' })"
        >
          到容易商城逛逛
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OtherApiHelper from "../../script/Utility/OtherApiHelper";
import DividerText from "../component/DividerText.vue";
import FieldRadio from "../component/FieldRadio.vue";
import FieldEdit from "../component/FormFieldEdit.vue";
import RadioButton from "../component/RadioButton.vue";
export default {
  components: { DividerText, FieldEdit, FieldRadio, RadioButton },
  props: [],
  data() {
    const source = this.$route.params.source;
    const tempData = getLocalStorage("tempData") || {};
    const productMap = {
      A1B1: {
        result: "乾燥熟齡肌",
        recommands: ["保濕瞬效補水面膜", "玻尿酸原液賦活緊緻面膜"],
      },
      A1B2: {
        result: "乾燥蠟黃肌",
        recommands: ["保濕瞬效補水面膜", "九胜肽原液密集亮白面膜"],
      },
      A1B3: {
        result: "乾燥敏感肌",
        recommands: ["保濕瞬效補水面膜", "EGF原液集中修復面膜"],
      },
      A2B1: {
        result: "暗沉熟齡肌",
        recommands: ["美白煥彩潤澤面膜", "玻尿酸原液賦活緊緻面膜"],
      },
      A2B2: {
        result: "暗沉蠟黃肌",
        recommands: ["美白煥彩潤澤面膜", "九胜肽原液密集亮白面膜"],
      },
      A2B3: {
        result: "暗沉敏感肌",
        recommands: ["美白煥彩潤澤面膜", "EGF原液集中修復面膜"],
      },
      A3B1: {
        result: "出油熟齡肌",
        recommands: ["控油細緻調理面膜", "玻尿酸原液賦活緊緻面膜"],
      },
      A3B2: {
        result: "出油蠟黃肌",
        recommands: ["控油細緻調理面膜", "九胜肽原液密集亮白面膜"],
      },
      A3B3: {
        result: "出油敏感肌",
        recommands: ["控油細緻調理面膜", "EGF原液集中修復面膜"],
      },
    };
    let skinTest = getCookie("skinTest") || "";
    let skinResult = {};
    let gentder = tempData.gender ?? "";

    if (skinTest) {
      skinTest = JSON.parse(decodeURIComponent(skinTest));
      skinResult = productMap[skinTest.result.replace(",", "")];
      if (skinTest.g == 1) {
        gentder = "男";
      } else if (skinTest.g == 0) {
        gentder = "女";
      }
    }
    let getByCompany = tempData.getByCompany || "true";
    return {
      source,
      commited: false,
      sourceDefault: {
        title: "探索美麗容顏秘密-面膜體驗活動",
        rules: [
          "限本人兌換，每人限兌換乙次(電話、E-Mail不得重複)，數量有限、送完為止。",
          "免費兌換需填寫個人資訊，請務必填寫真實資料，以防無法順利收到兌換商品。",
          "本活動開放給線上及企業用戶參加體驗。",
          "企業用戶均由公司負責同仁親自遞送,如包裏無人招領,本公司將視為放棄索取不會再另外補送。",
        ],
      },
      postData: {
        name: tempData.name ?? "",
        email: skinTest.e ?? tempData.email ?? "",
        phone: tempData.phone ?? "",
        gender: gentder ?? "",
        age: tempData.age ?? "",
        result: skinResult.result ?? tempData.result ?? "",
        send_shop: getByCompany == "true" ? "ROYI" : "FAMI",
        store_code: getQueryUrl("CVSStoreID") ?? "",
        store_name: getQueryUrl("CVSStoreName") ?? "",
        company_name: tempData.company_name ?? "",
        company_detail: tempData.company_detail ?? "",
        recommand: skinResult.recommands ?? tempData.recommand ?? [],
        source: source,
        getByCompany,
      },
      ruleValidate: {
        getByCompany: [
          {
            required: true,
          },
        ],
        name: [
          {
            required: true,
            message: "請輸入姓名",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "請輸入E-Mail",
            trigger: "blur",
          },
          {
            type: "email",
            message: this.$t("signup_email_wrong_format"),
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "請輸入電話",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value) {
                const regex = new RegExp(/^09[0-9]{8}$/);
                if (regex.test(value)) {
                  callback();
                } else {
                  callback(this.$t("signup_mobile_wrong_format"));
                }
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        store_code: [
          {
            required: getByCompany == "false",
            message: "請選擇取貨門市",
            trigger: "blur",
          },
        ],
        company_name: [
          {
            required: getByCompany == "true",
            message: "請輸入公司名稱",
            trigger: "blur",
          },
        ],
        company_detail: [
          {
            required: false,
            message: "請輸入樓層及門牌號碼",
            trigger: "blur",
          },
        ],
        gender: [
          {
            required: true,
            message: "請選擇性別",
            trigger: "blur",
          },
        ],
        age: [
          {
            required: true,
            message: "請選擇年齡",
            trigger: "blur",
          },
        ],
        result: [
          {
            required: true,
            message: "請選擇結果",
            trigger: "blur",
          },
        ],
        recommand: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value.length != 2) {
                callback("請選擇兩項推薦面膜");
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    sourceData() {
      return this.sourceDefault;
    },
    results() {
      const productMap = {
        保濕瞬效補水面膜: "A1",
        美白煥彩潤澤面膜: "A2",
        控油細緻調理面膜: "A3",
        玻尿酸原液賦活緊緻面膜: "B1",
        九胜肽原液密集亮白面膜: "B2",
        EGF原液集中修復面膜: "B3",
      };
      return [
        productMap[this.postData.recommand[0]],
        productMap[this.postData.recommand[1]],
      ];
    },
    resultAID() {
      const idMap = {
        A1: "471091826148",
        A2: "471091826149",
        A3: "471091826150",
      };
      return idMap[this.results[0]];
    },
    resultBID() {
      const idMap = {
        B1: "471091826154",
        B2: "471091826155",
        B3: "471091856156",
      };
      return idMap[this.results[1]];
    },
  },
  watch: {
    postData: {
      handler: function (value) {
        if (value.recommand.length > 2) {
          this.$Message.error("最多選取兩項");
          value.recommand.length = 2;
          return;
        }
        const isByCompany = value.getByCompany == "true";
        this.postData.send_shop = isByCompany ? "ROYI" : "FAMI";
        this.ruleValidate.company_name[0].required = isByCompany;
        this.ruleValidate.store_code[0].required = !isByCompany;
        setLocalStorage("tempData", value);
      },
      deep: true,
    },
  },
  methods: {
    onClickSelection(id) {
      this.$router.push({ name: "Product", params: { id } });
    },
    submit() {
      this.$refs["postData"].validate((valid) => {
        if (valid) {
          const postData = Object.assign({}, this.postData);
          postData["recommand"] = postData["recommand"].join(",");
          const getByCompany = this.postData.getByCompany == "true";
          if (getByCompany) {
            postData[
              "memo"
            ] = `${postData["company_name"]}  ${postData["company_detail"]}`;
            postData.store_code = "";
            postData.store_name = "";
          }
          delete postData["company_name"];
          delete postData["company_detail"];
          delete postData["getByCompany"];
          const checkStrs = [
            `<p class='subTitle fontBold' >請確認輸入資料是否正確,以避免錯失應有福利</p>`,
          ];
          checkStrs.push(`收件人姓名: ${postData.name}`);
          checkStrs.push(`收件人電話: ${postData.phone}`);
          checkStrs.push(`E-Mail: ${postData.email}`);
          checkStrs.push(`領取方式: ${getByCompany ? "送貨上門" : "全家取貨"}`);
          if (getByCompany) {
            checkStrs.push(`公司/團體名稱及地址: ${postData.memo}`);
          } else {
            checkStrs.push(`全家取貨門市: ${postData.store_name}`);
          }
          checkStrs.push(`性別: ${postData.gender}`);
          checkStrs.push(`年齡: ${postData.age}`);
          checkStrs.push(`您的肌膚檢測結果: ${postData.result}`);
          checkStrs.push(`檢測結果推薦的面膜: ${postData.recommand}`);
          $confirm(checkStrs.join("\n"), () => {
            $loading(true);
            OtherApiHelper.commit(postData)
              .then(() => {
                $loading(false);
                this.commited = true;
                setLocalStorage("tempData", {});
                $alert("送出成功!");
              })
              .catch((result) => {
                $loading(false);
                this.$Message.error(result.message);
              });
          });
        } else {
          this.$Message.error(this.$t("common_check_fields"));
        }
      });
    },
    selectMap(type) {
      OtherApiHelper.selectStore({
        ShopType: type,
        IsCollection: "N",
        source: this.source,
      }).then((result) => {
        this.postData.store_code = result.CVSStoreID;
        this.postData.store_name = result.CVSStoreName;
      });
    },
  },
};
</script>
<style>
.mybg .roundRadio,
.mybg .ivu-input {
  background: #f8efe6;
}

.block {
  max-width: 800px;
  text-align: left;
  border-radius: 7vmin;
}
</style>
