<template>
  <FormItem :label="field" :prop="prop">
    <div id="twzipcode" class="row mx-0">
      <div
        class="iconInput col-5 mx-0 px-0"
        data-role="county"
        data-name="county"
      ></div>
      <div
        class="iconInput col-7 mx-0 px-0"
        data-role="district"
        data-name="district"
      ></div>
      <div v-show="false" data-role="zipcode" data-name="zipcode"></div>
    </div>
    <input
      name="detail"
      class="mt-2 ivu-input ivu-input-default"
      type="text"
      :placeholder="placeholder"
      @change="update()"
    />
  </FormItem>
</template>
<script>
export default {
  props: {
    field: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      default: "",
    },
    prop: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  mounted() {
    $("input[name='detail']").val(this.value.detail);
    $("#twzipcode").twzipcode({
      onCountySelect: this.update,
      onDistrictSelect: this.update,
      zipcodeSel: this.value.zipCode, // 此參數會優先於 countySel, districtSel
      countySel: this.value.county,
      districtSel: this.value.district,
    });
  },
  methods: {
    update() {
      const value = {
        zipCode: $("input[name='zipcode']").val(),
        county: $("select[name='county']").val(),
        district: $("select[name='district']").val(),
        detail: $("input[name='detail']").val(),
      };
      this.$emit("input", value);
    },
  },
  watch: {
    value: {
      handler: function (v) {
        $("input[name='zipcode']").val(v.zipCode);
        $("select[name='county']").val(v.county);
        $("select[name='district']").val(v.district);
        $("input[name='detail']").val(this.value.detail);
      },
      deep: true,
    },
  },
};
</script>
