export default {
  common_use_outside_browser:'Please use an external browser to achieve the best experience',
  common_system_error:'The system is busy, please try again later ({0})',
  common_check_fields:'Please check if the field is entered correctly',
  common_yes:'OK',
  common_no:'Cancel',
  common_modify_success:'Modified successfully',
  common_steps_cart_content:'Shopping cart content',
  common_steps_order_finish:'Order is completed',
  common_shop_tcat:'Black Cat TA-Q-BIN',
  common_shop_ecan:'Toucan house with pass',
  common_shop_family:'Family Family Convenience Store',
  common_shop_711: '7-11',
  common_shop_hilife:'Lair Fu',
  common_paymethod_credit:'Credit Card/Financial Card',
  common_paymethod_webatm:'Network ATM',
  common_paymethod_cvs:'Super Commercial Code',
  common_paymethod_barcode:'Super business barcode',
  common_paymethod_getandpay:'Cash on delivery',
  common_logistics_home:'Home Delivery',
  common_logistics_home_shop: 'Home delivery service provider',
  common_logistics_cvs:'Super Merchant Pickup',
  common_logistics_cvs_shop: 'Pickup Supermarket',
  common_logistics_cvs_store_code:'Store code',
  common_logistics_cvs_store_name:'Store Name',
  header_search_placeholder:'Search products',
  header_title_login:'Login',
  header_title_signup:'Sign up',
  header_title_member:'Member Area',
  header_title_product:'Product Details',
  header_title_points:'Points record',
  header_title_cart:'Shopping Cart',
  header_title_checkout:'Checkout',
  header_title_order:'Order record',
  header_title_order_detail:'Order details',
  header_title_skintest_record:'Skin test record',
  header_title_reset_pwd:'Reset password',
  header_title_forgot_pwd:'Forgot password',
  footer_contact_phone:'Contact phone: {0}',
  footer_contact_email:'E-Mail: {0}',
  signup_email:'E-Mail',
  signup_email_please:'Please enter E-Mail',
  signup_email_wrong_format:'E-Mail format is wrong',
  signup_email_valify_first:'Please verify email first',
  signup_valify_code:'Verification code',
  signup_valify_code_please:'Please enter the verification code',
  signup_send_valify_code:'Send verification code',
  signup_password:'Password',
  signup_password_please:'Please set a 6-15 code password',
  signup_password_not_less_six:'Password is at least six characters',
  signup_password_confirn:'Password Confirmation',
  signup_password_confirn_please:'Please enter the password again',
  signup_password_not_match:'The two password entries do not match',
  signup_mobile:'Mobile phone number',
  signup_mobile_please:'Please enter your mobile number',
  signup_mobile_wrong_format:'Mobile phone format error',
  signup_birthday:'Birthday',
  signup_birthday_please:'Please choose a birthday',
  signup_gender:'Gender',
  signup_gender_man:'Male',
  signup_gender_woman:'Female',
  signup_gender_please:'Please select gender',
  signup_signup:'Sign up',
  signup_button_finish:'Click me to finish',
  signup_resend_valify_code:'Resend: {0}s',
  signup_privacy:'Privacy Policy',
  signup_service:'Terms of Service',
  login_email:'E-Mail',
  login_email_please:'Please enter E-Mail',
  login_email_wrong_format:'E-Mail format is wrong',
  login_password:'Password',
  login_password_please:'Please set a 6-15 code password',
  login_password_not_less_six:'Password is at least six characters',
  login_signup:'Sign up',
  login_login:'Login',
  login_slogan:'Make beauty easier',
  login_save_account:'Memory account',
  home_product_not_found:'Cannot find any products',
  product_add_to_cart:'Add to cart',
  product_buy_directly:'Buy directly',
  product_add_to_cart_success:'{0} successfully added to the shopping cart',
  product_product_id:'Product ID',
  product_product_price:'Product price',
  product_product_count:'Purchase quantity',
  product_shipfee_free:'Free shipping on all orders over {0} yuan',
  product_product_specification:'Product Specification',
  product_product_life_time:'Shelf life',
  product_product_origin:'origin',
  product_price:'Price',
  product_detail:'Product Features',
  product_recommand: 'Recommend to you',
  product_out_of_stock: 'Out of stock',
  product_product_out_of_stock: 'Product out of stock',
  product_product_out_of_stock_message: 'The product is out of stock',
  member_user_id:'Member ID',
  member_points:'Accumulated points',
  member_gender:'Gender',
  member_gender_woman:'Female',
  member_gender_man:'Male',
  member_birth:'Birthday',
  member_phone:'Phone number',
  member_email:'E-Mail',
  member_my_points:'My points',
  member_order_records:'Order records',
  member_button_search:'click me to search',
  member_logout:'Logout',
  member_skintest_records:'Skintest Records',
  member_skintest_records_notfount:'Your test record was not detected',
  member_skintest_records_test_now:'Go to the test now',
  member_password:'Password',
  member_password_reset:'Click me to reset',
  
  points_source:'Source',
  points_points:'Points',
  points_time:'Time',
  points_no_points:'There are currently no points',
  points_used_points:'Used points',
  points_order_reward_points:'Order points reward',
  points_promo_code_points:'Promo code points',
  points_system_points:'System allocation',
  orecords_order_id:'Order number',
  orecords_order_amount:'Order amount',
  orecords_order_time:'Order time',
  orecords_no_order:'There are currently no orders',
  order_order_id:'Order number',
  order_order_amount:'Order amount',
  order_order_time:'Order time',
  order_order_info:'Order Information',
  order_order_detail:'Order details',
  order_promo_discount:'Promo code discount',
  order_points_discount:'points discount',
  order_payment_detail:'payment details',
  order_payment_amount:'payment amount',
  order_payment_status:'payment status',
  order_payment_shipfee:'Shipping Fee',
  order_shipping_status:'shipping status',
  order_status_paid:'paid',
  order_status_unpaid:'Unpaid',
  order_status_canceled: 'Canceled',
  order_status_getandpay:'Cash on delivery',
  order_status_error:'Order abnormal',
  order_subtitle_product:'Product',
  order_subtitle_count:'Quantity',
  order_paymethod:'Payment Method',
  order_shipping_info:'shipping information',
  order_shipping_method:'shipping method',
  order_shipping_shop:'shipping merchant',
  order_shipping_store_name:'shipping store name',
  order_shipping_store_id:'shipping store number',
  order_shipping_zipcode:'Postal code',
  order_shipping_address:'Address',
  order_shipping_memo:'Transaction Remarks',
  order_receiver_name:'Recipient',
  order_receiver_mobile:'Mobile phone number',
  order_buy_count:'{0} goods',
  order_buy_amount:'Purchase amount',
  order_pay_now:'Pay now',
  order_status:'Status',
  order_go_shopping:'Go shopping immediately',
  cart_total_amount:'Subtotal',
  cart_notthing_in_cart:'The shopping cart is empty',
  cart_login_please:'Please log in first',
  cart_checkout:'Checkout now',
  cart_select_more:'Select more products',
  cart_go_shopping:'Go shopping now',
  scitem_product_name:'product name',
  scitem_product_price:'Price',
  scitem_product_count:'Quantity',
  scitem_product_clear_shoping_car: 'Remove all the items',
  scitem_product_remove_confirm:'Remove this product',
  coitem_product_name:'product name',
  coitem_product_price:'Price',
  coitem_product_count:'Quantity',
  checkout_subtitle_product:'Product',
  checkout_subtitle_count:'Quantity',
  checkout_save_order_data:'Memory checkout data',
  checkout_use_points_please:'Enter the number of discount points',
  checkout_use_points:'Points discount [{0}]',
  checkout_promo_code_please:'Enter promo code',
  checkout_promo_code:'Promo code',
  checkout_logistics_type:'Delivery Method',
  checkout_logistics_home_shop_select:'Please select a home delivery company',
  checkout_logistics_cvs_select:'Please choose a pick-up supermarket',
  checkout_logistics_cvs_store_select:'Select a pickup store',
  checkout_logistics_cvs_store_select_please:'Please select a pickup store',
  checkout_receiver_info:'Recipient Information',
  checkout_receiver_name:'Recipient',
  checkout_receiver_zipcode:'Postal code',
  checkout_receiver_address:'Address',
  checkout_receiver_mobile:'Mobile phone number',
  checkout_receiver_memo:'Transaction Remarks',
  checkout_payment_detail:'payment details',
  checkout_payment_order_amount:'Order amount',
  checkout_payment_promo_code_discount:'Promo discount',
  checkout_payment_use_points:'Points discount',
  checkout_payment_shipfee: 'Shipping Fee',
  checkout_payment_shipfee_discount: 'Shipping Fee discount',
  checkout_payment_total_amount:'Total payment amount',
  checkout_payment_commit:'Checkout now',
  checkout_name_min_6:'Recipient\'s address is at least six characters',
  checkout_zipcode_please:'Please enter the postal code',
  checkout_zipcode_wrong_format:'Zip code format error',
  checkout_name_please:'Please enter your name',
  checkout_address_please:'Please enter the address',
  checkout_mobile_please:'Please enter your mobile phone number',
  checkout_mobile_wrong_format:'Mobile phone number format is wrong',
  checkout_use_points_wrong_format:'input error',
  checkout_use_points_not_enugh:'Not enough points available',
  checkout_paymethod_select_plaese:'Please select payment method',
  checkout_paymethod_select:'Payment Method',
  checkout_commit_confirm:'Please confirm that your information is correct',
  forgot_pwd_resend:'Resend: {0}s',
  forgot_pwd_send:'Send',
  reset_pwd_origin_password:'Original password',
  reset_pwd_origin_password_please:'Please enter the original password',
  reset_pwd_new_password:'New password',
  reset_pwd_new_password_please:'Please set a 6-15 code password',
  reset_pwd_reset_password:'Modify password',
  shipping_status_not_found: "Order Abnormal",
  shipping_status:
  {
    0: "Order processing",
    1: "Shipped",
    3: "Cancelled",
    300: "Order processing",
    310: "Order upload processing in progress",
    311: "Upload and return electronic order processing",
    325: "Return order is being processed (order information has been received)",
    2000: "Amendment of Shipping Order",
    2001: "File transfer succeeded",
    2002: "The shipper number is out of order",
    2003: "The shipper number in the XML file is duplicated",
    2004: "The shipper number is repeatedly uploaded and used (discovered during acceptance)",
    2005: "Date format does not match",
    2006: "The order amount or the collected amount is wrong",
    2007: "Commodity type is empty",
    2008: "Order is empty",
    2009: "The store number is empty",
    2010: "The shipping date is empty",
    2011: "Shipping amount is empty",
    2012: "Shipping number does not exist",
    2013: "The parent manufacturer does not exist",
    2014: "Sub-vendor does not exist",
    2015: "The shipping number already exists (single entry)",
    2016: "The store has closed the transfer store and will be processed for return",
    2017: "The shipping date does not meet the requirements",
    2018: "The type of service does not comply with the regulations (if only pick-up and payment service is offered, the pure pick-up service is used)",
    2019: "The product type does not meet the regulations",
    2020: "The manufacturer has not yet applied for in-store distribution service",
    2021: "The same batch of shipment number is repeated (batch)",
    2022: "The amount of shipment does not meet the requirements",
    2023: "Pick-up person's name is empty",
    2024: "Superstore accepting information",
    2025: "Store transfer number (the old store number has been updated)",
    2026: "If there is no such store, the return will be processed",
    2027: "The store will not deliver at the designated time (Saturday and Sunday)",
    2028: "The store closes the store, and the SUP (new store number) is not updated within 3 days, it will go to the return process",
    2029: "The store has not yet opened a store",
    2030: "Logistic Center Acceptance Success",
    2031: "Not arrived (the product was not received at the logistics end)",
    2032: "Commodity defect (into the logistics center)",
    2033: "Super Material",
    2034: "Prohibited items (return and fine processing)",
    2035: "Order data uploaded repeatedly",
    2036: "The store purchase date has passed (not delivered to the logistics center within the specified time)",
    2037: "Store close and transfer (you can use SUP file to update the original order number to update the store and shipping date)",
    2038: "The first paragraph of label specification error",
    2039: "The first label cannot be read",
    2040: "The first paragraph of label data is wrong",
    2041: "Mental delivery in logistics",
    2042: "Item is missing",
    2043: "Delivery not designated by the store (Saturday and Sunday)",
    2044: "Logistics center acceptance and return completed",
    2045: "Abnormal arrival (commodities arrive at the logistics center early)",
    2046: "The shipment is not picked up and returned to Dazhitong Logistics Center",
    2047: "Normal second return (the return time is extended, and the return will be returned within the compensation period)",
    2048: "Commodity defect (commodity in the logistics center)",
    2049: "The store is closed and the return will be processed",
    2050: "The store transfers to the store, and the return will be processed",
    2051: "The manufacturer requires early return (the manufacturer has an error product)",
    2052: "Prohibited items (return and fine processing)",
    2053: "Swipe A to B",
    2054: "Consumers request early withdrawal (the consumer cancels the order with the manufacturer after placing the order)",
    2055: "Change Store",
    2057: "Vehicle failure, follow-up delivery",
    2058: "The weather is bad, follow-up delivery is in progress",
    2059: "Road interruption, follow-up delivery is in progress",
    2060: "The store is closed and will be returned.",
    2061: "Missing item (the product has not arrived at the store)",
    2062: "The store is reporting a vacancy",
    2063: "Store PeiDa",
    2065: "EC Refund",
    2066: "Abnormal collection and return (damaged goods, damaged outer bags, wrong items taken by consumers, wrong collection, etc., early return from the store)",
    2067: "Consumer picks up successfully",
    2068: "Receive on delivery (A store receives the shipment)",
    2069: "Return the product and receive it (return the product to the designated C store)",
    2070: "Return to the original shipping store and have already picked up",
    2071: "Store code format error",
    2072: "The merchandise is delivered to the seller to pick up the return store",
    2073: "The goods are delivered to the buyer's pick-up store",
    2074: "The consumer has not picked up for seven days, and the product has left the buyer's pick-up store",
    2075: "The manufacturer did not go to the store to pick up the return, and the product left the seller to pick up the return store",
    2076: "The consumer has not picked up for seven days, and the product will be returned to Dazhitong",
    2077: "The manufacturer did not go to the store to pick up the return, and the product was returned to Dazhitong",
    2078: "Buyer did not pick up the goods and return them to the logistics center-acceptance succeeded",
    2079: "Buyer did not pick up the goods and return them to the logistics center-defective goods (into the logistics center)",
    2080: "The buyer returns to the logistics center without picking up the goods-Chaocai",
    2081: "Buyer did not pick up the goods and return them to the logistics center-contraband (return and fine processing)",
    2082: "Buyer did not pick up the goods and return it to the logistics center-the order data is uploaded repeatedly",
    2083: "Buyer did not pick up the goods and return them to the logistics center-the store purchase date has passed (not sent to the logistics center within the specified time)",
    2084: "Buyer did not pick up the goods and return it to the logistics center-the first paragraph of label specification error",
    2085: "The buyer returns to the logistics center without picking up the goods-the first label cannot be read",
    2086: "Buyer did not pick up the goods and return it to the logistics center-the first paragraph of label information is wrong",
    2087: "Buyer did not pick up the goods and return them to the logistics center-logistics is in progress",
    2088: "Buyer did not pick up the goods and return them to the logistics center-the goods are missing",
    2089: "Buyer has not picked up and returned to the logistics center-store designated non-delivery (Saturday, Sunday)",
    2092: "Buyer has not picked up and returned to the logistics center-store closed",
    2093: "Buyer has not picked up and returned to the logistics center-burst quantity",
    2094: "Package cannot be delivered abnormally",
    2095: "The weather is bad",
    2101: "The store closes the store",
    2102: "Renew the old store number of the store",
    2103: "No Pickup Store Information",
    2104: "Temporary store closures",
    2105: "Applied for store change",
    3001: "Transshipment (i.e. consolidation)",
    3002: "Not at home",
    3003: "Finishing",
    3004: "Wrong BASE (sent to the wrong office)",
    3005: "Sent to the wrong center (sent to the wrong transfer center)",
    3006: "Delivery",
    3007: "Company line number rest",
    3008: "The address is wrong, contact the recipient",
    3009: "Moving",
    3010: "Forward (if originally sent to A, resend to B)",
    3011: "Temporary sales office (recipient requests to pick up the goods at the business office)",
    3012: "Arrival (the recipient requested to pick up the item at the station)",
    3013: "Get off at the time of delivery (delivered from A to B business office on the same day, and has arrived at B business office)",
    3014: "When equipped with a car (the same day delivery from A to B business office, has arrived at A business office)",
    3015: "Air freight delivery in progress",
    3016: "Delete after provisioning state",
    3017: "Returned status deleted (deleted for collection and return)",
    3018: "The goods have not been picked up at the store, the SMS will notify you to pick up the goods",
    3019: "The returned item has not been picked up at the store, and the pickup will be notified by SMS",
    3020: "The shipment is not picked up and returned to the logistics center",
    3021: "Returned merchandise is not picked up and returned to the logistics center",
    3022: "The buyer has arrived at the store to pick up the goods",
    3023: "The seller has picked up and the buyer has not picked up the goods",
    3024: "The shipment has arrived at the logistics center",
    3025: "Returned goods have been returned to the logistics center",
    3029: "The merchandise has been changed to the store (the merchandise is delivered to the designated pickup store for replacement)",
    3031: "Returned merchandise has been transferred to the store (returned merchandise is delivered to the designated replacement pickup store)",
    3032: "The seller has sent the parcel to the store",
    3110: "Transfer to Post Office",
    3111: "Distribution Outsourcing",
    3112: "Rematch",
    3113: "Exception",
    3114: "Fetch again",
    3117: "Refusal (under investigation and processing)",
    3118: "Temporary Sales Office",
    3119: "Temporary Placement of Business Office (Temporary Placement on Holidays)",
    3120: "Preparing for delivery",
    3121: "Transfer to the store PeiDa",
    3122: "Another time",
    4001: "Returned merchandise has been delivered to the store",
    4002: "The returned goods have arrived at the logistics center",
    5001: "Damaged, the station will assist in returning the goods",
    5002: "Lost",
    5003: "BASE list management (the sender and the recipient can't be contacted)",
    5004: "General Order Return",
    5005: "Receipt and Return",
    5006: "Recovery Damaged",
    5007: "Receipt Lost",
    5008: "Returned Goods Allocated",
    5009: "Emergency closure of the purchase store, early return of the goods to the logistics center",
    7001: "Oversized (usually occurs when the driver picks up the package, but does not pick up the package)",
    7002: "Overweight (usually occurs when the driver picks up the package, but does not pick up the package)",
    7003: "The address is wrong, contact the recipient",
    7004: "Flight Delayed",
    7005: "Delete Consignment Note",
    7006: "Small logistics lost",
    7007: "The store is missing",
    7008: "Small logistics is damaged and returned to the logistics center",
    7009: "The product is badly packaged (reaction by the logistics center)",
    7010: "The product is badly packaged (reaction from the store)",
    7011: "Pick-up store is closed and returned to original shipping store",
    7012: "Barcode error, the logistics center customer service processing",
    7013: "The order exceeds the acceptance period (the merchant has not shipped)",
    7014: "The merchant did not arrive (if the order is established and the order is not arrived the next day, it will be sent until the order expires and deletes)",
    7015: "Duplicate barcode, handled by the customer service of the logistics center",
    7016: "Super Talent",
    7017: "Unexpected assistance in parcel pickup",
    7018: "Compensation procedure for loss of pickup",
    7019: "Sending shipment status is abnormally searched",
    7020: "Procedure for compensation for lost shipment",
    7021: "Commodity Packing",
    7022: "The outer bag of the product is transparent",
    7023: "Multiple tags",
    7024: "Another time",
    7025: "Unable to connect to telecommunications",
    7026: "Incorrect information",
    7027: "No items can be returned",
    7028: "Oversized and overweight",
    7029: "Recovered",
    7030: "Don't take it away",
    7031: "The goods have not arrived",
    7032: "The shipping store is closed",
    7101: "Parcel delivery acceptance abnormal-store closed transfer",
    7102: "Parcel delivery acceptance abnormal-no purchase data",
    7103: "Parcel delivery acceptance abnormal-barcode error",
    7104: "Parcel delivery acceptance abnormal-super material",
    7105: "Parcel delivery acceptance abnormal-large logistics package is poor (leakage)",
    7106: "Parcel delivery acceptance abnormal-small logistics damage",
    7107: "Parcel delivery acceptance is abnormal-the store responds to poor product packaging (leakage)",
    7201: "Return package delivery acceptance abnormal-store closed transfer",
    7202: "Return package delivery acceptance abnormal-no purchase data",
    7203: "Return package delivery acceptance abnormal-barcode error",
    7204: "Return package delivery acceptance abnormal-super material",
    7205: "Return package delivery acceptance abnormal-large logistics package is poor (leakage)",
    7206: "Return package delivery acceptance abnormal-minor logistics damage",
    7207: "The delivery and acceptance of the returned package is abnormal-the store reports that the product is poorly packaged (leakage)",
    7255: "Return the package to the pickup store",
    9001: "Return has been taken",
    9002: "Return has been taken",
    9999: "Order cancelled"
  },
  error_code: {
    "1001007": "Incorrect account or password",
    "1001010": "You have been logged out",
    "1001013": "E-Mail has been registered",
    "1001018": "E-Mail cannot be empty",
    "1001020": "Verification code error",
    "1001022": "E-Mail cannot be empty",
    "1001025": "Verification code error",
    "1001029": "E-Mail has been registered",
    "1001037": "The input data is incorrect",
    "1001038": "The link has expired",
    "1001039": "The link has expired",
    "1001041": "The original password was entered incorrectly",
    "1001042": "Account not found",
    "1002001": "You have been logged out",
    "1002002": "You have been logged out",
    "1003001": "You have been logged out",
    "1013004": "Point input error",
    "1013005": "Insufficient points balance",
    "1013006": "Recipient information is wrong",
    "1013007": "Recipient information is wrong",
    "1013017": "The coupon code usage time has not yet started",
    "1013018": "The coupon code has expired",
    "1013019": "The coupon code is only for the first purchase",
    "1013021": "The coupon code exceeds the number of uses",
    "1013023": "Promotion code does not exist",
    "1013026": "Some items are out of stock, please re-order",
    "1021001": "Please do not fill in the form repeatedly"
  }
}