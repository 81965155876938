import { LoginType } from "../enum/LoginType";
import LoginInterface from "./LoginInterface";
import ApiHelper from "../Utility/ApiHelper";
import LoginApiHelper from "../Utility/LoginApiHelper";
import { ApiMethods } from "../enum/ApiMethods";
const loginApi = require("../../../../storage/app/apiPath/login.json");
export default class GoogleLogin extends LoginInterface {
    url = {
        auth: "https://accounts.google.com/o/oauth2/v2/auth"
    }
    config = {
        loginType: LoginType.Google,
    }
    isInited: boolean = false
    initedCallback: Function = () => { }
    constructor() {
        super();
        this.defaultData.loginType = this.config.loginType;
        this.data = Object.assign({}, this.defaultData)
    }
    public registCallback(callback: () => void): void {
        callback();
    }
    public logout(callback: (response: StatusResponse) => void): void {
        LoginApiHelper.googleLogout()
            .then((data) => {
                this.statusChangeCallback(data, callback);
            });
    }
    public login(callback: (response: StatusResponse) => void) {
        ApiHelper.openOutside(ApiMethods.GET, this.url.auth, {
            response_type: "token",
            client_id: process.env.MIX_GOOGLE_CLIENT_ID,
            redirect_uri: location.origin + loginApi.google.auth,
            state: Math.random(),
            scope: "https://www.googleapis.com/auth/drive.metadata.readonly"
        }).then(this.onPageCallback.bind(this, callback));
    }
    public onPageCallback(callback: ((response: StatusResponse) => void), data: any) {
        log(data);
        this.statusChangeCallback(data, callback);
    }
    public checkLoginStatus(callback: (response: StatusResponse) => void) {
        LoginApiHelper.googleGetUserInfo()
            .then((data) => {
                this.statusChangeCallback(data, callback);
            }).catch((error) => {
                this.statusChangeCallback(error, callback);
            });
    }

    // 處理各種登入身份
    private statusChangeCallback(rawData: any, callback: ((response: StatusResponse) => void) | null) {
        const userData = rawData?.user;
        if (userData) {
            this.loginSuccess();
            this.data.isLogin = true;
            this.data.detail.id = userData.permissionId;
            this.data.detail.display_name = userData.displayName;
            this.data.detail.email = userData.emailAddress;
        } else {
            this.logoutSuccess();
            Object.assign(this.data, this.defaultData);
            this.data.message = rawData.message;
        }
        if (callback) callback(this.data);
    }
}
