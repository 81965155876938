<template>
  <FormItem :label="field" :prop="prop">
    <Input
      :value="value ? value : ''"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :name="name"
      :type="type"
      :password="password"
      @input="update($event)"
    />
  </FormItem>
</template>
<script>
export default {
  props: {
    field: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    prop: {
      type: String,
      default: "",
    },
    maxlength: {
      type: Number,
      default: 100,
    },
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    password: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    update(value) {
      this.$emit("input", value);
    },
  },
};
</script>
