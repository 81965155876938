import { Toast } from "bootstrap";

export default {
  props: ["product"],
  data(){
    return {
      showAddToCart: false
    }
  },
  methods: {
    goProduct() {
       this.$router.push({ name: "Product", params: { id: this.product.id } });
    },
    addToCart() {
      if (this.product.inventory != null && (this.product.inventory == 0 || (this.$store.state.cart[this.product.id] + 1) > this.product.inventory)) {
          $alert(this.$t("product_product_out_of_stock_message"));
          return;
      }
      if (this.product.selections.length > 0) {
        this.goProduct();
      } else if (this.product.id) {
        this.$store.commit("add", { product: this.product, count: 1 });
        this.$Message.success(
          this.$t("product_add_to_cart_success", [`${this.product.name}x1`])
        );
      }
    },
  },
  computed: {
    addToCartText() {
      return this.canBuy ? this.$t("product_add_to_cart") : this.$t("product_out_of_stock")
    },
    canBuy() {
      return this.product.inventory == null || this.product.inventory > 0
    },
    content() {
      // const start = new Date("2021/10/07 00:00:00");
      // const end = new Date("2021/10/23 23:59:59");
      // const now = Date.now();
      let _content = this.product.content;
      // if (start <= now && now <= end) {
      //   if (this.product.id == "471091856156") {
      //     _content =
      //       "<div>修復組合買3送1</div><div class='colorDarkRed'>全館滿666免運</div>";
      //   }
      // }
      return _content;
    },
    lowestSelection() {
      if (this.product.selections?.length > 0) {
        const prices = this.product.selections
          .sort((a, b) => {
            let aPrice =  a.origin_price || a.price;
            let bPrice = b.origin_price || b.price;
            return aPrice - bPrice;
          });

        return prices[0];
      }
      return null;
    },
    originPrice() {
      let productItem = this.product;
      if (this.product.selections?.length > 0) {
        productItem = this.lowestSelection;
      }
      if (productItem.special_price) {
        return productItem.origin_price || productItem.price;
      }
      return productItem.origin_price;
    },
    priceText() {
      let productItem = this.product;
      if (this.product.selections?.length > 0) {
        productItem = this.lowestSelection;
      }
      let price = productItem.price;
      if (productItem.special_price) {
        price = productItem.special_price;
      }
      return price;
    },
  },
};