const Login = require("../Login/Login.ts").default;
export default {
  beforeCreate() {},
  data() {
    return {
    };
  },
  methods: {
    clickSkinTest() {
      const skinTestResult = this.$store.state.loginInfo.detail.skinTestResult;
      if (skinTestResult) {
        window.open(`${location.origin}/SkinTest/result?s=${skinTestResult}`, "_blank");
      } else {
        $confirm({
          content: this.$t("member_skintest_records_notfount"),
          yesText: this.$t("member_skintest_records_test_now"),
          noText: this.$t("common_no"),
          yesCallback: () => {
            window.open(location.origin + "/SkinTest", "_blank");
          },
        });
      }
    },
    logout() {
      $loading(true);
      Login.logout().then((result) => {
        $loading(false);
      });
    },
  },
};