<template>
  <div :class="!labelWidth ? 'row ' : 'row mx-0'">
    <div
      :class="!labelWidth ? 'col-4 ' : 'px-0'"
      :style="{
        'text-align': 'left',
        margin: 'auto',
        width: labelWidth ? labelWidth : '',
      }"
    >
      {{ field }}
    </div>
    <div
      :class="!labelWidth ? 'col-8 ' : 'px-0'"
      :style="{
        color: red ? 'red' : '',
        width: labelWidth ? `calc( 100% - ${labelWidth} )` : '',
      }"
    >
      <div
        :style="{
          position: 'relative',
          width: parseInt(height) * 5 + 'vmin',
          margin: '0 0 0 auto',
        }"
      >
        <img src="/img/button_count.png" />
        <div
          class="colorLight"
          style="
            display: flex;
            justify-content: center;
            position: absolute;
            align-items: center;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            font-size: 5.5vmin;
            font-weight: bold;
          "
        >
          <div
            class="bgLight"
            :style="{
              height: height,
              width: height,
              'line-height': height,
              cursor: 'default',
              'border-radius': '1vmin',
            }"
            @click="update(value - 1)"
          >
            －
          </div>
          <input
            class="colorLight"
            type="number"
            :value="showValue"
            @input="update($event.target.value)"
            :style="{
              width: '10vmin',
              background: '#0000',
              border: '0px',
              'text-align': 'center',
              height: height,
              'font-size': '4vmin',
            }"
          />
          <div
            class="bgLight"
            :style="{
              height: height,
              width: height,
              'line-height': height,
              cursor: 'default',
              'border-radius': '1vmin',
            }"
            @click="update(value + 1)"
          >
            ＋
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    field: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: 1,
    },
    red: {
      type: String,
      default: "",
    },
    labelWidth: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "6vmin",
    },
    not_negtive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showValue() {
      return this.not_negtive ? (this.value > 0 ? this.value : 1) : this.value;
    },
  },
  methods: {
    update(updateValue) {
      updateValue = parseInt(updateValue);
      if (this.not_negtive) {
        updateValue = updateValue > 0 ? updateValue : 1;
      }
      this.$emit("input", updateValue);
    },
  },
};
</script>
