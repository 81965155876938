var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"px-0",style:({
      'text-align': 'left',
      width: _vm.labelWidth,
    })},[_vm._v("\n    "+_vm._s(_vm.field)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"px-0",style:({
      margin: 'auto',
      'text-align': _vm.contentAlign,
      width: ("calc( 100% - " + _vm.labelWidth + " )"),
      'white-space': 'pre-wrap',
    })},[_c('Input',{attrs:{"value":_vm.value ? _vm.value : '',"placeholder":_vm.placeholder,"maxlength":_vm.maxlength,"name":_vm.name,"type":_vm.type,"password":_vm.password},on:{"input":function($event){return _vm.update($event)}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }