import VueRouter from 'vue-router'
import Vue from 'vue'

Vue.use(VueRouter);
const routers = require("./index.json");
const routersPC = require("./indexPC.json");
const routersMobile = require("./indexMobile.json");


routersPC.routes.forEach((route) => {
    route.component = 'components/pages/PC/' + route.component;
});
routersMobile.routes.forEach((route) => {
    route.path = '/m' + route.path;
    route.name = 'mobile' + route.name;
    route.component = 'components/pages/Mobile/' + route.component;
});
routers.routes.forEach((route) => {
    route.component = 'components/' + route.component;
});

routers.routes = routers.routes.concat(routers.routes.map(route => {
    const copyRoute = copy(route);
    copyRoute.path = '/m' + copyRoute.path;
    copyRoute.name = 'mobile' + copyRoute.name;
    return copyRoute;
}));

routers.routes = routersPC.routes.concat(routersMobile.routes, routers.routes);
routers.routes.forEach((route) => {
    // console.log(`name: ${route.name}, path: ${route.path}, component: ../${route.component}`);
    route.component = copy(require("../" + route.component)).default;
});

export default new VueRouter(routers);