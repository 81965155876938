import { ApiMethods } from "../enum/ApiMethods";
import ApiHelper from "./ApiHelper";
const userApi = require("../../../../storage/app/apiPath/user.json");
const md5: (s: string) => string = require('md5');

export default class LoginApiHelper {


    public static getPoints() {
        return ApiHelper.callSelf(ApiMethods.GET, userApi.getPoints);
    }
}