export default {
    mounted() {
        
    },
    data() {
        const breakpoints = {};
        const itemWidth = 300;
        for (let i = 3000; i > 768; i = i - itemWidth) {
            breakpoints[i] = {
                slidesPerView: (i - itemWidth) / itemWidth,
            };
        }
        breakpoints[768] = {
            slidesPerView: 2,
        };

        return {
            swiperOption: {
                slidesPerView: 15,
                spaceBetween: 10,
                freeMode: true,
                centerInsufficientSlides: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                breakpoints: breakpoints,
            },
            swiperOptionBanner: {
                slidesPerView: 1,
                freeMode: false,
                centerInsufficientSlides: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
            },
        };
    },
    computed: {
        allCategory() {
            if (this.$store.state.categories.length==0) {
                $loading(true);
            } else {
                $loading(false);
            }
            return this.$store.state.categories;
        },
        productFilterList: function () {
            const filterList = [];
            this.allCategory.forEach((category) => {
                const splitSearch = this.$store.state.searchStr.split(" ");
                category.products.forEach((product) => {
                    const allStr = Object.values(product).reduce((all, current) => all + current);
                    if(splitSearch.filter((search) => allStr.includes(search)).length ==
                        splitSearch.length) {
                        filterList.push(product);
                    }
                });
            });
            return filterList;
        },
        banners() {
            const banners = this.$store.state.config.banners;
            if (banners instanceof Array) {
                return banners.map((banner) => {
                        return {
                            src: banner.img,
                            click: () => {
                                if (banner.click_url) {
                                    if (banner.new_window) {
                                        window.open(banner.click_url, "_blank");
                                    } else {
                                        location.href = banner.click_url;
                                    }
                                }
                            },
                        };
                    })
            }
            return []
        },
        brandStories() {
            const brand_stories = this.$store.state.config.brand_stories;
            if (brand_stories instanceof Array) {
                return brand_stories.map((brand_storie) => {
                        return {
                            src: brand_storie.image,
                            click: () => {
                                if (brand_storie.click_url) {
                                    if (brand_storie.new_window) {
                                        window.open(brand_storie.click_url, "_blank");
                                    } else {
                                        location.href = brand_storie.click_url;
                                    }
                                }
                            },
                        };
                    })
            }
            return []
        }
    },
    watch: {
    },
    methods: {
        goProduct(productID) {
            this.$router.push({ name: "Product", params: { id: productID } });
        }
    },
};