import OrderApiHelper from "../Utility/OrderApiHelper";
import ConfigApiHelper from "../Utility/ConfigApiHelper";
import ProductApiHelper from "../Utility/ProductApiHelper";
import FieldValue from "../../components/component/FieldValue.vue";
import { ShopType } from "../enum/ShopType";
import { PayMethod } from "../enum/PayMethod";
import { DeliveryMethod } from "../enum/DeliveryMethod";
import RadioButton from "../../components/component/RadioButton.vue";
import DividerText from "../../components/component/DividerText.vue";
import CheckboxButton from "../../components/component/CheckboxButton.vue";

export default {
  components: { FieldValue, RadioButton, DividerText, CheckboxButton },
  beforeCreate() {
  },
  mounted() {
    this.updateInfo();
  },
  data() {
    const needCacheData = getLocalStorage("needCacheData") ? true : false;
    const cacheData = needCacheData ? getLocalStorage("cacheData") || {} : {};
    const selectedCheckboxes = [];
    const promoCodeCheck = (callback) => {
      const promoCode = this.promoCode;
      const checkedInfo = this.checkedCode[promoCode];
      if (promoCode && checkedInfo == undefined || checkedInfo && !checkedInfo.success) {
        ConfigApiHelper.getPromoCodeInfo(promoCode)
          .then((result) => {
            this.checkedCode[promoCode] = {
              success: true,
              type: result.promo_type,
              value: result.promo_value,
              products: result.products,
              categories: result.categories
            };
            if (this.checkedCodes.indexOf(promoCode) < 0) {
              this.checkedCodes.push(promoCode);
            }
            this.checkPromoInfo(callback);
          })
          .catch((e) => {
            this.checkedCode[promoCode] = {
              success: false,
              value: e,
            };
            const index = this.checkedCodes.indexOf(promoCode);
            if (index >= 0) {
              this.checkedCodes.splice(index, 1);
            }
            this.checkedCodes.push(promoCode);
            this.checkPromoInfo(callback);
          });
      } else {
        this.checkPromoInfo(callback);
      }
    };
    if (needCacheData) selectedCheckboxes.push("cacheData");

    return {
      getedCookiePromoCode: false,
      selectedCheckboxes,
      PayMethod,
      ShopType,
      DeliveryMethod,
      // 未來寫成由api決定顯示價格
      promoCodeDiscount: 0,
      total: 0,
      totalCount: 0,
      pay_method: PayMethod.Credit,
      logisticsType: cacheData.logistics_type || DeliveryMethod.HOME,
      logisticsInfo: {
        send_shop: cacheData.logistics_sub_type || ShopType.ECAN,
        store_code: cacheData.logistics_store_id || "",
        store_name: cacheData.logistics_store_name || "",
      },
      checkedCode: {},
      checkedCodes: [],
      logisticsMap: {
        Home: ["TCAT", "ECAN"],
        CVS: ["FAMI", "UNIMART", "HILIFE"],
      },
      receiver: {
        name: cacheData.receiver_name || this.$store.state.loginInfo.detail.display_name || "",
        zipCode: "",
        address: "",
        mobile: cacheData.receiver_mobile || this.$store.state.loginInfo.detail.phone || "",
        email: cacheData.receiver_email || this.$store.state.loginInfo.detail.email || "",
        memo: "",
      },
      addressData: {
        detail: cacheData.detail || "",
        zipCode: cacheData.zip_code || "",
        county: cacheData.county || "",
        district: cacheData.district || "",
      },
      usePoints: 0,
      promoCode: "",
      ruleValidate: {
        name: [
          {
            required: true,
            message: this.$t("checkout_name_please"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value.trim()) {
                callback();
              } else {
                callback(this.$t("checkout_name_please"));
              }
            },
            trigger: "blur",
          }
        ],
        address: [
          {
            required: true,
            message: this.$t("checkout_address_please"),
            trigger: "blur",
          },
          {
            type: "string",
            min: 6,
            message: this.$t("checkout_name_min_6"),
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: this.$t("checkout_mobile_please"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (this.receiver.mobile) {
                const regex = new RegExp(/^09[0-9]{8}$/);
                if (regex.test(this.receiver.mobile)) {
                  callback();
                } else {
                  callback(this.$t("checkout_mobile_wrong_format"));
                }
              }
            },
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t("signup_email_please"),
            trigger: "blur",
          },
          {
            type: "email",
            message: this.$t("signup_email_wrong_format"),
            trigger: "blur",
          }
        ],
        promoCode: [
          {
            validator: (rule, value, callback) => {
              promoCodeCheck(callback);
            },
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              promoCodeCheck(callback);
            },
            trigger: "change",
          },
        ],
        usePoints: [
          {
            validator: (rule, value, callback) => {
              if (this.usePoints < 0 || isNaN(this.usePoints)) {
                callback(this.$t("checkout_use_points_wrong_format"));
              } else if (
                parseInt(this.usePoints) >
                parseInt(this.$store.state.loginInfo.detail.points)
              ) {
                callback(this.$t("checkout_use_points_not_enugh"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    promoDiscount() {
      let discount = 0;
      const getDiscount = (product) => {
        const productId = product.id + (product.selection_id ? `-${product.selection_id}`:"");
        if (product.inventory == null || product.inventory >= this.$store.state.cart[productId]) {
          let originPrice = product.origin_price || product.price;
          let newPrice = product.special_price || product.price;
          return (originPrice - newPrice) * product.count;
        } else {
          return 0;
        }
      }
      if (this.cartList.length == 1) {
          discount = getDiscount(this.cartList[0]);
      } else if (this.cartList.length > 0) {
          discount = this.cartList.reduce((a, b) => {
              if (typeof a == "object") {
                  a = getDiscount(a);
              }
              return a + getDiscount(b);
          });
      }
      return discount + this.promoCodeDiscount;
    },
    config() {
      return this.$store.state.config;
    },
    shipFee() {
      if (this.isCVS) {
        return this.config.ship_fee_cvs;
      } else {
        if (this.logisticsInfo.send_shop == ShopType.TCAT) {
          return this.config.ship_fee_home;
        } else if (this.logisticsInfo.send_shop == ShopType.ECAN) {
          return this.config.ship_fee_home;
        }
      }
      return 0;
    },
    shipFeeDiscount() {
      
      const noFeePrice = this.total - this.promoDiscount - this.showUsePoint;
      if (noFeePrice >= this.config.free_ship_fee_lower) {
        if (this.isCVS) {
          return this.config.ship_fee_cvs;
        } else {
          if (this.logisticsInfo.send_shop == ShopType.TCAT) {
            return this.config.ship_fee_home;
          } else if (this.logisticsInfo.send_shop == ShopType.ECAN) {
            return this.config.ship_fee_home;
          }
        }
      }
      return 0;
    },
    isCVS() {
      const isCVS = this.logisticsType == DeliveryMethod.CVS;
      this.ruleValidate.address[0].required = !isCVS;
      return isCVS;
    },
    showUsePoint() {
      let usePoints = this.usePoints > 0 ? parseInt(this.usePoints) : 0;
      usePoints =
        usePoints <= this.$store.state.loginInfo.detail.points ? usePoints : 0;
      return usePoints;
    },
    finalPrice() {
      return (
        this.total -
        this.promoDiscount -
        this.showUsePoint +
        this.shipFee -
        this.shipFeeDiscount
      );
    },
    cartList() {
      let list = Object.keys(this.$store.state.cart).map((id) => {
        const oldID = id.split("-")[0];
        const oldSelection = id.split("-")[1];
        return {
          id: oldID,
          selection_id: oldSelection,
          imgs: "",
          name: "...",
          tag: "",
          timestamp: "",
          category_id: "",
          detail: "...",
          price: 0,
          count: this.$store.state.cart[id],
        };
      })
      if (this.$store.state.products.length) {
        let promoInfo = null;
        if (this.promoCode && this.checkedCodes.indexOf(this.promoCode) >= 0) {
          promoInfo = this.checkedCode[this.promoCode];
          this.promoCodeDiscount = 0;
          if (promoInfo.type == '1') {
            this.promoCodeDiscount = promoInfo.value;
          }
        }
        list = list.map((old) => {
          let newItem = this.$store.state.products.find((item) => item.id == old.id);
          newItem = Object.assign({}, newItem);
          if (old.selection_id && newItem.selections.length) {
            const selection = newItem.selections.find(
              (selection) => selection.id == old.selection_id
            );
            newItem.name = newItem.name + `-${selection?.name}`;
            newItem.origin_price = selection?.origin_price;
            newItem.price = selection?.price;
            newItem.special_price = this.getSpecialPrice(newItem, promoInfo);
          } else if (this.isProductInPromo(newItem, promoInfo)) {
            newItem.special_price = this.getSpecialPrice(newItem, promoInfo);
          } else {
            newItem.special_price = 0;
          }
          

          newItem.selection_id = old.selection_id;
          newItem.count = old.count;
          return newItem;
        });
        $loading(false);
      } else {
        $loading(true);
      }
      list = list.filter((product) => {
        const productId = product.id + (product.selection_id ? `-${product.selection_id}` : "");
        return product.inventory == null || product.inventory >= this.$store.state.cart[productId];
      });
      return list;
    }
  },
  methods: {
    checkPromoInfo(callback) {
      const promoInfo = this.checkedCode[this.promoCode];    
      if (!this.promoCode || !promoInfo || !promoInfo.success) {
        callback(promoInfo?.value);
      } else {
        callback();
      }
    },
    isProductInPromo(product, promoInfo) {
      let inPromo = false;
      if (promoInfo && promoInfo.type == "2") {
        if ((promoInfo.products.length == 0 && promoInfo.categories.length == 0)) {
          inPromo = true;
        } else if (promoInfo.products.indexOf(String(product.id)) >= 0 || promoInfo.categories.indexOf(String(product.category_id)) >= 0) {
          inPromo = true;
        }
      }
      return inPromo;
    },
    getSpecialPrice(product, promoInfo) {
      if (promoInfo) {
        const type = promoInfo.type;
        const value = promoInfo.value;
        const originPrice = product.origin_price || product.price;
        switch (type) {
          case "2":
            return Math.floor(originPrice * value);
        }
       
      }
       return 0;
    },
    onOrderPostSuccess(result) {
      if (result.errorCode) {
         this.$Message.error(this.$t(result.errorCode));
      } else {
        ProductApiHelper.getProductByCategory().then((result) => {
          this.$store.commit("categories", result);
        });
        setCookie("promoCode", "");
        this.$store.commit("clear");
        this.$router.push({
          name: "OrderDetail",
          params: { id: result.order_id, from: "Checkout" },
        });
      }
      $loading(false);
    },
    onOrderPostField(error) {
      ProductApiHelper.getProductByCategory().then((result) => {
        this.$store.commit("categories", result);
      });
      $alert(error.message);
      $loading(false);
    },
    ShopName(code) {
      const map = {
        TCAT: this.$t("common_shop_tcat"), // 黑貓(宅配)
        ECAN: this.$t("common_shop_ecan"), // 大嘴鳥宅配通
        FAMI: this.$t("common_shop_family"), // 全家
        UNIMART: this.$t("common_shop_711"), // 統一超商
        HILIFE: this.$t("common_shop_hilife"), // 萊爾富
      };
      return map[code] ?? "";
    },
    PayMethodName(code) {
      const map = {
        Credit: this.$t("common_paymethod_credit"),
        WebATM: this.$t("common_paymethod_webatm"),
        CVS: this.$t("common_paymethod_cvs"),
        BARCODE: this.$t("common_paymethod_barcode"),
        GetAndPay: this.$t("common_paymethod_getandpay"),
      };
      return map[code];
    },
    DeliveryName(code) {
      const map = {
        Home: this.$t("common_logistics_home"), // 黑貓(宅配)
        CVS: this.$t("common_logistics_cvs"), // 宅配通
      };
      return map[code] ?? "";
    },
    selectMap(type) {
      OrderApiHelper.selectStore({
        ShopType: type,
        IsCollection: "Y",
      }).then((result) => {
        this.logisticsInfo.store_code = result.CVSStoreID;
        this.logisticsInfo.store_name = result.CVSStoreName;
      });
    },
    submit() {
      if (this.cartList.length == 0) {
        $alert(this.$t("common_system_error", [100009]), () => {
          this.$router.push({ name: "Cart" });
        });
        return;
      }
      this.$refs["receiver"].validate((valid) => {
        if (valid) {
          const orderItems = this.cartList.map((item) => {
            return {
              id: item.id,
              count: item.count,
              selection_id: item.selection_id,
            };
          });
          if (this.isCVS) {
            if (!this.logisticsInfo.send_shop) {
              this.$Message.error(this.$t("checkout_logistics_cvs_select"));
              return;
            } else if (!this.logisticsInfo.store_code) {
              this.$Message.error(
                this.$t("checkout_logistics_cvs_store_select_please")
              );
              return;
            }
          } else if (!this.isCVS && !this.logisticsInfo.send_shop) {
            this.$Message.error(this.$t("checkout_logistics_home_shop_select"));
            return;
          }
          if (!this.pay_method) {
            this.$Message.error(this.$t("checkout_paymethod_select_plaese"));
            return;
          }
          const checkStrs = [
            `<p class='subTitle fontBold' >${this.$t(
              "checkout_commit_confirm"
            )}</p>`,
          ];
          checkStrs.push(
            `${this.$t("checkout_logistics_type")}: ${this.DeliveryName(
              this.logisticsType
            )}`
          );
          if (this.isCVS) {
            checkStrs.push(
              `${this.$t("common_logistics_cvs_shop")}:  ${this.ShopName(
                this.logisticsInfo.send_shop
              )}`
            );
            checkStrs.push(
              `${this.$t("common_logistics_cvs_store_name")}: ${
                this.logisticsInfo.store_name
              }`
            );
            checkStrs.push(
              `${this.$t("common_logistics_cvs_store_code")}: ${
                this.logisticsInfo.store_code
              }`
            );
            checkStrs.push(
              `${this.$t("checkout_receiver_name")}: ${this.receiver.name}`
            );
            checkStrs.push(
              `${this.$t("checkout_receiver_mobile")}: ${this.receiver.mobile}`
            );
          } else {
            checkStrs.push(
              `${this.$t("checkout_receiver_name")}: ${this.receiver.name}`
            );
            checkStrs.push(
              `${this.$t("checkout_receiver_address")}: ${
                this.receiver.address
              }`
            );
            checkStrs.push(
              `${this.$t("checkout_receiver_mobile")}: ${this.receiver.mobile}`
            );
          }
          checkStrs.push(
            `E-mail: ${this.receiver.email}`
          );
          checkStrs.push(
            `${this.$t("checkout_receiver_memo")}: ${this.receiver.memo}`
          );
          checkStrs.push(
            `${this.$t("checkout_payment_total_amount")}: ${this.finalPrice}`
          );
          $confirm(checkStrs.join("\n"), () => {
            const params = {
              items: orderItems,
              use_points: this.usePoints,
              promo_code: this.promoCode,
              pay_method: this.pay_method,
              logistics_type: this.logisticsType,
              logistics_sub_type: this.logisticsInfo.send_shop,
              logistics_store_id: this.logisticsInfo.store_code,
              logistics_store_name: this.logisticsInfo.store_name,
              receiver_name: this.receiver.name,
              receiver_email: this.receiver.email,
              zip_code: this.isCVS ? "" : this.receiver.zipCode,
              address: this.isCVS ? "" : this.receiver.address,
              receiver_mobile: this.receiver.mobile,
              referrer: getCookie("referrer"),
              event: getCookie("event"),
              memo: this.receiver.memo,
              remark: "",
            };

            if (this.pay_method != PayMethod.GetAndPay) {
              OrderApiHelper.openCreateOrder(params)
                .then(this.onOrderPostSuccess)
                .catch(this.onOrderPostField);
            } else {
              params.items = JSON.stringify(orderItems);
              $loading(true);
              OrderApiHelper.createOrder(params)
                .then(this.onOrderPostSuccess)
                .catch(this.onOrderPostField);
            }
            setTimeout(() => {
              this.$router.go(-1);
            }, 500);
          });
        } else {
          this.$Message.error(this.$t("common_check_fields"));
        }
      });
    },
    updateCacheData() {
      const value = this.selectedCheckboxes.indexOf("cacheData") >= 0;
      setLocalStorage("needCacheData", value);
      if (value) {
        const cacheData = {
          logistics_type: this.logisticsType || "",
          logistics_sub_type: this.logisticsInfo.send_shop || "",
          logistics_store_id: this.logisticsInfo.store_code || "",
          logistics_store_name: this.logisticsInfo.store_name || "",
          receiver_name: this.receiver.name || "",
          receiver_mobile: this.receiver.mobile || "",
          receiver_email: this.receiver.email || "",
          zip_code: this.addressData.zipCode || "",
          county: this.addressData.county || "",
          district: this.addressData.district || "",
          detail: this.addressData.detail || "",
        };
        setLocalStorage("cacheData", cacheData);
      } else {
        setLocalStorage("cacheData", null);
      }
    },
    updateInfo() {
      const prices = Object.values(this.cartList).map(
        (value) => {
          const productId = value.id + (value.selection_id ? `-${value.selection_id}`:"");
          if (value.inventory == null || value.inventory >= this.$store.state.cart[productId]) {
            const originPrice = value.origin_price || value.price;
            return originPrice * value.count;
          } else {
            return 0
          }
        }
        );
      const counts = Object.values(this.cartList).map((value) => {
        const productId = value.id + (value.selection_id ? `-${value.selection_id}`:"");
        if (value.inventory == null || value.inventory >= this.$store.state.cart[productId]) {
          return value.count
        }
        else {
          return 0
        }
      });
        if (prices.length > 0) {
          this.total = prices.reduce((total, item) => total + item);
          this.totalCount = counts.reduce((total, item) => total + item);
        } else {
          this.total = 0;
          this.totalCount = 0;
        }
        if (!this.getedCookiePromoCode) {
          this.getedCookiePromoCode = true;
          this.promoCode = getCookie("promoCode") || "";
        }
    }
  },
  watch: {
    selectedCheckboxes() {
      this.updateCacheData();
    },
    cartList: {
      handler: function (value) {
        this.updateInfo();
      },
      deep: true,
    },
    logisticsType: function (value) {
      if (
        !this.logisticsMap[value].find(
          (shop) => shop == this.logisticsInfo.send_shop
        )
      ) {
        this.logisticsInfo.send_shop = "";
      }
      if (value == DeliveryMethod.HOME) {
        this.logisticsInfo.send_shop = ShopType.ECAN;
        this.pay_method = PayMethod.Credit;
      }
      this.updateCacheData();
    },
    addressData: function (value) {
      let address = "";
      if (value.county && value.district && value.detail) {
        address = value.county + value.district + value.detail;
        this.receiver.address = address;
        this.receiver.zipCode = value.zipCode;
      }
      this.updateCacheData();
      return address;
    },
    "logisticsInfo.send_shop": function (value) {
      Object.keys(this.logisticsMap).find((logisticsType) => {
        if (this.logisticsMap[logisticsType].find((shop) => shop == value)) {
          this.logisticsType = logisticsType;
        }
      });
      this.logisticsInfo.store_code = "";
      this.logisticsInfo.store_name = "";
      this.updateCacheData();
    },
    "logisticsInfo.store_code": function () {
      this.updateCacheData();
    },
    "receiver.name": function () {
      this.updateCacheData();
    },
    "receiver.mobile": function () {
      this.updateCacheData();
    },
    "receiver.email": function () {
      this.updateCacheData();
    },
    "$store.state.loginInfo.detail": function () {
      this.receiver.name = this.receiver.name || this.$store.state.loginInfo.detail.display_name;
      this.receiver.mobile = this.receiver.mobile || this.$store.state.loginInfo.detail.phone;
      this.receiver.email = this.receiver.email || this.$store.state.loginInfo.detail.email;
    }
  },
};