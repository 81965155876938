<template>
  <div :class="classes" :style="styles" @click="back">
    <slot>
      <div :class="innerClasses">
        <i class="ivu-icon ivu-icon-ios-arrow-up"></i>
      </div>
    </slot>
  </div>
</template>
<script>
const prefixCls = "ivu-back-top";

export default {
  props: {
    height: {
      type: Number,
      default: 400,
    },
    bottom: {
      type: Number,
      default: 30,
    },
    right: {
      type: Number,
      default: 30,
    },
    target: {
      type: String,
      default: "body",
    },
  },
  data() {
    return {
      targetElement: null,
      backTop: false,
    };
  },
  mounted() {
    this.targetElement = $(this.target)[0];
    this.targetElement.addEventListener("scroll", this.handleScroll, false);
    this.targetElement.addEventListener("resize", this.handleScroll, false);
  },
  beforeDestroy() {
    this.targetElement.removeEventListener("scroll", this.handleScroll, false);
    this.targetElement.removeEventListener("resize", this.handleScroll, false);
  },
  computed: {
    classes() {
      return [
        `${prefixCls}`,
        {
          [`${prefixCls}-show`]: this.backTop,
        },
      ];
    },
    styles() {
      return {
        bottom: `${this.bottom}px`,
        right: `${this.right}px`,
        "z-index": 1000,
      };
    },
    innerClasses() {
      return `${prefixCls}-inner`;
    },
  },
  methods: {
    handleScroll() {
      this.backTop = this.targetElement.scrollTop >= this.height;
    },
    back() {
      this.targetElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.$emit("on-click");
    },
  },
  watch: {
    $route() {
      if (this.targetElement) {
        this.targetElement.removeEventListener(
          "scroll",
          this.handleScroll,
          false
        );
        this.targetElement.removeEventListener(
          "resize",
          this.handleScroll,
          false
        );
      }
      this.targetElement = $(this.target)[0];
      this.targetElement.addEventListener("scroll", this.handleScroll, false);
      this.targetElement.addEventListener("resize", this.handleScroll, false);
    },
  },
};
</script>
