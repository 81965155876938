const LoginApiHelper =
  require("../Utility/LoginApiHelper").default;
export default {
  mounted() {},
  data() {
    return {
      params: {
        email: this.$route.params.email || "",
      },
      timeLeft: 0,
      waitTime: 60,
    };
  },
  computed: {},
  methods: {
    sendToken() {
      if (this.params.email) {
        if (this.timeLeft == 0) {
          $loading(true);
          LoginApiHelper.forgetPassword(this.params.email)
            .then(() => {
              $loading(false);
              $alert("已寄出密碼重設連結, 請檢查信箱", () => {
                this.$router.go(-1);
              });
            })
            .catch((error) => {
              $loading(false);
              $alert(error.message);
            });
          const startTime = new Date();
          const intervel = setInterval(() => {
            const timeLeft = Math.floor(
              this.waitTime - (new Date() - startTime) / 1000
            );
            if (timeLeft > 0) {
              this.timeLeft = timeLeft;
            } else {
              this.timeLeft = 0;
              clearInterval(intervel);
            }
          }, 300);
        }
      } else {
        this.$Message.error(this.$t("common_check_fields"));
      }
    },
  },
};