<template>
  <div class="p-3 page" style="">
    <Form
      class="py-3 m-auto"
      ref="params"
      :label-width="100"
      :model="params"
      :rules="ruleValidate"
      style="
        width: 300px;
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
      "
    >
      <form-field-edit
        v-if="fromMember"
        :field="$t('reset_pwd_origin_password')"
        v-model="params.oldPassword"
        :placeholder="$t('reset_pwd_origin_password_please')"
        name="password"
        type="password"
        :password="true"
        :maxlength="15"
      />
      <form-field-edit
        :field="$t('reset_pwd_new_password')"
        v-model="params.newPassword"
        :placeholder="$t('reset_pwd_new_password_please')"
        name="new-password"
        type="password"
        :password="true"
        :maxlength="20"
      />
      <div class="roundButton2 p-1 h-auto" @click="submit()">
        {{ $t("reset_pwd_reset_password") }}
      </div>
    </Form>
  </div>
</template>

<script>
export default require("../../../script/pages/ResetPassword").default;
</script>