<template>
  <div :class="!labelWidth ? 'row ' : 'row mx-0'">
    <div
      :class="!labelWidth ? 'col-4 ' : ''"
      :style="{
        'text-align': 'left',
        width: labelWidth ? `${labelWidth}` : '',
      }"
    >
      {{ field }}
    </div>
    <div
      :class="!labelWidth ? 'col-8 ' : ''"
      :style="{
        width: labelWidth ? `calc( 100% - ${labelWidth} )` : '',
        display: 'flex',
        'justify-content': align ? align : 'left',
      }"
    >
      <radio-button
        v-for="selection in selections"
        :key="selection.tag"
        class="py-0 px-2"
        :group="group"
        :tag="selection.tag"
        :text="selection.text"
        :noImg="true"
        :disabled="disabled"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "field",
    "labelWidth",
    "value",
    "group",
    "selections",
    "disabled",
    "align",
  ],
  methods: {
    update(value) {
      this.$emit("input", value);
    },
  },
  mounted() {
    this.selections.forEach((selection) => {
      $(`#${this.group}${selection.tag}`)[0].checked =
        this.value == selection.tag;
    });
  },
  watch: {
    value: function (v) {
      this.selections.forEach((selection) => {
        $(`#${this.group}${selection.tag}`)[0].checked = v == selection.tag;
      });
    },
  },
};
</script>
