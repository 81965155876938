import { swiper, swiperSlide } from "vue-awesome-swiper";
// import style (>= Swiper 6.x)
import "swiper/css/swiper.css";

export default {
  components: {
    swiper,
    swiperSlide
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    product: {
      type: Object,
      default: () => {
        return {
          id: "",
          imgs: [],
          name: "...",
          tag: "",
          timestamp: "",
          category_id: "",
          detail: "...",
          origin_price: 0,
          price: 0,
          special_price: 0,
          selections: [],
          inventory: 0
        };
      },
    },
  },
  beforeCreate() { },
  mounted() {
    if (!this.product.id) {
      $loading(true);
    }
  },
  data() {
    let skinTest = getCookie("skinTest");
    let recommands = [];
    if (skinTest) {
      skinTest = JSON.parse(decodeURIComponent(skinTest));
      recommands = skinTest.recommands;
    }
    
    const defaultObtions = {
      freeMode: false,
      centerInsufficientSlides: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    };
    let option1 = Object.assign(
      {
        slidesPerView: 2,
        spaceBetween: 20,
        breakpoints: {
          768: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        },
      },
      defaultObtions
    );
    const breakpoints = {};
    const itemWidth = 220;
    for (let i = 3000; i > 768; i = i - itemWidth) {
      breakpoints[i] = {
        slidesPerView: (i - itemWidth) / itemWidth,
      };
    }
    breakpoints[768] = {
      slidesPerView: 2,
    };
    let option2 = Object.assign(
      {
        slidesPerView: 6,
        spaceBetween: 20,
        breakpoints,
      },
      defaultObtions
    );
    return {
      recommands,
      selectNotice: false,
      selection: "",
      count: 1,
      swiperOption1: option1,
      swiperOption2: option2,
    };
  },
  methods: {
    addToCart() {
      if (this.product.id) {
        if (
          (this.product.selections.length > 0 && this.selection) ||
          this.product.selections.length == 0
        ) {
          const productId = this.product.id + (this.selection ? `-${this.selection}` : "");
          if (this.product.inventory != null && (this.product.inventory == 0 || (this.$store.state.cart[productId] + 1) > this.product.inventory)) {
            $alert(this.$t("product_product_out_of_stock_message"));
            return;
          }
          this.$store.commit("add", {
            product: this.product,
            count: this.count,
            selection: this.selection,
          });
          let name = this.product.name;
          if (this.selection) {
            const selectionName = this.product.selections.find(
              (selection) => selection.id == this.selection
            ).name;
            name += `-${selectionName}`;
          }
          this.$Message.success(
            this.$t("product_add_to_cart_success", [`${name}x${this.count}`])
          );
          return true;
        } else {
          this.$Message.warning("請選擇產品");
          this.selectNotice = true;
          return false;
        }
      } else {
        this.$Message.warning(this.$t("common_system_error"));
      }
    },
    directBuy() {
      if (this.addToCart()) {
        this.$router.push({
          name: "Cart",
        });
      }
    },
  },
  computed: {
    canBuy() {
      return this.product.inventory == null || this.product.inventory > 0
    },
    productList() {
      let list = this.recommands.map((id) => {
        return {
          id,
          imgs: [],
          name: "...",
          content: "",
          product_code: "...",
          extra_info: "...",
          specification: "...",
          life_time: "...",
          made_origin: "...",
          tag: "",
          timestamp: "",
          category_id: "",
          detail: "...",
          selections: [],
          inventory: 0
        };
      
      });
      if (this.$store.state.products.length) {
        this.$store.state.products.forEach((item) => {
          // 主物件更新
          if (item.id == this.$route.params.id) {
            Object.assign(this.product, item);
          }
          let listItem = list.find(
            (oldItem) => oldItem.id == item.id
          );
          if (listItem) {
            // 其餘物件更新
            Object.assign(listItem, item);
          } else {
            // 不在清單, 加入清單
            list.push(item);
          }
        });
        window.$scrollToTop();
        $loading(false);
      }
      
      return list;
    },
    productListFilter() {
      return this.productList.filter(
        (productItem) => productItem.id != this.product.id
      );
    },
    productSelections() {
      let selections = [];
      if (this.product.selections.length > 0) {
        selections = this.product.selections.map((selection) => {
          return { text: selection.name, value: selection.id };
        });
        selections.unshift({ text: "請選擇產品", value: "" });
      }
      return selections;
    },
    sortedSelections() {
      if (this.product.selections?.length > 0) {
        const prices = this.product.selections
          .sort((a, b) => {
            let aPrice =  a.origin_price || a.price;
            let bPrice = b.origin_price || b.price;
            return aPrice - bPrice;
          });

        return prices;
      }
      return null;
    },
    originPrice() {
      let price =this.product.origin_price ?  "NT$"+this.product.origin_price : null;
      if (this.product.selections?.length > 0) {
        if (this.selection) {
          const selection = this.product.selections.find(
            (selection) => selection.id == this.selection
          );
          if (selection.special_price) {
            price = "NT$"+(selection.origin_price || selection.price);
          } else {
            price = selection.origin_price ? "NT$"+selection.origin_price : "";
          }
        } else {
          let prices = this.sortedSelections.map((selection) => {
            let selectionPrice = selection.origin_price;
            if (selection.special_price) {
              selectionPrice = selection.origin_price || selection.price;
            }
            return selectionPrice;
          });
          
          prices = prices
            .filter(
              (pri, index) =>
                pri && (
                index == 0 ||
                (index == prices.length - 1 && pri != prices[0]))
            )
            .map((pri) => `NT$${pri}`);

          price = prices.length >0 ? prices.join(" ~ ") : null;
        }
      }
      else if (this.product.special_price) {
        price = "NT$"+(this.product.origin_price || this.product.price);
      
      }
      return price;
    },
    priceText() {
      let price = `NT$${this.product.price}`;
      if (this.product.selections?.length > 0) {
        if (this.selection) {
          const selection = this.product.selections.find(
            (selection) => selection.id == this.selection
          );
          if (selection.special_price) {
            price = selection.special_price;
          } else {
            price = selection.price;
          }
          price = `NT$${price}`;
        } else {
          let prices = this.sortedSelections
            .map((selection) => {
               let selectionPrice = selection.price;
              if (selection.special_price) {
                selectionPrice = selection.special_price;
              }
              return selectionPrice;
            });
          prices = prices
            .filter(
              (pri, index) =>
                pri && (
                index == 0 ||
                (index == prices.length - 1 && pri != prices[0]))
            )
            .map((pri) => `NT$${pri}`);

          price = prices.length >0 ? prices.join(" ~ ") : null;
        }
      } else if(this.product.special_price){
        price = "NT$"+this.product.special_price;
      }
      return price;
    },
  },
  watch: {
    selection(value) {
      if (value) {
        this.selectNotice = false;
      }
    },
    product: {
      handler: function (value) {
        $scrollToTop();
      },
      deep: true,
    }
  },
};